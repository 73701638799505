import React from 'react'
import Form from '../components/form'
import { Grid, Paper } from '@material-ui/core'
import * as Yup from 'yup'
import Input from '../components/form/Input'
import Button from '../components/form/Button'
import { makeStyles } from '@material-ui/core/styles'
import axios from '../axios'
import {useAuthContext} from '../auth'
import { useForm } from 'react-hook-form'
import { handleErrors } from '../utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(#1b2430, #151C26);',
    height: '100vh'
  },
  logo: {
    textAlign: 'center'
  },
  paper: {
    width: '250px',
    padding: '40px 40px',
    [theme.breakpoints.up('md')]: {
      width: '350px',
      padding: '60px 40px',
    },
  },
  button: {
    width: '100%', marginTop: '20px'
  }
}));

const LoginSchema = Yup.object().shape({
  username: Yup
    .string()
    .required('Username is required.'),
  password: Yup
    .string()
    .required('Password is required.')
})

function Login() {
  const classes = useStyles()

  const {login} = useAuthContext()

  const methods = useForm({ defaultValue: {}, resolver: yupResolver(LoginSchema) })
  const { setError } = methods

  const onSubmit = async (userInfo={}) => {
    try {
      //test mode
      // const user = {
      //   name: 'Jack Weber',
      //   role: {
      //     permissions: {
      //       readUser: true,
      //       writeUser: true,
      //       readVendor: true,
      //       writeVendor: true
      //     }
      //   }
      // }
      // const expiry = Date.now() + 100000000
      // localStorage.setItem('user', JSON.stringify(user))
      // localStorage.setItem('expiry', expiry)
      // login({user, expiry})

      //production
      const {data} = await axios.post('/users/login', {...userInfo})
      login(data)
    } catch(err) {
      handleErrors({err, setError})
    }
  }

  return (
    <div className={classes.root}>
      <Form 
        methods={methods} 
        onSubmit={onSubmit}
      >
        <Paper className={classes.paper} elevation={0}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <h2 className={classes.logo}>Ritani Portal</h2>
            </Grid>
            <Grid item>
              <Input
                name="username"
                label="Username"
                autoComplete="username"
                style={{width: '100%'}}
              />
            </Grid>
            <Grid item>
              <Input
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                style={{width: '100%'}}
              />
            </Grid>
            <Grid item><Link to="/password-request">Forgot Password?</Link></Grid>
            <Grid item>
              <Button className={classes.button} disabled={false} type="submit">Login</Button>
            </Grid>
          </Grid>
        </Paper>
      </Form>
    </div>
  )
}

export default Login;