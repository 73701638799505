import React from 'react'
import * as Yup from 'yup'

import { Grid } from '@material-ui/core'
import Input from '../form/Input'
import FormDialog from '../form/FormDialog'
import InputSkeleton from '../skeletons/InputSkeleton'
import Checkbox from '../form/Checkbox'

const StoreUserSchema = Yup.object().shape({
    name: Yup
        .string()
        .required('Name is required'),
    email: Yup.string().email('Invalid email'),
    admin: Yup.boolean(),
    password: Yup.string()
      .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
})

const StoreUserSkeleton = () => (
    <Grid container spacing={1}>
        {[...Array(3)].map((x, i) => <Grid key={i} item xs={12}>
            <InputSkeleton />
        </Grid>)}
    </Grid>
)

export default function StoreUser() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
            <Input 
                name="name"
                label="Name"
            />
        </Grid>
        <Grid item xs={12}>
            <Input 
                name="email"
                label="Email"
            />
        </Grid>
        <Grid item xs={12}>
            <Input 
                name="password"
                label="Password"
                type="password"
            />
        </Grid>
        <Grid item xs={12}>
            <Checkbox name="admin" label="Admin" />
        </Grid>
    </Grid>
  )
}

const initialValues = {
    name: '',
    email: '',
    admin: false,
    password: ''
}

export const StoreUserDialog = (route) => (props) => {
  return (
    <FormDialog 
      title="User"
      routeOptions={{route}}
      validationSchema={StoreUserSchema}
      Skeleton={StoreUserSkeleton}
      defaultValues={initialValues}
      {...props}
    >
      <StoreUser />
    </FormDialog>
  )
}