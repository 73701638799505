import { Button, IconButton, Tooltip } from "@material-ui/core"
import React, { useState } from "react"
import BasicDialog from "../form/BasicDialog"
import EmailIcon from '@material-ui/icons/Email';
import { handleErrors } from "../../utils"
import axios from "../../axios"

const SendEmail = ({
    id, to, from, body, altLink, reload, backlink
}) => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const isError = !to.email || !to.name || !from.email || !body || !altLink

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleConfirm = async () => {
        try {
            setLoading(true)
            await axios.post(`/backlinks/${id}/email`, {
                to, from, body, altLink
            })
            reload()
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

    return <>
        <Tooltip title="Email">
            <IconButton onClick={handleClickOpen}>
                <EmailIcon />
            </IconButton>
        </Tooltip>
        <BasicDialog
            open={open}
            onClose={handleClose}
            title={`Send Email`}
            actions={<>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Cancel
                </Button>
                <Button disabled={loading || isError} onClick={handleConfirm} color="secondary">
                    Send Email
                </Button>
            </>}
        >
            {isError ? <div style={{color: 'red'}}>
                Some fields are missing   
            </div> : <>
                Are you sure you want to send an email to <b>{to.email}</b> from <b>{from.email}</b> with the following body: 
                <div style={{marginTop: '10px', whiteSpace: 'pre'}}>{body}</div>
            </>}
        </BasicDialog>
    </>
}

export default SendEmail;