import React, {useState, useEffect} from 'react'
import { formatCurrency, handleErrors } from '../../utils'
import axios from '../../axios'
import {round} from 'lodash'
import { useAuthContext } from '../../auth'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, CircularProgress } from '@material-ui/core'

export default function ReturnsReport() {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [loadingSuppliers, setLoadingSuppliers] = useState(false)
    const [suppliers, setSuppliers] = useState([])
    const [selectedSupplier, setSelectedSupplier] = useState('')
    const {user} = useAuthContext()
    const {is_supplier} = user

    const getSuppliers = async() => {
        if (is_supplier) return
        setLoadingSuppliers(true)

        try {
            const {data} = await axios.get('/suppliers');
            setSuppliers(data.map(({supplier_id, name}) => ({supplier_id, name})))
        } catch (e) {

        } finally {
            setLoadingSuppliers(false)
        }
    }

    const getData = async() => {
        try {
            setLoading(true)
            const {data} = await axios.get(`/returns/report?supplier=${selectedSupplier}`)
            setData(data)
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getSuppliers()
    }, [])

    useEffect(() => {
        if (!is_supplier && !selectedSupplier) {
            setData()
            return
        }
        getData()
    }, [selectedSupplier])

    return (
        <div>
            {!is_supplier && <Autocomplete
                style={{ width: 250, marginBottom: 20 }}
                getOptionLabel={(option) => option.name}
                options={suppliers}
                onChange={(_, opt) => setSelectedSupplier(opt ? opt.supplier_id : '')}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Supplier"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                            <React.Fragment>
                                {loadingSuppliers ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                            ),
                        }}
                    />
                )}
            />}
            {loading && <CircularProgress color="primary" size={40} style={{marginTop: 20}} />}
            {!loading && data &&
                <div>
                    Sales: {formatCurrency(data.sales)}< br/>
                    Returns: {formatCurrency(data.returns)}< br/>
                    Return percentage: {data.sales ? round(100 * data.returns / data.sales, 2) + '%' : '---'}
                </div>
            }
        </div>
    )
}
