import React from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core'
import axios from '../axios'
import { Link } from 'react-router-dom'
import useTitle from '../utils/useTitle'
import { useAuthContext } from '../auth'

export default function Dashboard() {
    useTitle('Dashboard')

    const {user} = useAuthContext()
    const {is_supplier, is_retailer, is_vs_retailer} = user

    const [data, setData] = React.useState({})

    React.useEffect(() => {
        const getData = async() => {
        try {
            const {data} = await axios.get('/orders/aggregate')
            setData(data)
        } catch(e) {

        }
        }
        getData()
    },[])

    const {on_hold, pending, ready_to_ship, shipped, unavailable, cancelled, received, pending_clearance, needed_tomorrow} = data

    return (
        <TableContainer component={Paper}>
        <Table>
            {(!is_retailer && !is_vs_retailer) ? <>
                <TableHead>
                <TableRow>
                    <TableCell>Queue</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Action</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Diamonds Pending Availability</TableCell>
                        <TableCell>{pending}</TableCell>
                        <TableCell><Link to={`/orders/pending`}>Confirm Availability</Link></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Diamonds on Hold</TableCell>
                        <TableCell>{on_hold}</TableCell>
                        <TableCell><Link to={`/orders/on_hold`}>View Diamonds on Hold</Link></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Diamonds to Ship to Ritani</TableCell>
                        <TableCell>{ready_to_ship}</TableCell>
                        <TableCell><Link to={`/orders/ready_to_ship`}>Ship to Ritani</Link></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Diamonds Shipped</TableCell>
                        <TableCell>{shipped}</TableCell>
                        <TableCell><Link to={`/orders/shipped`}>View Diamonds Shipped</Link></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Diamonds Unavailable</TableCell>
                        <TableCell>{unavailable}</TableCell>
                        <TableCell><Link to={`/orders/unavailable`}>View Diamonds Unavailable</Link></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Diamonds Cancelled</TableCell>
                        <TableCell>{cancelled}</TableCell>
                        <TableCell><Link to={`/orders/cancelled`}>View Diamonds Cancelled</Link></TableCell>
                    </TableRow>
                    {!is_supplier && 
                        <>
                            <TableRow>
                                <TableCell>Diamonds Received</TableCell>
                                <TableCell>{received}</TableCell>
                                <TableCell><Link to={`/orders/received`}>View Diamonds Received</Link></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Diamonds Pending Clearance</TableCell>
                                <TableCell>{pending_clearance}</TableCell>
                                <TableCell><Link to={`/orders?verification=pending`}>View Diamonds Pending Clearance</Link></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Diamonds Needed Tomorrow</TableCell>
                                <TableCell>{needed_tomorrow}</TableCell>
                                <TableCell><Link to={`/orders?needed=tomorrow`}>View Diamonds Needed Tomorrow</Link></TableCell>
                            </TableRow>
                        </>
                    }
                </TableBody>
            </>
            : <>
                <TableHead>
                    <TableRow>
                        <TableCell>Queue</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Pending Orders</TableCell>
                        <TableCell>{pending}</TableCell>
                        <TableCell><Link to={`/orders/pending`}>Pending Orders</Link></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Shipped Orders</TableCell>
                        <TableCell>{pending}</TableCell>
                        <TableCell><Link to={`/orders/pending`}>Shipped Orders</Link></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Received Orders</TableCell>
                        <TableCell>{pending}</TableCell>
                        <TableCell><Link to={`/orders/pending`}>Received Orders</Link></TableCell>
                    </TableRow>
                </TableBody>
            </>}
            </Table>
        </TableContainer>
    )
}
