import React from 'react'
import DefaultTable from '../table/DefaultTable'
import { WarrantyDialog } from './Warranty'
import useTitle from '../../utils/useTitle'

export default function Index() {
  useTitle('Warranties')

  const columns = React.useMemo(
    () => [
    //   {
    //     Header: 'Customer ID',
    //     accessor: 'customer_id',
    //     filter: numEqFilter
    //   },
      {
        Header: 'First Name',
        accessor: 'first_name',
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )

  return (
    <DefaultTable 
      columns={columns} 
      routeOptions={{route: 'warranties', selected: 'first_name last_name email customer_id status', sorted: [{id: 'created_at', desc: true}]}}
      header='Warranty'
      Dialog={WarrantyDialog}
    />
  )
}