import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { get } from 'lodash'

const TextArea = ({ name='', label, ...rest }) => {
  const { control } = useFormContext()

  const {
    field: { onChange, onBlur, value },
    formState: {errors}
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  const err = get(errors, name)
  
  return (
    <div>
      {label && <h5>{label}</h5>}
      <textarea 
          onChange={onChange} 
          onBlur={onBlur} 
          name={name} 
          value={value}
          {...rest}
      >
      </textarea>
      {err && <div style={{color: 'red'}}>{err}</div>}
    </div>
  );
}

export default TextArea;