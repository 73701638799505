import React, {useState} from 'react'
import FlashOn from '@material-ui/icons/FlashOn'
import CloseIcon from '@material-ui/icons/Close'
import Refresh from '@material-ui/icons/Refresh'
import { Tooltip, IconButton, Dialog, DialogTitle, AppBar, Toolbar, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TableSortLabel } from '@material-ui/core'
import { formatCurrency, handleErrors } from '../../utils'
import axios from '../../axios'

const SuggestionTable = ({data, title}) => {
    const headers = [
        {id: 'carat', label: 'Carat'}, 
        {id: 'shape', label: 'Shape'}, 
        {id: 'color', label: 'Color'}, 
        {id: 'clarity', label: 'Clarity'},
        {id: 'cut', label: 'Cut'},
        {id: 'cert', label: 'Cert'}, 
        {id: 'type', label: 'Type'},
        {id: 'polish', label: 'Pol / Sym'},
        {id: 'fluorescence', label: 'Fluorescence'},
        {id: 'retailer', label: 'Retailer'}, 
        {id: 'vendor', label: 'Vendor'},
        {id: 'price', label: 'Price'},
        {id: 'sku', label: 'SKU'},
    ]

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('price')

    const handleChangePage = (_, newPage) => setPage(newPage)
    
    const handleChangeRowsPerPage = e => {
        setRowsPerPage(+e.target.value)
        setPage(0)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
          return -1
        }
        if (b[orderBy] > a[orderBy]) {
          return 1
        }
        return 0
    }

    const getComparator = (order, orderBy) =>
        order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy)
      
    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedThis.map((el) => el[0])
    }

    const createSortHandler = (property) => (event) => handleRequestSort(event, property)

    return <div style={{marginTop: '20px', padding: '20px 30px'}}>
        <h2>{title}</h2>
        <Table>
            <TableHead>
                <TableRow>
                    {headers.map(({id, label}) => <TableCell
                        sortDirection={orderBy === id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === id}
                            direction={orderBy === id ? order : 'asc'}
                            onClick={createSortHandler(id)}
                        >
                            {label}
                        </TableSortLabel>
                    </TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(({carat, shape, color, clarity, cut, fluorescence, polish, symmetry, certificate_lab, certificate_number, site, retailer, supplier_id, price, retail_price, lab_grown, sku, title, handle}) => {
                    let url = ''
                    switch(site) {
                        case 'bluenile':
                            url = `https://www.bluenile.com/${handle}`
                            break
                        case 'jamesallen':
                            url = `https://www.jamesallen.com/${handle}`
                            break
                        case 'rarecarat':
                            url = `https://www.rarecarat.com/${handle}`
                            break
                        case 'brilliantearth':
                            url = `https://www.brilliantearth.com/${handle}`
                            break
                        case 'brilliance':
                            url = `https://www.brilliance.com/${handle}`
                            break
                        default:
                            url = `https://www.ritani.com/products/${handle}`
                            break
                    }

                    return <TableRow>
                        <TableCell>{carat}</TableCell>
                        <TableCell>{shape}</TableCell>
                        <TableCell>{color}</TableCell>
                        <TableCell>{clarity}</TableCell>
                        <TableCell>{cut}</TableCell>
                        {/* <TableCell>{fluorescence}</TableCell> */}
                        <TableCell>{certificate_lab} - {certificate_number}</TableCell>
                        <TableCell>{lab_grown ? 'Lab' : 'Earth'}</TableCell>
                        <TableCell>{polish} / {symmetry}</TableCell>
                        <TableCell>{fluorescence}</TableCell>
                        <TableCell>{site || 'ritani'}</TableCell>
                        <TableCell>{retailer || supplier_id || ''}</TableCell>
                        <TableCell>{formatCurrency(price || retail_price)}</TableCell>
                        <TableCell>{url ? <a href={url} target="_blank" rel="noopener noreferrer">{sku}</a> : sku}</TableCell>
                    </TableRow>
                })}
                {!data.length && <TableRow><TableCell style={{textAlign: 'center'}} colSpan="100">No data found</TableCell></TableRow>}
            </TableBody>
        </Table>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={data.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </div>
}

export default function GetRecommendations({id}) {
    const [open, setOpen] = React.useState(false)
    const [suggestions, setSuggestions] = React.useState({})
    const [loading, setLoading] = React.useState(false)

    const getData = async () => {
        try {
            setLoading(true)
            const {data} = await axios.get(`/orders/${id}/recommendations`, {
                timeout: 30000
            })
            setSuggestions(data)
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

    const handleClickOpen = () => {
        setOpen(true)
        getData()
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Suggested Alternatives">
                <IconButton onClick={handleClickOpen}>
                    <FlashOn />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                fullScreen
                onClose={handleClose}
            >
                <AppBar>
                    <Toolbar>
                        <DialogTitle>Suggested Alternatives</DialogTitle>
                        <div style={{marginLeft: 'auto'}}>
                            <IconButton onClick={getData}>
                                {loading ? <CircularProgress color="secondary" size={20} /> : <Refresh />}
                            </IconButton>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                {loading && <CircularProgress color="secondary" size={60} style={{position: 'absolute', left: '50%', top: '50%'}} />}
                <div style={{marginTop: '60px'}} />
                {suggestions.order && <SuggestionTable data={[suggestions.order]} title="Selected Diamond" />}
                {suggestions.alt1 && <SuggestionTable data={suggestions.alt1.map(x => ({...x, sku: x._id}))} title="Ritani Alternatives" />}
                {suggestions.alt2 && <SuggestionTable data={suggestions.alt2} title="Other Alternatives" />}
            </Dialog>
        </>
    )
}