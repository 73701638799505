import React from 'react'
import Button from '@material-ui/core/Button'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import { Tooltip, IconButton, TextField } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'

export default function AddTracking({id, fetchData}) {
  const [open, setOpen] = React.useState(false)
  const [tracking, setTracking] = React.useState(false)
  const [trackingError, setTrackingError] = React.useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

    const handleConfirm = async () => {
        try {
            if (!tracking) {
                setTrackingError('Required')
                return
            }
            await axios.patch(`/orders/tracking/${id}`, {tracking_number: tracking})
            setOpen(false)
            fetchData()
        } catch(err) {
            handleErrors({err})
            setOpen(false)
        }
    }

  return (
    <>
      <Tooltip title="Mark Shipped">
        <IconButton onClick={handleClickOpen}>
          <LocalShippingIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Mark Shipped`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="secondary">
            Add Tracking
          </Button>
        </>}
      >
        <TextField 
            label="Tracking Number"
                onChange={e => {
                    setTracking(e.target.value)
                    setTrackingError('')
                }
            }
            error={!!trackingError}
            helperText={trackingError}
        />
      </BasicDialog>
    </>
  );
}