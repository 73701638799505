import React from 'react'
import Button from '@material-ui/core/Button'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { Tooltip, IconButton } from '@material-ui/core'
import BasicDialog from '../form/BasicDialog'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import { useForm } from 'react-hook-form';
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Form from '../form'
import Input from '../form/Input'
import { useAuthContext } from '../../auth'

const labelSchema = Yup.object().shape({
  row: Yup
    .number()
    .integer()
    .min(1)
    .max(10),
  column: Yup
    .number()
    .integer()
    .min(1)
    .max(5),
})

const defaultValues = {
  row: 1, column: 1, hours: 2
}

export default function DownloadUserBarcodes({rows}) {
  const {user} = useAuthContext()
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const methods = useForm({ 
    defaultValues, resolver: yupResolver(labelSchema),
  })

  const handleConfirm = async (labelInfo) => {
    try {
        setLoading(true)

        const selectedRows = rows.map(({_id, name, username}) => ({_id, name, username}))

        const emptyLabels = (labelInfo.row - 1) * 5 + (labelInfo.column - 1) 
        const {data} = await axios.post(`/users/barcodes`, {rows: selectedRows, emptyLabels}, {timeout: 30000})
        window.open(data.url, '_blank')
        handleClose()
    } catch(err) {
        handleErrors({err})
    } finally {
        setLoading(false)
    }
  }

  if (user.role.name !== 'superAdmin') return null

  return (
    <>
      <Tooltip title="Download Barcodes">
        <IconButton onClick={handleClickOpen}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Download Barcodes`}
        // actions={<>
        //   <Button onClick={handleClose} color="primary" autoFocus>
        //     Cancel
        //   </Button>
        //   <Button loading={loading} onClick={handleConfirm} color="secondary">
        //     Download Orders
        //   </Button>
        // </>}
      >
        <Form
          onSubmit={handleConfirm}
          methods={methods}
        >
          Start row <Input type="number" name="row" /><br />
          Start column <Input type="number" name="column" /><br /><br />
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button loading={loading} type="submit" color="secondary">
            Download Barcodes
          </Button>
        </Form>
      </BasicDialog>
    </>
  );
}