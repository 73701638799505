import React from 'react'
import DefaultTable from '../table/DefaultTable'
import { LocationDialog } from './Location'
import useTitle from '../../utils/useTitle'

export default function Index() {
  useTitle('Locations')

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'State',
        accessor: 'province_code',
      },
      {
        Header: 'Retailer',
        accessor: 'retailer.name',
        disableFilters: true
      },
    ],
    []
  )

  return (
    <DefaultTable 
      columns={columns} 
      routeOptions={{route: 'locations', selected: 'name province_code', populated: {path: 'retailer', select: 'name'}}}
      header='Location'
      Dialog={LocationDialog}
    />
  )
}