import React from 'react'
import { Checkbox as MCheckbox, FormControlLabel } from '@material-ui/core'
import { useFormContext, useController } from 'react-hook-form'

const Checkbox = ({ 
  color='primary', 
  label,
  name,
  ...rest
}) => {
  const { control } = useFormContext()

  const {
        field: { onChange, onBlur, value, ref },
    } = useController({
        name,
        control,
        rules: { required: true },
    });

  return (
    <FormControlLabel
        control={<MCheckbox 
            checked={value}
            name={name} value={value} inputRef={ref} 
            onChange={onChange} onBlur={onBlur} {...rest} 
        />}
        label={label}
    />
  )
}

export default Checkbox;