import { Button, Input } from '@material-ui/core'
import React, {useState} from 'react'
import { useAuthContext } from '../../auth'
import { handleErrors } from '../../utils'
import BasicDialog from '../form/BasicDialog'
import axios from '../../axios'

const ChangePassword = ({userId}) => {
    const [open, setOpen] = useState(false)
    const [pass, setPass] = useState("")
    const handleClose = () => setOpen(false)
    const handleChange = async () => {
        try {
            await axios.put(`/users/changePassword/${userId}`, {
                password: pass, confirmPassword: pass
            })
            setOpen(false)
        } catch(err) {
            handleErrors({err})
        } 
    }
    const {hasPermission} = useAuthContext()
    if (!hasPermission('writeUser'))
        return null

    return <>
        <Button type="button" color="primary" onClick={() => setOpen(true)}>Change Password</Button>
        <BasicDialog
            open={open}
            onClose={handleClose}
            title="Change Password"
            actions={<>
            <Button onClick={handleClose} color="primary" autoFocus>
                Cancel
            </Button>
            <Button onClick={handleChange} color="secondary">
                Change Password
            </Button>
            </>}
        >
            <Input autoComplete="new-password" value={pass} onChange={e => setPass(e.target.value)} type="password" />
        </BasicDialog>
      </>
}

export default ChangePassword