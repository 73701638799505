import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useMediaQuery, useTheme, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  actions: {
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      padding: '15px',
      backgroundColor: '#efefef'
    },
  },
}))

export default function DeleteDialog({title, children, actions, ...props}) {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      fullScreen={fullScreen}
      style={{zIndex: '1200'}}
      {...props}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {actions}
      </DialogActions>
    </Dialog>
  );
}