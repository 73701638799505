import React from 'react'
import * as Yup from 'yup'

import { Grid, MenuItem } from '@material-ui/core'
import Input from '../form/Input'
import FormDialog from '../form/FormDialog'
import AsyncAutoComplete from '../form/AutoComplete'
import InputSkeleton from '../skeletons/InputSkeleton'
import Select from '../form/Select'

import ChangePassword from './ChangePassword'
import { useFormContext } from 'react-hook-form'

const UserSchema = ({isNew}) => Yup.object().shape({
  name: Yup
    .string()
    .required('Name is required'),
  role: Yup.object().shape({
    _id: Yup
      .string()
      .required('Role is required')
  }),
  user_type: Yup.string().oneOf([
      'admin', 'supplier', 'retailer', 'vs_retailer'
  ]),
  email: Yup.string().email('Invalid email'),
  supplier: Yup.object().shape({
    _id: Yup
      .string()
  }).nullable(),
  retailer: Yup.object().shape({
    _id: Yup
      .string()
  }).nullable(),
  vs_retailer: Yup.object().shape({
    _id: Yup
      .string()
  }).nullable(),
  supplier_id: Yup.number(),
  username: Yup
    .string()
    .required('Username is required.'),
//   ...(isNew && {
//   password: Yup
//     .string()
//     .required('Password is required.'),
//   confirmPassword: Yup
//     .string()
//     .oneOf([Yup.ref('password'), null], 'Passwords must match')
//     .required('Please confirm password.')
//   })
})

const UserSkeleton = () => (
  <Grid container spacing={1}>
    {[...Array(4)].map((x, i) => <Grid key={i} item xs={6}>
      <InputSkeleton />
    </Grid>)}
    </Grid>
)

export default function User({isNew, userId}) {
    const { watch } = useFormContext()
    const userType = watch('user_type')
    
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Input 
          name="name"
          label="Name"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="email"
          label="Email"
        />
      </Grid>
      <Grid item xs={6}>
        <AsyncAutoComplete
          name="role"
          errorPath="role._id"
          label="Role"
          routeOptions={{route: 'roles', selected: 'name', searchField: 'name'}}
          getOptionSelected={(option, value) => {
            return option._id === value._id
          }}
          getOptionLabel={option => option.name || ''}
        />
      </Grid>
      <Grid item xs={6}>
        <Select 
            name="user_type"
            label="User"
            select
        >
            <MenuItem value="admin">
                Admin
            </MenuItem>
            <MenuItem value="supplier">
                Vendor
            </MenuItem>
            <MenuItem value="retailer">
                Retailer
            </MenuItem>
            <MenuItem value="vs_retailer">
                VS Retailer
            </MenuItem>
        </Select>
      </Grid>
      {userType === 'retailer' && <Grid item xs={6}>
        <AsyncAutoComplete
          name="retailer"
          errorPath="retailer"
          label="Retailer"
          routeOptions={{route: 'retailers', selected: 'name', searchField: 'name'}}
          getOptionSelected={(option, value) => {
            return option._id === value._id
          }}
          getOptionLabel={option => option.name || ''}
        />
      </Grid>}
      {userType === 'supplier' && <Grid item xs={6}>
        <AsyncAutoComplete
          name="supplier"
          errorPath="supplier._id"
          label="Vendor"
          routeOptions={{route: 'suppliers', selected: 'name supplier_id', searchField: 'name'}}
          getOptionSelected={(option, value) => {
            return option.supplier_id === value.supplier_id
          }}
          getOptionLabel={option => option.name || ''}
        />
      </Grid>}
      <Grid item xs={6}>
        <Input 
          name="username"
          autoComplete="username"
          label="Username"
        />
      </Grid>
      {!isNew && <ChangePassword userId={userId} />}
      {/* {isNew ? <><Grid item xs={6}>
        <Input 
          name="password"
          type="password"
          autoComplete="new-password"
          label="Password"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="confirmPassword"
          type="password"
          autoComplete="new-password"
          label="Confirm Password"
        />
      </Grid></> : <ChangePassword userId={userId} />} */}
    </Grid>
  )
}

const initialValues = {
  name: '',
  role: {_id: ''},
  user_type: '',
  username: '',
//   password: '',
//   confirmPassword: '',
  email: ''
}

const transformData = data => {
  data.role = data.role?._id || undefined
  data.retailer = data.retailer?._id || undefined
  data.supplier_id = data.supplier?.supplier_id || undefined
}

export function UserDialog(props) {
  return (
    <FormDialog 
      title="User"
      routeOptions={{route: 'users', populated: 'role supplier retailer vs_retailer'}}
      transformData={transformData}
      validationSchema={UserSchema}
      Skeleton={UserSkeleton}
      defaultValues={initialValues}
      timeout={60000}
      {...props}
    >
      <User userId={props.itemId} isNew={!props.itemId} />
    </FormDialog>
  )
}