import React from 'react'
import DefaultTable from '../table/DefaultTable'
import { RepDialog } from './Rep'
import useTitle from '../../utils/useTitle'

export default function Index() {
  useTitle('Reps')

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'State',
        accessor: 'state',
        disableFilters: true
      },
    ],
    []
  )

  return (
    <DefaultTable 
      columns={columns} 
      routeOptions={{route: 'reps', selected: 'name email state'}}
      header='Rep'
      Dialog={RepDialog}
    />
  )
}