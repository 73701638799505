import { Checkbox } from '@material-ui/core'
import React from 'react'
import useTitle from '../utils/useTitle'

export default function Settings() {
  useTitle('Settings')
  const [freezeRow, setFreezeRow] = React.useState(localStorage.getItem('freeze-row') !== 'false')
  const [freezeColumn,setFreezeColumn] = React.useState(localStorage.getItem('freeze-column') !== 'false')

  return (
    <div>
      <h2>Settings</h2>
      Freeze table header <Checkbox checked={freezeRow} onChange={() => setFreezeRow(x => {
        localStorage.setItem('freeze-row', !!x ? 'false' : 'true')
        return !x
      })} /><br />
      Freeze table column <Checkbox checked={freezeColumn} onChange={() => setFreezeColumn(x => {
        localStorage.setItem('freeze-column', !!x ? 'false' : 'true')
        return !x
      })} />
    </div>
  )
}
