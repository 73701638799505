import React from 'react'
import * as Yup from 'yup'

import { Grid, MenuItem } from '@material-ui/core'
import Input from '../form/Input'
import FormDialog from '../form/FormDialog'
import InputSkeleton from '../skeletons/InputSkeleton'
import Checkbox from '../form/Checkbox'
import Select from '../form/Select'
import states from '../../utils/states.json'
import countries from '../../utils/countries.json'
import axios from '../../axios'
import { useFormContext } from 'react-hook-form'
import { round } from 'lodash'

const RepSchema = Yup.object().shape({
    first_name: Yup
      .string()
        .required('First name is required'),
    last_name: Yup
      .string()
        .required('Last name is required'),
    active: Yup.boolean(),
    address1: Yup.string()
        .required('Address is required'),
    address2: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    zip: Yup.string().required('Zipcode is required'),
    phone: Yup.string(),
    email: Yup.string().email(),
    delivery: Yup.string().oneOf([
      'Home Address',
      'FedEx Print and Ship',
      'Hand Delivery'
    ]),
    location_id: Yup.string()
})

const RepSkeleton = () => (
  <Grid container spacing={1}>
    {[...Array(8)].map((x, i) => <Grid key={i} item xs={6}>
      <InputSkeleton />
    </Grid>)}
  </Grid>
)

export default function Rep() {
  const [locations, setLocations] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const {
    getValues
  } = useFormContext()

  const getLocations = async () => {
    try {
      setLoading(true)
      const info = getValues()
      const {data} = await axios.post('/reps/locations', info)
      setLocations(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Input 
            name="first_name"
            label="First Name"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="last_name"
            label="Last Name"
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox 
            name="active"
            label="Active"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="address1"
            label="Address 1"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="address2"
            label="Address 2"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="city"
            label="City"
          />
        </Grid>
        <Grid item xs={6}>
          <Select 
            name="state"
            label="State"
          >
              {states.map(x => <MenuItem value={x.code}>{x.name}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select 
            name="country"
            label="Country"
          >
              {countries.map(x => <MenuItem value={x.code}>{x.name}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="zip"
            label="Zip"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="phone"
            label="Phone"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
      <Grid item xs={6}>
        <Select 
          name="delivery"
          label="Delivery Type"
        >
          <MenuItem value="Home Address">Home Address</MenuItem>
          <MenuItem value="FedEx Print and Ship">FedEx Print and Ship</MenuItem>
          <MenuItem value="Hand Delivery">Hand Delivery</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="email"
          label="Email"
        />
      </Grid>
      <Grid item xs={6}>
        <Select 
          name="location_id"
          label="Location"
          onFocus={getLocations}
          renderValue={value => value}
        >
          <MenuItem value="">None</MenuItem>
          {locations.map(x => <MenuItem value={x.locationId}>
            {x.address.streetLines} 
            {x.address.city}, {x.address.stateOrProvinceCode} {x.address.postalCode}
            <b style={{marginLeft: '5px'}}>{round(x.distance.value, 1)} {x.distance.units}</b>
          </MenuItem>)}
        </Select>
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="inactive_from"
          label="Inactive From"
          type="date"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="inactive_to"
          label="Inactive To"
          type="date"
        />
      </Grid>
      </Grid>
    </>
  )
}

const initialValues = {
    first_name: '',
    last_name: '',
    active: true,
    address1: '',
    address2: '',
    city: '',
    province: '',
    country: 'US',
    zip: '',
    phone: '',
    delivery: '',
    email: '',
    location_id: '',
    inactive_from: '',
    inactive_to: ''
}

export function RepDialog(props) {
  return (
    <FormDialog 
      title="Rep"
      routeOptions={{route: 'reps', populated: 'retailer'}}
      validationSchema={RepSchema}
      Skeleton={RepSkeleton}
      defaultValues={initialValues}
      {...props}
    >
      <Rep />
    </FormDialog>
  )
}