import React from 'react'
import Button from '@material-ui/core/Button'
import LabelIcon from '@material-ui/icons/Label'
import { Tooltip, IconButton, Checkbox } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'
import { toast } from 'react-toastify'

export default function ShippingLabel({selectedRows, fetchData}) {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [reprint, setReprint] = React.useState(false)
  const [autoSaturdayDelivery, setAutoSaturdayDelivery] = React.useState(true)
  const [saturdayDelivery, setSaturdayDelivery] = React.useState(false)
  const [twoDayDelivery, setTwoDayDelivery] = React.useState(false)
  const [returnLabel, setReturnLabel] = React.useState(false)

  const handleClickOpen = () => {
    setReprint(false)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
        try {
            setLoading(true)
            const {data} = await axios.post(`/order-reports/shipping-label`, {ids: selectedRows.filter(x => x.active).map(x => x.order_id), reprint, saturdayDelivery, autoSaturdayDelivery, twoDayDelivery, returnLabel}, {timeout: 60000})
            toast.success('Successfully created shipping labels')
            window.open(data.url, '_blank')
            fetchData()
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

  return (
    <>
      <Tooltip title={`Create shipping label`}>
        <IconButton onClick={handleClickOpen}>
          <LabelIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Create shipping label`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button loading={loading} disabled={loading} onClick={handleConfirm} color="secondary">
            Create shipping labels
          </Button>
        </>}
      >
        Are you sure you want to create shipping labels for the selected orders?< br/>
        Recreate labels with existing tracking numbers <Checkbox checked={reprint} onChange={e => setReprint(x => !x)} />< br/>
        Auto Saturday delivery <Checkbox checked={autoSaturdayDelivery} onChange={e => setAutoSaturdayDelivery(x => !x)} />< br/>
        Saturday delivery <Checkbox checked={saturdayDelivery} onChange={e => setSaturdayDelivery(x => !x)} />< br/>
        Two day delivery <Checkbox checked={twoDayDelivery} onChange={e => setTwoDayDelivery(x => !x)} />< br/>
        Include return label <Checkbox checked={returnLabel} onChange={e => setReturnLabel(x => !x)} />< br/>
      </BasicDialog>
    </>
  );
}