import React, {useEffect} from 'react'
import Button from '@material-ui/core/Button'
import { CircularProgress, TextField, Tooltip } from '@material-ui/core'
import { handleErrors, strEqFilter } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { debounce } from 'lodash'

export default function AssignOrder({id, fetchData, children, route='orders', name='Order'}) {
  const [open, setOpen] = React.useState(false)
  const [assignedTo, setAssignedTo] = React.useState(false)
  const [assignedToError, setAssignedToError] = React.useState('')
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getData = React.useCallback(async (term) => {
    setLoading(true)
    try {
      const {data: res} = await axios.post(`/users/table`, {
        ...(term && {filter: strEqFilter('name', term)}),
        selected: 'name'
      })

      setOptions(res.data)
    } catch(err) {
      handleErrors({err})
    } finally {
      setLoading(false)
    }
  }, [])

  const debouncedGetData = React.useCallback(debounce(getData, 900), [getData])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    } else {
      getData()
    }
  }, [open])

    const handleConfirm = async () => {
        try {
            // if (!assignedTo) {
            //     setAssignedToError('Required')
            //     return
            // }
            await axios.patch(`/${route}/assign/${id}`, {assigned_to: assignedTo || null})
            setOpen(false)
            fetchData()
        } catch(err) {
            handleErrors({err})
            // setOpen(false)
        }
    }

  return (
    <>
      <Tooltip title={`Assign ${name}`}>
        <Button onClick={handleClickOpen}>
          {children}
        </Button>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Assign ${name}`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="secondary">
            Assign {name}
          </Button>
        </>}
      >
          <Autocomplete 
            open={autocompleteOpen}
            onOpen={() => {
              setAutocompleteOpen(true);
            }}
            onClose={() => {
              setAutocompleteOpen(false);
            }}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={options}
            loading={loading}
            onChange={(_, data) => {
                setAssignedTo(data._id)
            }}
            onInputChange={(event, newInputValue) => {
                if (open) debouncedGetData(newInputValue)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="User"
                error={!!assignedToError}
                helperText={assignedToError}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        {/* <TextField 
            label="Tracking Number"
                onChange={e => {
                    setTracking(e.target.value)
                    setTrackingError('')
                }
            }
            error={!!assignedToError}
            helperText={assignedToError}
        /> */}
      </BasicDialog>
    </>
  );
}