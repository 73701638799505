import React from 'react'
import Button from '@material-ui/core/Button'
import EmailIcon from '@material-ui/icons/Email'
import { Tooltip, IconButton, DialogContentText } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'
import { toast } from 'react-toastify'

export default function DelayedEmail({selectedRows}) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
    try {
      await axios.post(`/orders/delayedemail`, {rows: selectedRows})
      setOpen(false)
      toast.success('Email successfully sent')
    } catch(err) {
      handleErrors({err})
      setOpen(false)
    }
  }

  return (
    <>
      <Tooltip title="Send order delayed email">
        <IconButton onClick={handleClickOpen}>
          <EmailIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Send order delayed email`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="secondary">
            Send Email
          </Button>
        </>}
      >
        <DialogContentText id="alert-dialog-description">
          Send order delayed email for orders: <br />
          {selectedRows.map(x => x.name).join(', ')}
        </DialogContentText>
      </BasicDialog>
    </>
  );
}