import React from 'react'
import { TextField } from '@material-ui/core'
import { useController, useFormContext } from 'react-hook-form'
import { get } from 'lodash'

const Input = ({ name='', ...rest }) => {
  const { control } = useFormContext()

  const {
    field: { onChange, onBlur, value, ref },
    formState: {errors}
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  const err = get(errors, name)
  const val = rest.type === 'date' ? (value ? new Date(value).toISOString().split('T')[0] : value) : value
  
  return (
    <TextField 
        onChange={onChange} 
        onBlur={onBlur} 
        value={val} 
        name={name} 
        inputRef={ref}
        error={!!err}
        helperText={err?.message}
        {...rest}
    />
  );
//   return (
//     <Controller
//         name={name}
//         control={control}
//         render={({ field }) => <TextField 
//             name={name}
//             inputRef={register}
//             error={!!err}
//             helperText={err?.message}
//             {...field}
//             {...rest}
//       />}
//     />
//   )
}

export default Input;