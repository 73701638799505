import React from 'react'
import Button from '@material-ui/core/Button'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { Tooltip, IconButton, Checkbox } from '@material-ui/core'
import BasicDialog from '../form/BasicDialog'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import { useForm } from 'react-hook-form';
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Form from '../form'
import Input from '../form/Input'

const labelSchema = Yup.object().shape({
  row: Yup
    .number()
    .integer()
    .min(1)
    .max(10),
  column: Yup
    .number()
    .integer()
    .min(1)
    .max(5),
})

const defaultValues = {
  row: 1, column: 1
}

export default function DownloadReport({rows}) {
  const [open, setOpen] = React.useState(false)
  const [, setLoading] = React.useState(false)
  const [labels, setLabels] = React.useState(false)

  const handleClickOpen = () => {
    setLabels(false)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const methods = useForm({ 
    defaultValues, resolver: yupResolver(labelSchema),
  })

  const handleConfirm = async (labelInfo) => {
    try {
        setLoading(true)
        if (labels) {
            const selectedRows = rows
                .filter(x => x.lot_id)
                .map(({name, lot_id, processed_at}) => ({name, lot_id, processed_at}))
            const emptyLabels = (labelInfo.row - 1) * 5 + (labelInfo.column - 1) 
            const {data} = await axios.post(`/order-reports/report`, {rows: selectedRows, emptyLabels}, {timeout: 30000})
            window.open(data.url, '_blank')
        } else {
            const headers = ['Order Number', 'Due Date', 'SKU', 'Diamond Vendor', 'Fulfillment Status', 'Lot ID', 'Production Status', 'Metal Consumed']
            const body = rows
                .map(({name, delivery_by, sku, supplier_id, fulfillment_status, lot_id, production_status, metal_consumed}) => [name, delivery_by, sku, supplier_id, fulfillment_status, lot_id, production_status, metal_consumed])
            const csv = [headers].concat(body).map(x => x.map(y => `"${y || ''}"`).join(",")).join("\n")
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", 'report.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        handleClose()
    } catch(err) {
        handleErrors({err})
    } finally {
        setLoading(false)
    }
  }

  return (
    <>
      <Tooltip title="Download Orders">
        <IconButton onClick={handleClickOpen}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Download Orders`}
        // actions={<>
        //   <Button onClick={handleClose} color="primary" autoFocus>
        //     Cancel
        //   </Button>
        //   <Button loading={loading} onClick={handleConfirm} color="secondary">
        //     Download Orders
        //   </Button>
        // </>}
      >
        Labels <Checkbox checked={labels} onChange={() => setLabels(x => !x)} /><br />
        <Form
          onSubmit={handleConfirm}
          methods={methods}
        >
          Start row <Input type="number" name="row" /><br />
          Start column <Input type="number" name="column" /><br /><br />
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button type="submit" color="secondary">
            Download Orders
          </Button>
        </Form>
      </BasicDialog>
    </>
  );
}