import React from 'react'
import DefaultTable from '../table/DefaultTable'
import useTitle from '../../utils/useTitle'
import {formatCurrency, getLocal, setLocal} from '../../utils'
import { Checkbox, Chip, TextField } from '@material-ui/core'
import EditDeliveryDate from './EditDeliveryDate'
import EditTracking from './EditTracking'
import { Button } from '@material-ui/core'
import CapturePayment from './CapturePayment'
import MarkPaid from './MarkPaid'
import { Link, useHistory, useLocation } from 'react-router-dom'
import PackingSlip from './PackingSlip'
import MarkCancelled from './MarkCancelled'
import MarkFulfilled from './MarkFulfilled'
import ShippingLabel from './ShippingLabel'
import PrintLabel from './PrintLabel'
import EditAddress from './EditAddress'
import ReceiptIcon from '@material-ui/icons/Receipt'
import LabelIcon from '@material-ui/icons/Label'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import DownloadReport from './DownloadReport'
import {keyBy} from 'lodash'
import ReorderColumns from '../../utils/ReorderColumns'
import moment from 'moment'
import { sumBy } from 'lodash'
import CertSearch from './CertSearch'

const statuses = {
  pending: 'Pending',
  unavailable: 'Unavailable',
  on_hold: 'On hold',
  ready_to_ship: 'Ready to ship',
  shipped: 'Shipped',
  cancelled: 'Cancelled',
  received: 'Received'
}

const promotions = {
    112: '12 Months With Payments, Deferred Interest',
    711: '36 Months Fixed Payments',
    712: '60 Months Fixed Payments'
}

const Address = ({
    order_id, order_number, fedex_address, shipping_address, fetchData, checkoutType
}) => {
    const address = fedex_address || shipping_address || {}
    const {
        first_name, last_name,
        city, state, zip,
        address1
    } = address

    return <div style={{minWidth: '200px'}}>
        {first_name} {last_name}
        {checkoutType === 'ship' && <EditAddress id={order_id} name={order_number} address={address} fetchData={fetchData} /> }
        < br/>
        {address1}< br/>
        {city}, {state} {zip}
    </div>
}

const columnKeyArr = [
    {
        Header: 'Name',
        accessor: 'name',
        Cell: ({value, row: {original}}) => <div>
            <Link to={`/customer-orders/${original.customer_order_id}`}>{value}</Link><br />
            {original.invoice_printed_at && <ReceiptIcon />}
            {original.tracking && <LabelIcon />}
            {original.order_fulfillment_status === 'fulfilled' && <LocalShippingIcon />}
        </div>
    },
    {
        Header: 'Checkout Type',
        accessor: 'checkoutType',
        disableFilters: true
    },
    {
        Header: 'Total',
        accessor: 'total_price',
        Cell: ({value}) => formatCurrency(value)
    },
    {
        Header: 'Payments',
        accessor: 'payments',
        disableFilters: true,
        Cell: ({value}) => <div style={{minWidth: '250px'}}>
            {value?.map(({title, amount, card_cvv, card_number, account_type='', created_at, promotion_type, status, type}, idx) => {
                const promotion = `Promotion: ${promotions[promotion_type]}`
                const cvv = `CVV: ${card_cvv}`
                return <div key={idx} style={{marginTop: '8px', marginBottom: '8px'}}>
                    {formatCurrency(amount)} on {title || card_number} <br />
                    {(promotion_type || card_cvv) && <>{(!!card_cvv && cvv) || (promotion_type && promotion)} <br /></>}
                    {account_type ? account_type + ' - ' : ''}{moment(created_at).format('M/D/YYYY')}<br />
                    {status || type}
                </div>
            })}
        </div>,
    },
    {
        Header: 'Tags',
        accessor: 'tags',
        Cell: ({value}) => <div style={{display: 'flex', flexWrap: 'wrap', minWidth: '200px', gap: '5px'}}>
            {value.split(', ').filter(x => x.trim()).map(x => <Chip key={x} label={x} />)}
        </div>
    },
    {
        Header: 'Ship By',
        accessor: 'ship_by',
    },
    {
        Header: 'Original Due Date',
        accessor: 'original_delivery_by',
    },
    {
        Header: 'Due Date',
        accessor: 'delivery_by',
        Cell: ({value, row, initialState: {getFetcher}}) => <div style={{minWidth: '130px'}}>
            {value} <EditDeliveryDate row={row.original} fetchData={getFetcher()} />
        </div>
    },
    {
        Header: 'SKU',
        accessor: 'sku',
    },
    {
        Header: 'Supplier',
        accessor: 'supplier_id',
        disableFilters: true
    },
    {
        Header: 'Vendor SKU',
        accessor: 'supplier_sku',
        disableFilters: true
    },
    {
        Header: 'Carat',
        accessor: 'carat',
        disableFilters: true
    },
    {
        Header: 'Shape',
        accessor: 'shape',
        disableFilters: true
    },
    {
        Header: 'Cert Number',
        accessor: 'certificate_number',
        disableFilters: true
    },
    {
        Header: 'Diamond Tracking',
        accessor: 'supplier_tracking',
        Cell: ({value=''}) => {
            const x = value.trim()
            let url = ''
            if (/^\d+$/.test(x)) {
                if (x.length <= 9)
                    url = 'https://tracking.malca-amit.com/'
                else
                    url = 'https://www.fedex.com/fedextrack/?trknbr='
                if (url)
                    url = url + x
            }
            return url ? <a href={url} target="_blank" rel="noopener noreferrer">{x}</a> : x
        },
        disableFilters: true
    },
    {
        Header: 'Shipping Address',
        accessor: 'fedex_address',
        disableFilters: true,
        Cell: ({row, initialState: {getFetcher}}) => <Address {...row.original} fetchData={getFetcher()} />
    },
    {
        Header: 'Tracking',
        accessor: 'tracking',
        Cell: ({value='', initialState: {getFetcher}, row}) => <div>
            {value && <a href={`https://www.fedex.com/fedextrack/?trknbr=${value}`} target="_blank" rel="noopener noreferrer">{value}</a> }
            <EditTracking row={row.original} fetchData={getFetcher()} />
        </div>
    },
    {
        Header: 'Fulfillment Status',
        accessor: 'fulfillment_status',
        Cell: ({value}) => statuses[value] || '',
        disableFilters: true,
    },
    {
        Header: 'Lot ID',
        accessor: 'lot_id',
    },
    {
        Header: 'Production Status',
        accessor: 'production_status',
        disableFilters: true
    },
    {
        Header: 'Metal Consumed',
        accessor: 'metal_consumed',
        disableFilters: true
    },
    {
        Header: 'Notes',
        accessor: 'notes',
        Cell: ({value}) => <div style={{whiteSpace: 'pre'}}>{value}</div>
    }
]

const columnKey = keyBy(columnKeyArr, 'Header')
const defaultColumnOrder = columnKeyArr.map(({Header}) => ({value: Header, hide: false}))

export default function Index() {
    const [certs, setCerts] = React.useState('')
    const [columnOrder, setColumnOrder] = React.useState(getLocal('order-report-column-order', defaultColumnOrder))
    const updateColumnOrder = (order) => {
        setLocal('order-report-column-order', order)
        setColumnOrder(order)
    }

    const history = useHistory()
    const query = new URLSearchParams(useLocation().search)
    const time = query.get('time')
    const startDate = query.get('startdate') || moment().format('YYYY-MM-DD')
    const endDate = query.get('enddate') || moment().format('YYYY-MM-DD')
    const showCancelled = query.get('cancelled') === 'true'
    const showRepairs = query.get('repairs') === 'true'
    const showFulfilled = query.get('fulfilled') === 'true'
    const showVIP = query.get('vip') === 'true'
    const showLoose = query.get('loose') !== 'false'
    const showNotLoose = query.get('notloose') !== 'false'
    const showPickup = query.get('pickup') !== 'false'
    const showShip = query.get('ship') !== 'false'
    useTitle('Order Report')

    const updatePageHandler = (params, reload=false) => () => {
        for (let key in params) {
            query.set(key, params[key])
        }
        history.replace('/order-reports?' + query.toString())
        if (reload)
            history.go(0)
    }

    const columns = React.useMemo(
        () => columnOrder.filter(x => !x.hide && columnKey[x.value]).map(x => columnKey[x.value]), [columnOrder]
    )

    const filtered = []
    if (!showCancelled)
        filtered.push({cancelled_at: null})
    if (!showRepairs)
        filtered.push({purpose_of_order_status: {$in: [null, '']}})
    if (!showFulfilled)
        filtered.push({fulfillment_status: {$ne: 'fulfilled'}})
    if (!showVIP)
        filtered.push({is_vip: false})
    if (!showLoose)
        filtered.push({has_loose: false})
    if (!showNotLoose)
        filtered.push({has_loose: true})
    if (!showPickup)
        filtered.push({is_pickup: false})
    if (!showShip)
        filtered.push({is_pickup: true})

    return (
        <>
            <Button color={time === 'at-risk' ? 'secondary' : 'primary'} onClick={updatePageHandler({time: 'at-risk'})}>At Risk</Button>
            <Button color={!time || time === 'today' ? 'secondary' : 'primary'} onClick={updatePageHandler({time: 'today'})}>Today</Button>
            <Button color={time === 'tomorrow' ? 'secondary' : 'primary'} onClick={updatePageHandler({time: 'tomorrow'})}>Tomorrow</Button>
            <Button color={time === 'all' ? 'secondary' : 'primary'} onClick={updatePageHandler({time: 'all'})}>All time</Button>
            <Button color={time === 'range' ? 'secondary' : 'primary'} onClick={updatePageHandler({time: 'range'})}>Date Range</Button>
            <TextField
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                type="date"
                defaultValue={startDate}
                onBlur={e => updatePageHandler({startdate: e.target.value})()}
                style={{margin: '0 20px'}}
            />
            <TextField
                label="End Date"
                InputLabelProps={{ shrink: true }}
                type="date"
                defaultValue={endDate}
                onChange={e => updatePageHandler({enddate: e.target.value})()}
            />

            <ReorderColumns values={columnOrder} updateValues={updateColumnOrder} defaultValues={defaultColumnOrder} />
            <div>
                Cancelled <Checkbox style={{marginRight: '25px'}} checked={showCancelled} onClick={updatePageHandler({cancelled: !showCancelled ? 'true' : ''}, true)} />
                Repairs <Checkbox style={{marginRight: '25px'}} checked={showRepairs} onClick={updatePageHandler({repairs: !showRepairs ? 'true' : ''}, true)} />
                Fulfilled <Checkbox style={{marginRight: '25px'}} checked={showFulfilled} onClick={updatePageHandler({fulfilled: !showFulfilled ? 'true' : ''}, true)} />
                VIP <Checkbox style={{marginRight: '25px'}} checked={showVIP} onClick={updatePageHandler({vip: !showVIP ? 'true' : ''}, true)} />
                Loose Diamonds <Checkbox style={{marginRight: '25px'}} checked={showLoose} onClick={updatePageHandler({notloose: showLoose || showNotLoose ? 'true' : 'false', loose: !showLoose ? 'true' : 'false'}, true)} />
                Non Loose Diamonds <Checkbox style={{marginRight: '25px'}} checked={showNotLoose} onClick={updatePageHandler({notloose: !showNotLoose ? 'true' : 'false', loose: showNotLoose || showLoose ? 'true' : 'false'}, true)} />
                Pickup <Checkbox style={{marginRight: '25px'}} checked={showPickup} onClick={updatePageHandler({pickup: !showPickup ? 'true' : 'false', ship: showPickup || showShip ? 'true' : 'false'}, true)} />
                Ship <Checkbox style={{marginRight: '25px'}} checked={showShip} onClick={updatePageHandler({ship: !showShip ? 'true' : 'false', pickup: showShip || showPickup ? 'true' : 'false'}, true)} />
            </div>
            <DefaultTable 
                columns={columns} 
                routeOptions={{
                    route: `order-reports`, query: `time=${time}&start=${startDate}&end=${endDate}&certs=${certs}`, filtered
                }}
                header='Order Report'
                actions={{showActions: false}}
                getRowProps={(x, {row}) => {
                    const {financial_status, payment_status, delivery_by, original_delivery_by, payments, total_price, active} = row.original
                    const paidAmt = sumBy(payments?.filter(x => x.type === 'payment'), 'amount')
                    const green = (financial_status === 'paid' || payment_status === 'paid' || paidAmt + 0.05 >= total_price) ? {className: 'green'} : {}
                    const orange = original_delivery_by < delivery_by ? {className: 'orange'} : {}
                    const red = payments?.some(({created_at, account_type}) => account_type === 'Visa' && (new Date() - new Date(created_at)) > 7*86400*1000) ? {className: 'red'} : {}
                    const lightRed = (typeof active === 'boolean' && !active) ? {className: 'light-red'} : {}
                    return {...x, ...orange, ...red, ...green, ...lightRed}
                }}
                searches={<CertSearch onChange={setCerts} />}
                selectedActions={[
                    ({selectedRows, fetchData}) => <div style={{display: 'flex'}}>
                        <CapturePayment selectedRows={selectedRows} fetchData={fetchData} />
                        <MarkPaid selectedRows={selectedRows} fetchData={fetchData} markPaid />
                        <MarkPaid selectedRows={selectedRows} fetchData={fetchData} />
                        <PackingSlip selectedRows={selectedRows} />
                        <ShippingLabel selectedRows={selectedRows} fetchData={fetchData} />
                        <PrintLabel selectedRows={selectedRows} fetchData={fetchData} />
                        <MarkCancelled selectedRows={selectedRows} fetchData={fetchData} />
                        <MarkFulfilled selectedRows={selectedRows} fetchData={fetchData} />
                        <DownloadReport rows={selectedRows} />
                    </div>
                ]}
            />
        </>
    )
}