import React from 'react'
import { makeStyles, LinearProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  },
  linearProgress: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%'
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px'
    }
  }
}));

export default function PageLoading() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <LinearProgress className={classes.linearProgress} />
      <Typography className={classes.logo} variant="h1">
        Ritani
      </Typography>
    </div>
  )
}
