export const createBarcodeScanner = ({timeframe=75, maxDelayBetweenKeys=50, onBarcode=console.log}) => {
    let buffer = [];
    let timeout = null;
    let lastKeyEventTimestamp = null;

    function handleKeyEvent(e) {
        const currentKeyEventTimestamp = Date.now();
        const delayBetweenKeys = lastKeyEventTimestamp
        ? currentKeyEventTimestamp - lastKeyEventTimestamp
        : 0;

        if (delayBetweenKeys > maxDelayBetweenKeys && buffer.length > 0) {
            buffer = [];
        }

        lastKeyEventTimestamp = currentKeyEventTimestamp;

        if (timeout) {
            clearTimeout(timeout);
        }

        buffer.push(e.key);

        timeout = setTimeout(() => {
            processBuffer();
        }, timeframe);
    }

    function processBuffer() {
        const barcode = buffer.join('').replace(/Enter/g, '').replace(/Shift/g, '');
        onBarcode(barcode);

        buffer = [];
        timeout = null;
        lastKeyEventTimestamp = null;
    }

    return {
        handleKeyEvent,
    };
  }