import React from 'react'

const html = `
<p><b><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
color:black;'>Purchase Order Terms and Conditions </span></b></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>All Purchase
Orders issued by Electronic Commodities Exchange L.P. d/b/a Ritani, LLC
('Ritani') are expressly conditioned on Vendor's acceptance of these Purchase
Order Terms and Conditions ('Terms and Conditions'). Vendor's full or partial
performance under a Purchase Order issued by Ritani shall constitute acceptance
of the Terms and Conditions. To the extent there is a conflict between the
Terms and Conditions and the Purchase Order, these Terms and Conditions shall
control. </span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;
'>Vendor represents and warrants that: </span></p>

<p style='margin-left:.5in;text-indent:-.25in'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span dir=LTR></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(a)
&nbsp;In performing its obligations under the Purchase Order, Vendor shall
comply (and shall require all of its employees and other agents involved in
Vendor's performance under the Purchase Order to comply) with all applicable
federal, state, local and foreign laws (including, without limitation, common
law), rules, statutes, regulations, ordinances, and other provisions having the
full force and effect of law, and orders, policies, judgments or requirements
of any governmental or regulatory authority, including, without limitation,
those described in greater detail in these Terms and Conditions. </span></p>

<p style='margin-left:.5in;text-indent:-.25in'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span dir=LTR></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(b)
&nbsp;Vendor is aware of the Kimberley Process Certification Scheme in all
countries in which Vendor does business and is and shall continue to be in full
compliance with same and any laws relating thereto. </span></p>

<p style='margin-left:.5in;text-indent:-.25in'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span dir=LTR></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(c)
&nbsp;Vendor is aware of and participates in the System of Warranties
established by the World Diamond Council, including without limitation: </span></p>

<p style='margin-left:1.0in;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>0.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>Vendor's invoices for rough diamonds, polished diamonds
and jewelry containing diamonds include the following statement, which is true
and correct: 'The diamonds herein invoiced have been purchased from legitimate
sources not involved in funding conflict and in compliance with United Nations
resolutions. The seller hereby guarantees that these diamonds are conflict
free, based on personal knowledge and/or written guarantees provided by the
supplier of these diamonds' (the 'Warranty Declaration'). </span></p>

<p style='margin-left:1.0in;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>Vendor trades only with companies whose invoices for
rough diamonds, polished diamonds and jewelry containing diamonds also include
the Warranty Declaration. </span></p>

<p style='margin-left:1.0in;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>Vendor does not buy diamonds from suspect sources or
unknown suppliers, or which originate in countries that have not implemented
the Kimberley Process Certification Scheme. </span></p>

<p style='margin-left:1.0in;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>Vendor does not buy diamonds from any sources that, after
a legally binding due process system, have been found to have violated
government regulations restricting the trade in conflict diamonds. </span></p>

<p style='margin-left:1.0in;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>Vendor does not buy diamonds in or from any region that
is subject to an advisory by a governmental authority indicating that conflict
diamonds are emanating from or available for sale in such region, unless
diamonds have been exported from such region in compliance with the Kimberley
Process Certification Scheme. </span></p>

<p style='margin-left:1.0in;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>Vendor does not knowingly buy or sell or assist others to
buy or sell conflict diamonds. </span></p>

<p style='margin-left:1.0in;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>Vendor ensures that all company employees who buy or sell
diamonds within the diamond trade are well informed regarding trade resolutions
and government </span></p>

<p style='margin-left:1.0in'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>regulations
restricting the trade in conflict diamonds. </span></p>

<p style='margin-left:1.0in;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>Vendor maintains records sufficient the statements in
this paragraph, and audits the </span></p>

<p style='margin-left:1.0in'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>warranties
made by others to Vendor. </span></p>
</span></p>

<p style='margin-left:.5in;text-indent:-.25in'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span dir=LTR></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(d)
&nbsp;Vendor shall not take any action directly or indirectly, that would
constitute a violation of the United States Foreign Corrupt Practices Act (the
'FCPA'), or any other applicable anti-bribery laws or regulations. </span></p>

<p style='margin-left:.5in;text-indent:-.25in'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span dir=LTR></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(e)
&nbsp;With respect to all Goods to be delivered under the Purchase Order,
Vendor represents and warrants that such Goods will (i) be merchantable, (ii)
be free from any defects in workmanship, materials and design, (iii) be
manufactured, packaged, labeled, handled, shipped and stored by Vendor or its
agent in a good and workman-like manner at quality levels consistent with
industry standards and in accordance with all applicable specifications,
drawings, designs, samples and other requirements specified by Ritani, (iv) be
fit, safe and effective for their intended uses and purposes, and operate as
intended, (v) be free and clear of all liens, security interests or other
encumbrances; and (vi) not infringe or violate the rights of any third person,
including intellectual property rights. </span></p>

<p style='margin-left:.5in;text-indent:-.25in'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span dir=LTR></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(f)
&nbsp;In accordance with International Labour Organization ('ILO') Convention
No.138 on Minimum Age, Vendor shall not employ children under the age of 15
even where the local law permits, or under the applicable legal minimum age,
whichever is higher. Vendor shall allow workers to complete compulsory education
in accordance with applicable laws. Vendor shall not subject workers under the
age of 18 to hazardous work, night work or overtime work in compliance with ILO
Convention No.182 on the Worst Forms of Child Labor. Vendor shall not use
apprenticeship and trainee schemes to avoid the provision of regular employment
or the payment of legally mandated wages and benefits. </span></p>

<p style='margin-left:.5in;text-indent:-.25in'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span dir=LTR></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(g)
&nbsp;Vendor shall not employ prison labor, indentured labor, bonded labor or
use corporal or other forms of mental and physical coercion as a form of
discipline. Vendor shall not conduct business with suppliers employing children
under the age of 15, prison labor, indentured labor, bonded labor or who use
corporal punishment or other forms of mental and physical coercion as a form of
discipline. </span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Vendor shall
indemnify, defend and hold harmless Ritani, its directors, officers,
shareholders, employees, affiliates, agents, contractors, principals, and
attorneys, from and against any and all loss, costs, penalties, fines, damages,
claims, expenses (including attorney's fees) or liabilities arising out of,
resulting from, or in connection with (i) Vendor's manufacture, processing
and/or supply of Goods to Ritani, (ii) any defect in the Goods, (iii) the
negligence or willful misconduct of Vendor, its agents or employees, (vi)
Vendor's breach of any representation, warranty, covenant or other obligation
herein, and/or (v) the infringement of any third party proprietary rights with
respect to Goods supplied by Vendor.</span></p>

<p class=MsoNormal><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Ritani supports all sanctions
against the Russian economy and will reject all stones that have been sourced
from Russia. Our diamond vendors are required to verify the origin of stones
they provide to us to sell to ensure that you are not providing us with Russian
sourced stones.' Ritani will not offer or sell any stones that have come from
Russia or even mined in Russia and manufactured elsewhere after March 15 2022</span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'><span
>Vendor agrees to: </span></span></p>

<p style='margin-left:.5in;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>(a) &nbsp;Ensure all stones offered to Ritani are not of
Russian origin. </span></p>

<p style='margin-left:.5in;text-indent:-.25in'><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>(b) Vendor will pull all Russian sourced stones from
Ritani product feeds. </span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>All
representations and warranties of Vendor herein or that are implied by law
shall survive any inspection, delivery, acceptance, and/or payment by Ritani.
Any attempt by Vendor to limit, disclaim, or restrict these representations or
warranties or any remedies of Ritani, by acknowledgment or otherwise, in
accepting or performing under a Purchase Order, shall be null, void, and
ineffective without Ritani's prior written consent. </span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>These Terms
and Conditions shall be deemed to have been entered into in New York and shall
be interpreted and construed in accordance with the laws of the United States
and the State of New York applicable to agreements made and to be performed
entirely within New York. Any action to resolve a dispute arising from the
construction of or to enforce these Terms and Conditions or the Purchase Order shall be
brought in a court of appropriate jurisdiction in New York. Vendor consents and
agrees to the exclusive jurisdiction and venue of the federal and state courts
of New York. </span></p>

<p class=MsoNormal><span style='font-size:11.0pt'>&nbsp;</span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>
</span></p>

<p class=MsoNormal><span style='font-size:11.0pt'>&nbsp;</span></p>

`

const Terms = () => <div>
    <div dangerouslySetInnerHTML={{__html: html}} />
</div>

export default Terms