import React from "react"
import { FormControl, InputLabel, TextField } from "@material-ui/core"
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete"
import { useController, useFormContext } from "react-hook-form"

const filter = createFilterOptions()

const CreateSelect = ({ name, label, options, ...rest }) => {
    const { control } = useFormContext()
    const {
        field: {onChange, onBlur, value, ref},
        formState: {errors}
    } = useController({
        name, control, rules: { required: true },
    })

    const err = errors?.[name]

    return (
        <FormControl>
            {!!label && <InputLabel>{label}</InputLabel>}
            <Autocomplete
                options={options}
                getOptionLabel={(option) => option}
                onChange={(e, data) => onChange(data)}
                onBlur={onBlur}
                value={value}
                renderInput={(params) => <TextField {...params} {...rest} />}
                freeSolo
                multiple
                filterOptions={(options, params) => {
                    const filtered = filter(options, params)
                    if (params.inputValue !== '') {
                        filtered.push(params.inputValue)
                    }
                    return filtered
                }}
            />
            {err && <div style={{color: 'red'}}>{err?.message}</div>}
        </FormControl>
    )
}

export default CreateSelect;