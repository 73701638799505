import React from 'react';

const PageNotFound = () => {
 return (
   <div>
     404 Page not found.
   </div>
 );
}

export default PageNotFound;