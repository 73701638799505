import React, { useState } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import AppToolBar from './AppToolBar';
import AppDrawer from './AppDrawer';

export const drawerWidth = 260;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);



export const DrawerStateContext = React.createContext(null);

const Layout = ({children}) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerContext = {
    mobileOpen,
    handleDrawerToggle
  }

  return (
    <div className={classes.root}>
      <DrawerStateContext.Provider value={drawerContext}>
        <CssBaseline />
        <AppToolBar />
        <AppDrawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </DrawerStateContext.Provider>
    </div>
  );
}

export default Layout;