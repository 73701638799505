import React from 'react'
import * as Yup from 'yup'

import { Grid, MenuItem } from '@material-ui/core'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import Select from '../form/Select'
import FormDialog from '../form/FormDialog'
import AsyncAutoComplete from '../form/AutoComplete'
import InputSkeleton from '../skeletons/InputSkeleton'
import { yupNumber } from '../../utils'

const requiredString = Yup
    .string()
    .required('Required')

const requiredNumber = yupNumber
    .required('Required')

const ReturnsSchema = Yup.object().shape({
    name: requiredString,
        // .matches(/^#\d+/i, 'Sample name #1069'),
    sku: requiredString,
    supplier: Yup.object().shape({
        _id: Yup.string().required('Required')
    }),
    supplier_sku: Yup.string(),
    carat: requiredNumber,
    shape: requiredString,
    color: requiredString,
    cut: requiredString,
    clarity: requiredString,
    supplier_cost: yupNumber,
    certificate_lab: requiredString,
    certificate_number: requiredString,
    length: Yup.string(),
    width: Yup.string(),
    depth: Yup.string(),
    tracking: Yup.string(),
    status: Yup.string(),
    lab_grown: Yup.boolean(),
})

const ReturnsSkeleton = () => (
    <Grid container spacing={1}>
        {[...Array(14)].map((x, i) => <Grid key={i} item xs={6}>
            <InputSkeleton />
        </Grid>)}
    </Grid>
)

export default function Returns({isNew}) {
  return (
    <Grid container spacing={1}>
        <Grid item xs={6}>
            <Input 
                name="name"
                label="Order Name"
            />
        </Grid>
        <Grid item xs={6}>
            <Input 
                name="sku"
                label="SKU"
            />
        </Grid>
        <Grid item xs={6}>
            <AsyncAutoComplete
                name="supplier"
                errorPath="supplier._id"
                label="Vendor"
                routeOptions={{route: 'suppliers', selected: 'name supplier_id', searchField: 'name'}}
                getOptionSelected={(option, value) => {
                    return option.supplier_id === value.supplier_id
                }}
                getOptionLabel={option => option.name || ''}
            />
        </Grid>
        <Grid item xs={6}>
            <Input 
                name="supplier_sku"
                label="Vendor SKU"
            />
        </Grid>
        <Grid item xs={6}>
            <Input 
                name="carat"
                label="Carat"
                type="number"
                inputProps={{step: 0.01}}
            />
        </Grid>
        <Grid item xs={6}>
            <Select 
                name="shape"
                label="Shape"
            >
                <MenuItem value="AS">
                    Asscher
                </MenuItem>
                <MenuItem value="CU">
                    Cushion
                </MenuItem>
                <MenuItem value="EM">
                    Emerald
                </MenuItem>
                <MenuItem value="HS">
                    Heart
                </MenuItem>
                <MenuItem value="MQ">
                    Marquise
                </MenuItem>
                <MenuItem value="OV">
                    Oval
                </MenuItem>
                <MenuItem value="PS">
                    Pear
                </MenuItem>
                <MenuItem value="PR">
                    Princess
                </MenuItem>
                <MenuItem value="RA">
                    Radiant
                </MenuItem>
                <MenuItem value="RD">
                    Round
                </MenuItem>
            </Select>
        </Grid>
        <Grid item xs={6}>
            <Select 
                name="color"
                label="Color"
            >
                {
                    [...Array(9)]
                        .map((_, idx) => String.fromCharCode(idx + 68))
                        .map(x => <MenuItem key={x} value={x}>
                            {x}
                        </MenuItem>)
                }
            </Select>
        </Grid>
        <Grid item xs={6}>
            <Select 
                name="cut"
                label="Cut"
            >
                <MenuItem value="ID">
                    Ideal
                </MenuItem>
                <MenuItem value="E">
                    Excellent
                </MenuItem>
                <MenuItem value="VG">
                    Very Good
                </MenuItem>
                <MenuItem value="G">
                    Good
                </MenuItem>
            </Select>
        </Grid>
        <Grid item xs={6}>
            <Select 
                name="clarity"
                label="Clarity"
            >
                {
                    ['FL', 'IF', 'VVS1', 'VVS2', 'VS1', 'VS2', 'SI1', 'SI2']
                        .map(x => <MenuItem key={x} value={x}>
                            {x}
                        </MenuItem>)
                }
            </Select>
        </Grid>
        <Grid item xs={6}>
            <Input 
                name="length"
                label="Length"
            />
        </Grid>
        <Grid item xs={6}>
            <Input 
                name="width"
                label="Width"
            />
        </Grid>
        <Grid item xs={6}>
            <Input 
                name="depth"
                label="Depth"
            />
        </Grid>
        <Grid item xs={6}>
            <Checkbox 
                name="lab_grown"
                label="Lab grown"
            />
        </Grid>
        <Grid item xs={6}>
            <Input 
                name="supplier_cost"
                label="Vendor cost"
                type="number"
                inputProps={{step: 0.01}}
            />
        </Grid>
        <Grid item xs={6}>
            <Select 
                name="certificate_lab"
                label="Cert Lab"
            >
                {
                    ['GIA', 'IGI', 'HRD', 'GCAL', 'AGS', 'GSI', 'EGL']
                        .map(x => <MenuItem key={x} value={x}>
                            {x}
                        </MenuItem>)
                }
            </Select>
        </Grid>
        <Grid item xs={6}>
            <Input 
                name="certificate_number"
                label="Cert #"
            />
        </Grid>
        {!isNew && <>
            <Grid item xs={6}>
                <Input 
                    name="tracking"
                    label="Tracking"
                />
            </Grid>
            <Grid item xs={6}>
                <Select 
                    name="status"
                    label="Status"
                    select
                >
                    <MenuItem value="cancelled">
                        Cancelled 
                    </MenuItem>
                    <MenuItem value="pending">
                        Pending 
                    </MenuItem>
                    <MenuItem value="accepted">
                        Accepted
                    </MenuItem>
                    <MenuItem value="rejected">
                        Rejected
                    </MenuItem>
                    <MenuItem value="shipped">
                        Shipped
                    </MenuItem>
                    <MenuItem value="ready_to_ship">
                        Ready to Ship
                    </MenuItem>
                    <MenuItem value="return_initiated">
                        Customer Initiated Return
                    </MenuItem>
                    <MenuItem value="stock">
                        Stock
                    </MenuItem>
                </Select>
            </Grid></>
        }
    </Grid>
  )
}

const initialValues = {
    name: '',
    sku: '',
    supplier: {_id: ''},
    supplier_sku: '',
    carat: '',
    shape: '',
    color: '',
    cut: '',
    clarity: '',
    supplier_cost: '',
    certificate_lab: '',
    certificate_number: '',
    tracking: '',
    status: '',
    length: '',
    width: '',
    depth: '',
    lab_grown: ''
}

const preTransformData = data => ({
    ...data,
})

const transformData = data => {
    data.role = data.role?._id || undefined
    data.supplier_id = data.supplier?.supplier_id || undefined
}

export function ReturnsDialog(props) {
  return (
    <FormDialog 
      title="Returns"
      routeOptions={{route: 'returns', populated: 'supplier'}}
      preTransformData={preTransformData}
      transformData={transformData}
      validationSchema={ReturnsSchema}
      Skeleton={ReturnsSkeleton}
      defaultValues={initialValues}
      {...props}
    >
      <Returns isNew={!props.itemId} />
    </FormDialog>
  )
}