import React from 'react'
import Button from '@material-ui/core/Button'
import LabelIcon from '@material-ui/icons/Label'
import { Tooltip, IconButton, DialogContentText, Checkbox } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'
import { toast } from 'react-toastify'

export default function CreateLabel({order_id, reload}) {
  const [open, setOpen] = React.useState(false)
  const [email, setEmail] = React.useState(true)
  const [loading, setLoading] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
    try {
        setLoading(true)
        await axios.post(`/customer-orders/${order_id}/shipping-label`, {email})
        setOpen(false)
        toast.success('Label successfully created')
        reload()
    } catch(err) {
        handleErrors({err})
    } finally {
        setLoading(false)
    }
  }

  return (
    <>
      <Tooltip title="Create Shipping Label">
        <IconButton onClick={handleClickOpen}>
          <LabelIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Create Shipping Label`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button loading={loading} disabled={loading} onClick={handleConfirm} color="secondary">
            Create Shipping Label
          </Button>
        </>}
      >
        <DialogContentText id="alert-dialog-description">
          Email label <Checkbox checked={email} onChange={() => setEmail(x => !x)} />
        </DialogContentText>
      </BasicDialog>
    </>
  );
}