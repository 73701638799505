import React from 'react'

const Holidays = () => {
 return (
    <div>
        <h2>Holiday format</h2>
        <p>The date of the holiday is in the format of <code>Month/Day</code>.</p>
        <p>A day wrapped in parentheses <code>()</code> means a specific day of the week (as opposed to day of the month) and expects two values separated by a comma. The first part is the day of the week (0=Sunday, 6=Saturday). The second part (optional) specifies the week number of the month (1 means the first week of the month, 2 means the second week of the month, etc.). If the second part is wrapped in brackets <code>[]</code> and is a positive number, it means "The weekday on or after this day". If it's a negative number, it means "The weekday on or before this day"</p>
        <p>Two dates separated by a vertical bar <code>|</code> means a date range.</p>
        <p>You may also specific a 4-digit year by adding an additional <code>/</code> after the day.</p>
        <p>Examples:</p>
        <ul>
            <li><code>5/20</code> - The 20th of May.</li>
            <li><code>7/(1,3)</code> - The third Monday of July.</li>
            <li><code>3/(4,-1)</code> - The last Thursday of March.</li>
            <li><code>6/(2,[16])</code> - The Tuesday on or after the 16th of June.</li>
            <li><code>11/(5,[-9])</code> - The Friday on or before the 9th of November.</li>
            <li><code>8/21|9/4</code> - The 21st of August through the 4th of September.</li>
            <li><code>11</code> - The 11th of every month of the year.</li>
            <li><code>(0)</code> - Every Sunday of the year.</li>
            <li><code>(6,-2)</code> - The second to last Friday of every month of the year.</li>
            <li><code>10/(3)</code> - Every Wednesday in October.</li>
            <li><code>12/7/2014</code> - December 7th, 2014.</li>
            <li><code>(6)/2014</code> - Every Saturday of the year 2014.</li>
            <li><code>2/(1,1)|5/(5,-1)</code> - The first Monday of February through the last Friday of May.</li>
            <li><code>4/(3,[-11])|5/(0,1)</code> - The Wednesday on or before the 11th of March through the first Sunday of May.</li>
        </ul>
    </div>
 );
}

export default Holidays;