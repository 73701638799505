import React from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import MaUTable from '@material-ui/core/Table'
import PropTypes from 'prop-types'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from './TablePaginationActions'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableToolbar from './TableToolbar'
import Skeleton from '@material-ui/lab/Skeleton'
import EditIcon from '@material-ui/icons/Edit'
import ClearIcon from '@material-ui/icons/Clear'
import { Tooltip, IconButton, makeStyles, Input, TextField } from '@material-ui/core'
import {debounce} from 'lodash'
import {strEqFilter} from '../../utils'

import history from '../../history'

import {
  usePagination,
  useRowSelect,
  useFilters,
  useSortBy,
  useTable,
  useGlobalFilter
} from 'react-table'
import DeleteDialog from '../form/DeleteDialog'
import { getQueryParams } from '../../utils'
import styled from 'styled-components'

const TableWrapper = styled.div`
  /* max-width: 90vw;
  max-height: 60vh; */
  margin: -24px;
  margin-top: 0;
  overflow: auto;
  th {
    top: 0;
    z-index: 105 !important;
  }
  td:first-child, td:nth-child(2), th {
    position: sticky;
    z-index: 100;
    background: #fafafa;
  }
  th:first-child, td:first-child {
    left: 0;
  }
  th:nth-child(2), td:nth-child(2) {
    left: 82px;
  }
  th:first-child, th:nth-child(2) {
    z-index: 110 !important;
  }
  th:nth-child(2), td:nth-child(2) {
    filter: drop-shadow(1px 0 0px #bbb);
  }
`

const StyledTable = styled(MaUTable)`
    .red {
        background-color: rgba(255,0,0,0.1);
    }
    .orange {
        background-color: rgba(250,180,0,0.2);
    }
    .green {
        background-color: rgba(0,255,0,0.1);
    }
    .cancelled {
        text-decoration: line-through;
    }
    .light-red {
      background-color: rgb(247 8 8 / 36%);
    }
`

const useStyles = makeStyles(() => ({
    actions: {
        display: 'flex',
        minWidth: '100px',
        '& > button': {
            paddingRight: '8px',
        }
    },
    emptyCell: {
        padding: '30px'
    },
    nowrap: {
        whiteSpace: 'nowrap'
    },
    filter: {
        height: '30px'
    }
}))

const mockData = [...Array(5)].map((x, i) => ({id: i}))

const getInitalState = () => {
  const {term, page, pageSize} = getQueryParams()

  return {
    term: term || '',
    pageIndex: +page || 0,
    pageSize: +pageSize || 25,
  }
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    )
  }
)

const DefaultColumnFilter = ({
  column: { setFilter, filter=strEqFilter, placeholder, filterId},
}) => {
  const [val, setVal] = React.useState('')
  const debouncedSetFilter = React.useCallback(debounce(setFilter, 350), [])

  return (
    <Input
      value={val}
      onClick={e => e.stopPropagation()}
      onChange={e => {
        const newValue = e.target.value
        setVal(newValue)
        const val = newValue ? {value: newValue, filter, filterId} : undefined
        debouncedSetFilter(val)
      }}
      placeholder={placeholder}
    />
  )
}

const EnhancedTable = ({
  columns,
  data,
  fetchData,
  loading,
  itemCount,
  pageCount,
  header,
  deleteTitle,
  routeOptions: {route, sorted, filtered, query},
  Dialog,
  selectedActions=[],
  searches,
  getRowProps=x => x,
  actions: {
    canEdit=true,
    canAdd=true,
    canDelete=true,
    showActions=true,
    OtherActions
  }={}
}) => {
  const classes = useStyles()
  const ref = React.useRef()
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )
  const [freezeRow] = React.useState(localStorage.getItem('freeze-row') !== 'false')
  const [freezeColumn] = React.useState(localStorage.getItem('freeze-column') !== 'false')

  const {
    getTableProps,
    setGlobalFilter,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { selectedRowIds, pageIndex, pageSize, sortBy, filters, globalFilter='' }
  } = useTable(
    {
      columns,
      data: loading ? mockData : data,
      defaultColumn,
      initialState: {...getInitalState(), sortBy: sorted || [], filters: filtered || [], fetchData, getFetcher: () => reload},
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      pageCount,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.allColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            !row.original.hideSelect ? <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div> : null
          ),
        },
        ...columns,
        ...(showActions ? [{
          id: 'actions',
          Header: () => 'Actions',
          Cell: ({ row }) => (
            <div className={classes.actions}>
                {!!OtherActions && <OtherActions row={row} onFetchData={reload} />}
                {canEdit && <Tooltip title="Edit">
                    <IconButton aria-label="edit" onClick={() => handleDialogOpen(row.original._id)}>
                    <EditIcon />
                    </IconButton>
                </Tooltip>}
                {canDelete && <DeleteDialog 
                    route={route}
                    title={header}
                    deleteTitle={deleteTitle}
                    id={row.original._id}
                    fetchData={reload}
                />}
            </div>
          ),
        }] : []),
      ])
    }
  )

  const setParams = (params) => {  
    const {pathname, search} = history.location
    const newParams = new URLSearchParams(search)
    for (const key in params)
      newParams.set(key, params[key])
  
    const newUrl = `${pathname}?${newParams}`
    history.replace(newUrl)
  }

  const [count, setCount] = React.useState(0)
  const reload = () => setCount(x => x + 1)

  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy, filters })
  }, [fetchData, pageIndex, pageSize, sortBy, filters, route, query, count])

  React.useEffect(() => {
    if (!freezeColumn && !freezeRow) return

    const resize = () => {
      const el = ref.current
      const {top, left} = el.getBoundingClientRect()
      if (freezeRow) {
        el.style.height = (Math.floor(window.innerHeight - top - 1)) + 'px'
      } else {
        el.style.height = ''
      }
      if (freezeColumn) {
        el.style.width = (Math.floor(window.innerWidth - left - 1)) + 'px'
      } else {
        el.style.width = ''
      }
      // console.log('resized', window.innerWidth, left, 'width', window.innerHeight, top)
    }
    const debouncedResize = debounce(resize, 100)
    window.setTimeout(resize, 100)
    window.addEventListener('resize', debouncedResize)
    return () => {
      window.removeEventListener('resize', debouncedResize)
    }
  }, [freezeRow, freezeColumn])

  const [dialogItemId, setDialogItemId] = React.useState()
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const handleDialogOpen = (id) => {
    setDialogItemId(id)
    setDialogOpen(true)
  }
  const handleDialogClose = () => {
    setDialogItemId()
    setDialogOpen(false)
  }
  const handleDialogSave = () => {
    handleDialogClose()
    reload()
  }

  const handleChangePage = (event, page) => {
    setParams({page})
    gotoPage(page)
  }

  const handleChangeRowsPerPage = event => {
    setParams({pageSize: +event.target.value})
    setPageSize(+event.target.value)
  }
  const selectedRows = data.filter((x, idx) => selectedRowIds[idx] && !x.hideSelect)
  // Render the UI for your table
  return (
    <div>
      {Dialog && <Dialog 
        itemId={dialogItemId}
        open={dialogOpen}
        handleClose={handleDialogClose}
        handleSave={handleDialogSave}
      />}
      <TableToolbar
        numSelected={selectedRows.length}
        tableHeader={header}
        handleDialogOpen={handleDialogOpen}
        canAdd={canAdd}
        selectedActions={selectedActions.map(x => x({selectedRows, fetchData: reload}))}
      />
      <TextField 
        value={globalFilter}
        placeholder="Search"
        style={{margin: '20px'}}
        onChange={e => setGlobalFilter(e.target.value)}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={() => setGlobalFilter('')}
            >
              <ClearIcon />
            </IconButton>
          )
        }}
      />
      {searches}
      <TableWrapper className='table-wrapper' ref={ref}>
        <StyledTable {...getTableProps()} size="small">
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell
                    className={classes.nowrap}
                    {...(column.id === 'selection' || column.id === 'actions'
                      ? column.getHeaderProps()
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                  >
                    {column.render('Header')}
                    {column.id !== 'selection' && column.id !== 'actions' ? (
                      <TableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                    ) : null}
                    <div className={classes.filter}>{column.canFilter ? column.render('Filter') : null}</div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps(getRowProps)}>
                  {row.cells.map(cell => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {loading ? <Skeleton variant="text" animation="wave" /> : cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
            {
              page.length === 0 && <TableRow>
                <TableCell className={classes.emptyCell} align="center" colSpan={100}>No rows found.</TableCell>
              </TableRow>
            }
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  50,
                  100,
                  200
                ]}
                count={itemCount || 0}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </StyledTable>
      </TableWrapper>
    </div>
  )
}

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  deleteTitle: PropTypes.string
}

export default EnhancedTable