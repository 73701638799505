import React from 'react'
import Button from '@material-ui/core/Button'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import { Tooltip, IconButton, TextField, Select, MenuItem } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'
import { toast } from 'react-toastify'

export default function AddDueDate({row: {order_id: id, delivery_by: date, email, name: order_number}, fetchData}) {
  const [open, setOpen] = React.useState(false)
  const [emailType, setEmailType] = React.useState('nothing')
  const [emailAddress, setEmailAddress] = React.useState(email)
  const [emailError, setEmailError] = React.useState(false)
  const [due_date, setDueDate] = React.useState(date)
  const [due_dateError, setDueDateError] = React.useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

    const handleConfirm = async () => {
        try {
            if (!due_date || !/^\d{4}-\d{2}-\d{2}$/.test(due_date)) {
                setDueDateError('Valid date required')
                return
            }
            if (due_date === date) {
              setDueDateError('Date not changed')
              return
            }
            if (emailType !== 'nothing' && !/\S+@\S+\.\S+/.test(emailAddress || '')) {
              setEmailError('Invalid email address')
              return
            }
            await axios.post(`/order-reports/${id}/due-date`, {due_date, email_type: emailType, email: emailAddress, order_number})
            toast.success('Successfully updated')
            setOpen(false)
            fetchData()
        } catch(err) {
            handleErrors({err})
        }
    }

  return (
    <>
      <Tooltip title="Update Due Date">
        <IconButton onClick={handleClickOpen}>
          <CalendarIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Update Due Date for ${order_number}`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="secondary">
            Update Due Date
          </Button>
        </>}
      >
        <TextField 
            type='date'
            value={due_date}
            onChange={e => {
                setDueDate(e.target.value)
                setDueDateError('')
              }
            }
            onFocus={() => setDueDate('')}
            error={!!due_dateError}
            helperText={due_dateError}
        /><br />
        <TextField
            label="Email Address" 
            value={emailAddress}
            onChange={e => {
                setEmailAddress(e.target.value)
              }
            }
            error={!!emailError}
            helperText={emailError}
        />
        <br /><br />
        <span style={{marginRight: '10px'}}>Email: </span>
        <Select
          value={emailType}
          onChange={(e) => setEmailType(e.target.value)}
        >
          <MenuItem value="nothing">No Email</MenuItem>
          <MenuItem value="factory">Factory Delay</MenuItem>
          <MenuItem value="diamond">Diamond Delay</MenuItem>
          <MenuItem value="request">Customer requested Delay</MenuItem>
          <MenuItem value="payment">Payment Delay</MenuItem>
          <MenuItem value="order-change">Order Change Delay</MenuItem>
          <MenuItem value="info">Missing info Delay</MenuItem>
          <MenuItem value="payment-info">Payment info Delay</MenuItem>
          <MenuItem value="repair-received">Repair Received</MenuItem>
        </Select>
      </BasicDialog>
    </>
  );
}