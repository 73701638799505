import { CircularProgress } from '@material-ui/core'
import axios from '../../axios'
import React from 'react'
import { useParams } from 'react-router'
import { handleErrors } from '../../utils'

export default function WebCustomer() {
    const {id} = useParams()

    const [loading, setLoading] = React.useState(true)
    const [data, setData] = React.useState(null)

    React.useEffect(() => {
        const getData = async () => {
            try {
                const res = await axios.get(`/web-customers/${id}`)
                setData(res.data)
            } catch(err) {
                handleErrors({err})
            } finally {
                setLoading(false)
            }
        }
        getData()
    }, [id])

    if (loading) return <CircularProgress style={{position: 'absolute', left: '50%', top: '50%'}} />
    
    return (<pre>
        {JSON.stringify(data, null, 2)}
    </pre>)
}