import React from 'react'
import DefaultTable from '../table/DefaultTable'
import useTitle from '../../utils/useTitle'
import { strEqFilter } from '../../utils'
import { MenuItem, Select } from '@material-ui/core'
import moment from 'moment'
import useColumns from '../hooks/useColumns'
import Backlink from './Backlink'

const statusArr = ['pending', 'requested', 'accepted', 'rejected', 'garbage']

export default function Index() {
  useTitle('Backlinks')

  const {
    columns, reorder
  } = useColumns('backlinks', [
        {
            Header: 'Site',
            accessor: 'site',
            Cell: ({value, row: {original}, initialState: {getFetcher}}) => 
                <div style={{minWidth: '150px'}}>
                    {value} <Backlink id={original._id} reload={getFetcher()} />
                </div>,
        },
        {
            Header: 'Status',
            accessor: 'status',
            Filter: ({ setFilter, state, ...rest }) =>
                <Select onClick={e => e.stopPropagation()} value={state.filters?.find(x => x.id === 'status')?.value?.value || 'all'} onChange={e => setFilter('status', e.target.value !== 'all' ? {value: e.target.value, filter: strEqFilter, filterId: 'status'} : undefined)} style={{width: '100%'}}>
                    <MenuItem key="all" value="all">
                        All
                    </MenuItem>
                    {statusArr.map((val) => <MenuItem key={val} value={val}>
                        {val} 
                    </MenuItem>)}
                </Select>
        },
        {
            Header: 'Referring Link',
            accessor: 'referring_link',
            Cell: ({value}) => <a href={value} target='_blank' rel="noopener noreferrer">{value}</a>,
        },
        {
            Header: 'Referring Text',
            accessor: 'referring_text',
        },
        {
            Header: 'DR',
            accessor: 'dr',
        },
        {
            Header: 'UR',
            accessor: 'ur',
        },
        {
            Header: 'Traffic',
            accessor: 'traffic',
        },
        {
            Header: 'Referring Domains',
            accessor: 'referring_domains',
        },
        {
            Header: 'Linked Domains',
            accessor: 'linked_domains',
        },
        {
            Header: 'Link',
            accessor: 'link',
            Cell: ({value}) => <a href={value} target='_blank' rel="noopener noreferrer">{value}</a>,
        },
        {
            Header: 'Text Link',
            accessor: 'text_link',
        },
        {
            Header: 'Created At',
            accessor: 'created_at',
            Cell: ({value}) => value ? moment(value).format('M/D/YYYY h:mm A') : '',
        }
    ],
  )

  return (
    <DefaultTable 
        columns={columns} 
        routeOptions={{
            route: 'backlinks', 
            selected: 'referring_link referring_text dr ur traffic referring_domains linked_domains link text_link site created_at status',
            filtered: [{
                id: "status",
                value: {
                    value: "pending",
                    filterId: "status",
                    filter: strEqFilter
                }
            }]
        }}
        header={<>Backlink {reorder()}</>}
        actions={{showActions: false}}
    />
  )
}