import React from 'react'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'
import { Tooltip, IconButton } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'
import { toast } from 'react-toastify'

export default function MarkCancelled({selectedRows, fetchData}) {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async (status) => {
        try {
            setLoading(true)
            await axios.post(`/order-reports/mark-cancelled`, {status: 'cancelled', ids: selectedRows.map(x => x.retail_order_id), orderIds: selectedRows.map(x => x.order_id)})
            toast.success('Successfully cancelled')
            fetchData()
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

  return (
    <>
      <Tooltip title={`Mark Cancelled`}>
        <IconButton onClick={handleClickOpen}>
          <CancelIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Mark Cancelled'}`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button loading={loading} disabled={loading} onClick={handleConfirm} color="secondary">
            Mark Cancelled
          </Button>
        </>}
      >
        Are you sure you want to cancel the selected orders?
      </BasicDialog>
    </>
  );
}