import React from 'react'
import Button from '@material-ui/core/Button'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { Tooltip, IconButton, Grid } from '@material-ui/core'
import BasicDialog from '../form/BasicDialog'
import Form from '../form'
import Input from '../form/Input'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { handleErrors } from '../../utils'
import moment from 'moment'
import axios from '../../axios'
import TextArea from '../form/TextArea'

const packingSchema = Yup.object().shape({
  name: Yup
    .string()
        .required('Name is required'),
  address1: Yup.string()
      .required('Address is required'),
  address2: Yup.string(),
  address3: Yup.string(),
  phone: Yup.string(),
  port: Yup.string(),
  date: Yup.string(),
  consignment: Yup.string(),
  section1: Yup.string(),
})

const defaultValues = {
  invoice: '',
  name: '',
  address1: '',
  address2: '',
  address3: '',
  phone: '',
  port: '',
  date: moment().format('MMM DD, YYYY'),
  consignment: 'CONSIGNMENT RETURN',
  section1: `HSN-`
}

export default function DownloadReceived({selectedRows, fetchData}) {
  const rows = selectedRows.map(
    ({_id, lab_grown, shape, color, clarity, certificate_number, certificate_lab, carat, supplier_cost, sku, length, width, depth}) => 
    ({_id, lab_grown, shape, color, clarity, certificate_number, certificate_lab, carat, supplier_cost, sku, length, width, depth})
  )
  const [open, setOpen] = React.useState(false)

  const methods = useForm({ 
    defaultValues: defaultValues, resolver: yupResolver(packingSchema),
  })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    fetchData()
  }

  const handleSubmit = async () => {
      try {
        const data = methods.getValues()
        const {data: {url}} = await axios.post('/returns/invoice', {...data, rows})
        window.open(url, '_blank')
        // setOpen(false)
        // fetchData()
      } catch(err) {
        handleErrors({err})
      }
  }

  return (
    <>
      <Tooltip title="Download Packing Slip">
        <IconButton onClick={handleClickOpen}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Download Packing Slip`}
      >
        <Form
          onSubmit={handleSubmit}
          methods={methods}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Input
                name="invoice"
                label="Invoice"
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name="name"
                label="Name"
              />
            </Grid>
            <Grid item xs={12}>
              <Input 
                name="address1"
                label="Address 1"
                style={{width: '100%'}}
              />
            </Grid>
            <Grid item xs={12}>
              <Input 
                name="address2"
                label="Address 2"
                style={{width: '100%'}}
              />
            </Grid>
            <Grid item xs={12}>
              <Input 
                name="address3"
                label="Address 3"
                style={{width: '100%'}}
              />
            </Grid>
            <Grid item xs={6}>
              <Input 
                name="phone"
                label="Phone"
              />
            </Grid>
            <Grid item xs={6}>
              <Input 
                name="port"
                label="Port"
              />
            </Grid>
            <Grid item xs={6}>
              <Input 
                name="consignment"
                label="Return Type"
              />
            </Grid>
            <Grid item xs={6}>
              <Input 
                name="date"
                label="Date"
              />
            </Grid>
            <Grid item xs={12}>
              <TextArea 
                name="section1"
                label="Section 1"
                multiline
                style={{width: '100%', height: '150px'}}
              />
            </Grid>
          </Grid>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button type="submit" color="secondary">
            Download Packing Slip
          </Button>
        </Form>
      </BasicDialog>
    </>
  );
}