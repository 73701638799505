import React from 'react'
import Button from '@material-ui/core/Button'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import { Tooltip, IconButton, Checkbox } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'
import { toast } from 'react-toastify'

export default function MarkFulfilled({selectedRows, fetchData}) {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [shopify, setShopify] = React.useState(true)

  const handleClickOpen = () => {
    setShopify(true)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async (status) => {
        try {
            setLoading(true)
            if (selectedRows.find(x => !x.tracking?.trim()))
              throw new Error('All orders must have tracking numbers')

            await axios.post(`/order-reports/mark-fulfilled`, {status: 'fulfilled', shopify, rows: selectedRows.map(({retail_order_id, order_id, tracking, order_fulfillment_status, shipping_address, checkoutType}) => ({retail_order_id, order_id, tracking, order_fulfillment_status, shipping_address, checkoutType}))})
            toast.success('Successfully fulfilled')
            fetchData()
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

  return (
    <>
      <Tooltip title={`Mark Fulfilled`}>
        <IconButton onClick={handleClickOpen}>
          <LocalShippingIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Mark Fulfilled`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button loading={loading} disabled={loading} onClick={handleConfirm} color="secondary">
            Mark Fulfilled
          </Button>
        </>}
      >
        Are you sure you want to fulfill the selected orders?<br />
        Fulfill in shopify <Checkbox checked={shopify} onChange={e => setShopify(x => !x)} />
      </BasicDialog>
    </>
  );
}