import React from 'react'
import Button from '@material-ui/core/Button'
import PrintIcon from '@material-ui/icons/Print'
import { Tooltip, IconButton } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'


export default function PrintLabel({selectedRows, fetchData}) {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
        try {
            setLoading(true)
            const ids = selectedRows
              .filter(x => x.tracking)
              .map(x => x.order_id)
            const {data: {urls}} = await axios.post(`/order-reports/print-label`, {ids}, {timeout: 30000})
            for (const url of urls)
              window.open(url, '_blank')
            handleClose()
            // fetchData()
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

  return (
    <>
      <Tooltip title={`Print shipping label`}>
        <IconButton onClick={handleClickOpen}>
          <PrintIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Print shipping label`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button loading={loading} disabled={loading} onClick={handleConfirm} color="secondary">
            Print shipping labels
          </Button>
        </>}
      >
        Print shipping labels for the selected orders?
      </BasicDialog>
    </>
  );
}