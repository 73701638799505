import React from 'react'

import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { Tooltip, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    position: 'fixed',
    top: 0,
    zIndex: 1190
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    paddingRight: '20px'
  },
}))

const TableToolbar = ({canAdd, numSelected, tableHeader, handleDialogOpen, selectedActions=[]}) => {
  const classes = useToolbarStyles()

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? <>
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
        {selectedActions}
      </> : (
        <>
          {canAdd && <Tooltip title="Add">
            <IconButton aria-label="add" onClick={() => handleDialogOpen()}>
              <AddIcon />
            </IconButton>
          </Tooltip>}
          <Typography className={classes.title} variant="h6" id="tableTitle">
            {tableHeader}
          </Typography>
        </>
      )}
    </Toolbar>
  )
}

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  tableHeader: PropTypes.string.isRequired,
}

export default TableToolbar