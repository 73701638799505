import React from 'react'
import DefaultTable from '../table/DefaultTable'
import { RetailerDialog } from './Retailer'
import useTitle from '../../utils/useTitle'

export default function Index() {
  useTitle('Retailers')

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Emails',
        accessor: 'emails',
        Cell: ({value}) => value.map(email => <div>{email}</div>),
      },
    ],
    []
  )

  return (
    <DefaultTable 
      columns={columns} 
      routeOptions={{route: 'retailers', selected: 'name emails'}}
      header='Retailer'
      Dialog={RetailerDialog}
    />
  )
}