import React from 'react'
import * as Yup from 'yup'

import { Grid, MenuItem } from '@material-ui/core'
import Input from '../form/Input'
import FormDialog from '../form/FormDialog'
import InputSkeleton from '../skeletons/InputSkeleton'
import AsyncAutoComplete from '../form/AutoComplete'
import Checkbox from '../form/Checkbox'
import Select from '../form/Select'
import states from '../../utils/states.json'
import countries from '../../utils/countries.json'

const LocationSchema = Yup.object().shape({
    name: Yup
      .string()
        .required('Name is required'),
    retailer: Yup.object().shape({
        _id: Yup
          .string()
          .required('Retailer is required')
    }),
    rep: Yup.object().shape({
        _id: Yup
          .string()
    }),
    active: Yup.boolean(),
    address1: Yup.string()
        .required('Address is required'),
    address2: Yup.string(),
    city: Yup.string().required('City is required'),
    province: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    zip: Yup.string().required('Zipcode is required'),
    phone: Yup.string(),

    fedex_addresses: Yup.array().of(Yup.object().shape({
      first_name: Yup
        .string()
          .required('Name is required'),
      last_name: Yup
        .string()
          .required('Name is required'),
      email: Yup.string().email(),
      delivery: Yup.string().oneOf([
        'Home Address',
        'FedEx Print and Ship',
        'Hand Delivery'
      ]),
      active: Yup.boolean(),
      address1: Yup.string()
          .required('Address is required'),
      address2: Yup.string(),
      city: Yup.string().required('City is required'),
      state_code: Yup.string().required('State is required'),
      country_code: Yup.string().required('Country is required'),
      zip: Yup.string().required('Zipcode is required'),
      phone: Yup.string(),
      location_id: Yup.string()
    }))
})

const LocationSkeleton = () => (
  <Grid container spacing={1}>
    {[...Array(2)].map((x, i) => <Grid key={i} item xs={6}>
      <InputSkeleton />
    </Grid>)}
  </Grid>
)

export default function Location() {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Input 
            name="name"
            label="Name"
          />
        </Grid>
        <Grid item xs={6}>
          <AsyncAutoComplete
            name="retailer"
            errorPath="retailer._id"
            label="Retailer"
            routeOptions={{route: 'retailers', selected: 'name', searchField: 'name'}}
            getOptionSelected={(option, value) => {
              return option._id === value._id
            }}
            getOptionLabel={option => option.name || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox 
            name="active"
            label="Active"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="address1"
            label="Address 1"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="address2"
            label="Address 2"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="city"
            label="City"
          />
        </Grid>
        <Grid item xs={6}>
          <Select 
            name="province"
            label="State"
          >
              {states.map(x => <MenuItem value={x.code}>{x.name}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select 
            name="country"
            label="Country"
          >
              {countries.map(x => <MenuItem value={x.code}>{x.name}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="zip"
            label="Zip"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="phone"
            label="Phone"
          />
        </Grid>
        <Grid item xs={6}>
          <AsyncAutoComplete
            name="rep"
            errorPath="rep._id"
            label="Rep"
            routeOptions={{route: 'reps', selected: 'name', searchField: 'name'}}
            getOptionSelected={(option, value) => {
              return option._id === value._id
            }}
            getOptionLabel={option => option.name || ''}
          />
      </Grid>
      </Grid>
      <h4 style={{marginBottom: 0}}>Fedex Rep Address</h4>
      <Grid container spacing={1}>
      <Grid item xs={6}>
        <Input 
          name="fedex_addresses[0].first_name"
          label="First Name"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="fedex_addresses[0].last_name"
          label="Last Name"
        />
      </Grid>
      <Grid item xs={6}>
        <Checkbox 
          name="fedex_addresses[0].active"
          label="Active"
        />
      </Grid>
      <Grid item xs={6}>
        <Select 
          name="fedex_addresses[0].delivery"
          label="Delivery Type"
        >
          <MenuItem value="Home Address">Home Address</MenuItem>
          <MenuItem value="FedEx Print and Ship">FedEx Print and Ship</MenuItem>
          <MenuItem value="Hand Delivery">Hand Delivery</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="fedex_addresses[0].address1"
          label="Address 1"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="fedex_addresses[0].address2"
          label="Address 2"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="fedex_addresses[0].city"
          label="City"
        />
      </Grid>
      <Grid item xs={6}>
        <Select 
          name="fedex_addresses[0].state_code"
          label="State"
        >
            {states.map(x => <MenuItem value={x.code}>{x.name}</MenuItem>)}
        </Select>
      </Grid>
      <Grid item xs={6}>
        <Select 
          name="fedex_addresses[0].country_code"
          label="Country"
        >
            {countries.map(x => <MenuItem value={x.code}>{x.name}</MenuItem>)}
        </Select>
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="fedex_addresses[0].zip"
          label="Zip"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="fedex_addresses[0].phone"
          label="Phone"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="fedex_addresses[0].email"
          label="Email"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="fedex_addresses[0].location_id"
          label="Location ID"
        />
      </Grid>
      </Grid>
    </>
  )
}

const initialValues = {
    name: '',
    retailer: {_id: ''},
    active: true,
    address1: '',
    address2: '',
    city: '',
    province: '',
    country: 'US',
    zip: '',
    phone: '',
    rep: {_id: ''},

    fedex_addresses: [{
      first_name: '',
      last_name: '',
      active: true,
      delivery: '',
      address1: '',
      address2: '',
      city: '',
      state_code: '',
      country_code: '',
      phone: '',
      email: '',
      location_id: ''
    }]
}

const transformData = data => {
    data.retailer = data.retailer?._id || undefined
    data.rep = data.rep?._id || undefined
    data.country_code = data.country
    data.country_name = countries.find(x => x.code === data.country).name
    data.province_code = data.province
  }

export function LocationDialog(props) {
  return (
    <FormDialog 
      title="Location"
      routeOptions={{route: 'locations', populated: 'retailer rep'}}
      transformData={transformData}
      validationSchema={LocationSchema}
      Skeleton={LocationSkeleton}
      defaultValues={initialValues}
      {...props}
    >
      <Location />
    </FormDialog>
  )
}