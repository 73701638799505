export const shapeHash = {
    'RD': 'Round',
    'PR': 'Princess',
    'CU': 'Cushion',
    'RA': 'Radiant',
    'AS': 'Asscher',
    'EM': 'Emerald',
    'OV': 'Oval',
    'MQ': 'Marquise',
    'PS': 'Pear',
    'HS': 'Heart',
}
export const shapes = Object.keys(shapeHash)
export const shapeNames = Object.values(shapeHash)

export const colors = [...Array(9)].map((_, i) => String.fromCharCode(68 + i))
export const clarities = [
    'FL', 'IF', 'VVS1', 'VVS2', 'VS1', 'VS2', 'SI1', 'SI2'
]

const grades = ['EX', 'VG', 'G']
export const cuts = ['ID', ...grades.slice(1)]
export const polishes = grades
export const symmetries = grades
export const fluorescenceHash = {
    'N': 'None',
    'F': 'Faint',
    'M': 'Medium',
    'S': 'Strong',
    'VS': 'Very Strong'
}
export const fluorescences = Object.keys(fluorescenceHash)
export const fluorescenceNames = Object.values(fluorescenceHash)
export const caratRanges = [
    '0.00_0.22', '0.23_0.29', '0.30_0.34', '0.35_0.39', '0.40_0.44', '0.45_0.49', '0.50_0.54', '0.55_0.59', '0.60_0.69', '0.70_0.74', '0.75_0.79', '0.80_0.89', '0.90_0.99', 
    '1.00_1.04', '1.05_1.09', '1.10_1.19', '1.20_1.29', '1.30_1.49', '1.50_1.69', '1.70_1.99', '2.00_2.49', '2.50_2.99', '3.00_3.49', '3.50_3.99', '4.00_4.49', '4.50_4.99', 
    '5.00_5.49', '5.50_5.99', '6.00_6.49', '6.50_6.99', '7.00_7.49', '7.50_7.99', '8.00_8.49', '8.50_8.99', '9.00_9.49', '9.50_9.99', '10.00_49.99'
]