import React from 'react'
import DefaultTable from '../table/DefaultTable'
import useTitle from '../../utils/useTitle'
import { StoreDialog } from './Store'
import { Link } from 'react-router-dom'

export default function Index() {
  useTitle('Stores')

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({value, row}) => <Link to={`/stores/${row.original.id}`}>{value}</Link>,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Address',
        accessor: 'address',
        Cell: ({row: {original}}) => <div>
            {original.address1} {original.address2}<br />
            {original.city}, {original.state} {original.zip}<br />
            {original.country}
        </div>,
      },
    ],
    []
  )

  return (
    <DefaultTable 
      columns={columns} 
      routeOptions={{route: 'stores'}}
      header='Store'
      Dialog={StoreDialog}
    />
  )
}