import React from 'react'
import { FormProvider } from 'react-hook-form'

const Form = ({ children, onSubmit, methods }) => {
  const { handleSubmit } = methods

  return (
    <FormProvider {...methods} >
      <form onSubmit={handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
} 

export default Form;