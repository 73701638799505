import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { Grid } from '@material-ui/core'
import TextSkeleton from './TestSkeleton'

export default function CheckboxSkeleton({label=true, ...props}) {
  return (
    <Grid container alignItems="center">
      <Grid item>
        <Skeleton variant="rect" width="20px" height="20px" style={{margin: '20px'}} {...props} />
      </Grid>
      {label &&
        <Grid item xs={8}>
          <TextSkeleton />
        </Grid>
      }
    </Grid>
  )
}
