import React from 'react'
import DefaultTable from '../table/DefaultTable'
import useTitle from '../../utils/useTitle'
import { formatDate } from '../../utils'
import AbandonedEmail from './AbandonedEmail'

export default function Index() {
  useTitle('Checkouts')

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'url',
        Cell: ({value, row}) => <a target="_blank" href={value} rel="noopener noreferrer">{row.original._id} - {row.original.is_draft ? 'draft ' : ''}{/order/.test(value) ? 'order' : 'checkout'}</a>
      },
      {
        Header: 'Order',
        accessor: 'number'
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: ({value, row}) => formatDate(value, 'l'),
        disableFilters: true
      },
      {
        Header: 'Updated At',
        accessor: 'updated_at',
        Cell: ({value, row}) => formatDate(value, 'l'),
        disableFilters: true
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Email Status',
        accessor: 'abandoned_email',
        Cell: ({value, row, state}) => <div style={{minWidth: '180px'}}>
          {value?.sent_at ? formatDate(value.sent_at, 'l') : 'Not Sent'}
          {!/order/.test(row.original.url) && <AbandonedEmail state={state} row={row.original} />}
        </div>,
        disableFilters: true
      },
      {
        Header: 'Total',
        accessor: 'total',
      },
    ],
    []
  )

  return (
    <DefaultTable 
      columns={columns} 
      routeOptions={{route: 'checkouts', selected: 'email total url created_at updated_at abandoned_email is_draft number', sorted: [{id: "_id", desc: true}]}}
      actions={{showActions: false}}
      header='Checkout'
    />
  )
}