import React from 'react'
import Button from '@material-ui/core/Button'
import PaymentIcon from '@material-ui/icons/Payment'
import { Tooltip, IconButton, Checkbox } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'
import { toast } from 'react-toastify'
import { sumBy } from 'lodash'

export default function CapturePayment({selectedRows, fetchData}) {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [captureFISP, setCaptureFISP] = React.useState(false)

  const handleClickOpen = () => {
    setCaptureFISP(false)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
    try {
        setLoading(true)

        const excludedNames = []
        const excludedPaid = []

        const rows = selectedRows.filter(x => {
          const sum = sumBy(x.payments, 'amount')
          const total = x.total_price
          const excess = sum - total
          // const isExcess = excess > 0.5
          const excludedFisp = x.checkoutType === 'fisp' && !captureFISP
          const exclude = excludedFisp
          if (exclude)
            excludedNames.push(x.name)
          if (excess < -0.5)
            excludedPaid.push(x.retail_order_id)
          return !exclude
        })

        const names = [...new Set(excludedNames)]
        if (names.length)
          alert(`The following orders will not be captured ${names.join(', ')}`)
        
        // if (overPayments.length) {
        //   throw new Error('Over payments for orders: ' + overPayments.map(x => x.name).join(', '))
        // }
        await axios.post(`/order-reports/capture`, {ids: rows.map(x => x.name), excludedPaid})
        toast.success('Successfully captured')
        fetchData()
    } catch(err) {
        handleErrors({err})
    } finally {
        setLoading(false)
    }
  }

  return (
    <>
      <Tooltip title="Capture Payment">
        <IconButton onClick={handleClickOpen}>
          <PaymentIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Capture Payment`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button loading={loading} disabled={loading} onClick={handleConfirm} color="secondary">
            Capture Payment
          </Button>
        </>}
      >
        Are you sure you want to capture payment for the selected orders?< br/>
        Capture FISP as well <Checkbox checked={captureFISP} onChange={e => setCaptureFISP(x => !x)} />
      </BasicDialog>
    </>
  );
}