import React, { useEffect, useRef, useState } from 'react';
import { formatCurrency, handleErrors } from '../../utils';
import axios from '../../axios';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CircularProgress, Grid, IconButton, OutlinedInput, TextField, Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import Form from '../form';
import Input from '../form/Input';
import RemoveIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ResetIcon from '@material-ui/icons/Replay';
import PropertiesIcon from '@material-ui/icons/ViewList';
import BasicDialog from '../form/BasicDialog';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce, isEmpty, sumBy } from 'lodash';
import FormArray from '../form/FormArray';
import Select from '../form/Select';
import TextArea from '../form/TextArea';
import SimpleDialog from '../form/SimpleDialog';
import CreateSelect from '../form/CreateSelect';

const MiniIcon = ({icon, title, ...rest}) => {
    return <Tooltip title={title}>
        <IconButton style={{padding: '6px'}} {...rest}>
            {React.createElement(icon, {fontSize: 'small'})}
        </IconButton>
    </Tooltip>
}

const discountSchema = Yup.object().shape({
    title: Yup.string(),
    amount: Yup.number().nullable(),
    description: Yup.string(),
    rate: Yup.number().min(0),
    promo_type: Yup.string().nullable(),
})
.test('rateOrAmt', 'Rate or amount is required', function(value) {
    const { amount, rate } = value
    return amount || rate
})

const addressSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    address1: Yup.string().required('Address is required'),
    address2: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    zip: Yup.string().required('Zipcode is required'),
    phone: Yup.string(),
})

const propertySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    value: Yup.string().nullable()//.required('Value is required'),
})

const orderSchema = Yup.object().shape({
    line_items: Yup.array().of(
        Yup.object().shape({
            sku: Yup.string(),
            title: Yup.string(),
            quantity: Yup.number().required('Quantity is required').min(1),
            price: Yup.number(),
            promos: Yup.array().of(discountSchema),
            properties: Yup.array().of(propertySchema),
        })
    ),
    billing_address: addressSchema,
    shipping_address: addressSchema,
    charges: Yup.array().of(discountSchema),
    order_discounts: Yup.array().of(discountSchema),
    payment_adjustments: Yup.array().of(discountSchema),
    tax_lines: Yup.array().of(discountSchema),
    shipping_lines: Yup.array().of(Yup.object().shape({
        title: Yup.string().required('Title is required'),
        price: Yup.number().required('Price is required'),  
    })),
    tags: Yup.array().of(Yup.string()),
    notes: Yup.string(),
    note_attributes: Yup.array().of(propertySchema),
})

const defaultAddress = {
    first_name: '', last_name: '', address1: '', address2: '', city: '', state: '', country: '', zip: '', phone: ''
}

const defaultValues = {
    line_items: [],
    billing_address: defaultAddress,
    shipping_address: defaultAddress,
    charges: [],
    order_discounts: [],
    payment_adjustments: [],
    tax_lines: [],
    shipping_lines: [],
    tags: [],
}

const LineItemWrapper = styled.div`
    padding: 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    gap: 15px;

    .title {
        width: 55%;
    }

    &:last-child {
        border-bottom: none;
    }
`

const DiscountLine = ({fields, field, index, name, unitPrice}) => { 
    const { watch, setValue } = useFormContext()
    const curr = watch(`${name}[${index}]`)
    const { promo_type: promoType, rate, amount } = curr
    const ref = useRef(null)

    useEffect(() => {
        if (promoType === 'Rate') {
            ref.current.value = rate
            updateRate(rate)
        } else {
            ref.current.value = amount
        }
    }, [promoType])

    const sv = (key, value) => setValue(key, value, {
        shouldValidate: true,
    })

    const { total_item_discounted_price, total_before_tax } = useTotals()

    const updateRate = (val) => {
        const pct = +val / 100
        sv(`${name}[${index}].rate`, val)
        sv(`${name}[${index}].amount`, pct * (unitPrice ? unitPrice : (
            name !== 'payment_adjustments' ? total_item_discounted_price : total_before_tax
        )))
    }

    return <div style={{display: 'flex', gap: '10px'}}>
        <Select
            name={`${name}[${index}].title`}
            label="Discount Type"
            options={['Promo', 'Other']}
        />
        <Input name={`${name}[${index}].description`} label="Description" />
        <Select
            name={`${name}[${index}].promo_type`}
            label="Promo Type"
            options={['Amount', 'Rate']}
        />
        <TextField
            defaultValue={field.rate || field.amount}
            label={promoType === 'Rate' ? 'Rate' : 'Amount'}
            type="number"
            min={0}
            inputRef={ref}
            onChange={(e) => {
                if (promoType === 'Rate') {
                    updateRate(e.target.value)
                }
                else {
                    sv(`${name}[${index}].amount`, e.target.value)
                }
            }}
        />
    </div>
}

const DiscountForm = ({name, title, unitPrice}) => {
    const { watch, setValue, trigger } = useFormContext()
    let defaultValue = {title: 'Promo', amount: 0, description: '', rate: 0, promo_type: 'Amount'}
    if (name === 'shipping_lines')
        defaultValue = {title: 'Shipping', price: 0}
    else if (name === 'tax_lines')
        defaultValue = {title: 'Tax', amount: 0, rate: 0}
        
    const arr = watch(name)
    const fieldName = name

    const sv = (key, value) => setValue(key, value, {
        shouldValidate: true,
    })

    return <SimpleDialog
        title={title}
        trigger={<MiniIcon icon={EditIcon} title={title} />}
        onClose={() => {
            trigger(name)
        }}
    >
        <>
            {!arr.length && <Button onClick={() => {
                sv(name, [...arr, defaultValue])
            }} color="primary" autoFocus>   
                Add {title}
            </Button>}
            <FormArray
                name={name}
                render={({fields, field, index, name}) => {
                    if (fieldName === 'tax_lines')
                        return <div>
                            <Input name={`${name}[${index}].title`} label="Title" />
                            <Input name={`${name}[${index}].rate`} label="Rate" />
                        </div>

                    if (fieldName === 'shipping_lines')
                        return <div>
                            <Input name={`${name}[${index}].title`} label="Title" />
                            <Input name={`${name}[${index}].price`} label="Price" />
                        </div>

                    return <DiscountLine unitPrice={unitPrice} fields={fields} field={field} index={index} name={name} />
                }}
                defaultValue={defaultValue}
            />
        </>
    </SimpleDialog>
}

const LineItems = ({ }) => {
    const [open, setOpen] = useState(false)
    const [itemIndex, setItemIndex] = useState(null)
    const [newItem, setNewItem] = useState(null)
    const [acOpen, setAcOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [productType, setProductType] = useState('all')

    const name = 'line_items'
    const { control, formState: {errors}, watch } = useFormContext()
    const { fields, remove, insert } = useFieldArray({
        name, control
    })
  
    // const err = get(errors, name)?.message
    const handleClose = () => {
        setOpen(false)
    }

    const handleConfirm = () => {
        setOpen(false)
        if (newItem)
            insert(itemIndex, {
                ...newItem,
                unit_price: newItem.price,
                unit_discounted_price: newItem.price,
                quantity: 1,
                promos: [],
            })
    }

    const getData = async (term) => {
        setLoading(true)
        try {
            const {data} = await axios.post(`/customer-orders/products`, {
                term, productType
            })

            setOptions(data)
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

    const debouncedGetData = debounce(getData, 100)
  
    return (
        <Card>
            <BasicDialog
                open={open}
                onClose={handleClose}
                title={`Add Line Item`}
                actions={<>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} color="secondary">
                        Add Line Item
                    </Button>
                </>}
            >
                <div style={{width: '500px'}}>
                    <Autocomplete
                        open={acOpen}
                        onOpen={() => setAcOpen(true)}
                        onClose={() => setAcOpen(false)}
                        getOptionSelected={(option, value) => {
                            return option.id === value?.id
                        }}
                        filterOptions={(x) => x}
                        renderOption={(option) => <div style={{display: 'flex', gap: '10px', width: '100%'}}>
                            <img src={option.image?.src} style={{height: '60px'}} />
                            <div>
                                <b>{option.title}</b><br />
                                SKU: {option.sku}<br />
                                {[option.clarity, option.color, option.cut, option.metal].filter(Boolean).join(' | ')}<br />
                                {option.certificate_number ? `${option.certificate_lab} ${option.certificate_number}` : ''}
                            </div>
                            <div>
                                {formatCurrency(option.price)}
                            </div>
                            <div style={{marginLeft: 'auto'}}>
                                <b>{option.product_type}</b>
                            </div>
                        </div>}
                        getOptionLabel={(option) => option.title}
                        options={options}
                        // value={newItem}
                        loading={loading}
                        onChange={(_, data) => {
                            setNewItem(data)
                        }}
                        onInputChange={(event, newInputValue) => {
                            // if (acOpen)
                            debouncedGetData(newInputValue)
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </div>
            </BasicDialog>

            {fields.map((field, index) => {
                const quantityName = `${name}[${index}].quantity`
                const quantity = watch(quantityName)
                const promos = watch(`${name}[${index}].promos`)
                const discountedPrice = field.unit_price - sumBy(promos, x => +x.amount)

                return <LineItemWrapper key={field.id}>
                    <Input variant='outlined' style={{width: '60px'}} name={quantityName} type="number"  />
                    <span>X</span>
                    <div className='title' style={{marginRight: '10px'}}>
                        <div><b>{field.title}</b></div>
                        SKU: {field.sku} 
                        <SimpleDialog
                            title="Properties"
                            trigger={<MiniIcon icon={PropertiesIcon} title="Properties" />}
                        >
                            <div style={{marginBottom: '10px'}}>
                                <FormArray
                                    name={`${name}[${index}].properties`}
                                    render={({fields, field, index, name}) => {
                                        return <div>
                                            <Input name={`${name}[${index}].name`} label="Name" />
                                            <Input name={`${name}[${index}].value`} label="Value" />
                                        </div>
                                    }}
                                    defaultValue={{name: '', value: ''}}
                                />
                            </div>
                        </SimpleDialog>
                    </div>
                    <div>
                        {discountedPrice !== field.unit_price && <div style={{textDecoration: 'line-through'}}>{formatCurrency(field.unit_price)}</div>}
                        {formatCurrency(discountedPrice)}
                        <DiscountForm name={`${name}[${index}].promos`} title="Promos" unitPrice={field.unit_price} />
                    </div>
                    <div>
                        {formatCurrency(discountedPrice * quantity)}
                    </div>
                    <div style={{marginLeft: 'auto'}}>
                        <MiniIcon icon={RemoveIcon} title="Remove" onClick={() => remove(index)} />
                        <MiniIcon icon={AddIcon} title="Add" onClick={() => {
                            setItemIndex(index)
                            setOpen(true)
                        }} />
                    </div>
                </LineItemWrapper>
            })}
        </Card> 
    )
  }

const BasicCardWrapper = styled(Card)`
    padding: 20px;
    margin: 10px 0;
`

const BasicCard = ({title, children, name}) => {
    const { resetField, formState  } = useFormContext()
    const { dirtyFields } = formState
    const isDirty = dirtyFields[name]

    return <BasicCardWrapper>
        <h3>
            {title}
            {isDirty && <MiniIcon icon={ResetIcon} title="Reset" onClick={() => resetField(name)} style={{padding: '3px'}} />}
        </h3>
        {children}
    </BasicCardWrapper>
}

const AddressInputs = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
`

const Address = ({name}) => {
    return <BasicCard name={name} title={name === 'billing_address' ? 'Billing Address' : 'Shipping Address'}>
        <AddressInputs>
            <Input name={`${name}.first_name`} label="First Name" />
            <Input name={`${name}.last_name`} label="Last Name" />
            <Input name={`${name}.address1`} label="Address 1" />
            <Input name={`${name}.address2`} label="Address 2" />
            <Input name={`${name}.city`} label="City" />
            <Input name={`${name}.state`} label="State" />
            <Input name={`${name}.country`} label="Country" />
            <Input name={`${name}.zip`} label="Zip" />
        </AddressInputs>
    </BasicCard>
}

const getTotals = ({
    line_items, charges=[], order_discounts=[], payment_adjustments=[], tax_lines=[], shipping_lines=[], payments=[]
}) => {
    const totalItemPrice = sumBy(line_items, item => {
        const discountedPrice = item.unit_price - sumBy(item.promos, x => +x.amount)
        return discountedPrice * item.quantity
    })
    const totalCharges = sumBy(charges, x => +x.amount)
    const totalOrderDiscounts = sumBy(order_discounts, x => +x.amount)
    const totalPaymentAdjustments = sumBy(payment_adjustments, x => +x.amount)
    const totalBeforeTax = totalItemPrice + totalCharges - totalOrderDiscounts
    const subtotal = totalBeforeTax - totalPaymentAdjustments
    const totalTax = sumBy(tax_lines, x => +x.rate * subtotal / 100)
    const totalShipping = sumBy(shipping_lines, x => +x.price)
    const total = subtotal + totalShipping + totalTax

    return {
        total_item_discounted_price: totalItemPrice,
        total_charges: totalCharges,
        total_order_discounts: totalOrderDiscounts,
        total_payment_adjustments: totalPaymentAdjustments,
        total_tax: totalTax,
        total_shipping: totalShipping,
        total_payments: sumBy(payments, x => +x.amount),
        total,
        total_before_tax: totalBeforeTax
    }
}

const SummaryLineWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
`
const useTotals = () => {
    const {watch} = useFormContext()
    const line_items = watch('line_items')
    const charges = watch('charges')
    const order_discounts = watch('order_discounts')
    const payment_adjustments = watch('payment_adjustments')
    const tax_lines = watch('tax_lines')
    const shipping_lines = watch('shipping_lines')
    const payments = watch('payments')

    return getTotals({
        line_items, charges, order_discounts, payment_adjustments, tax_lines, shipping_lines, payments
    })
}

const SummaryLine = ({title, amount, name}) => {
    return <SummaryLineWrapper>
        <div style={{minWidth: '300px'}}>
            {title}
            {!!name && <DiscountForm name={name} title={title} />}
        </div>
        <div>{formatCurrency(amount)}</div>
    </SummaryLineWrapper>
}

const Summary = () => {
    const {
        total_item_discounted_price,
        total_charges,
        total_order_discounts,
        total_payment_adjustments,
        total_tax,
        total_shipping,
        total
    } = useTotals()

    return <Card style={{marginTop: '10px', padding: '15px'}}>
        <h3>Summary</h3>
        <SummaryLine title="Item Total" amount={total_item_discounted_price} />
        <SummaryLine title="Charges" name="charges" amount={total_charges} />
        <SummaryLine title="Promo Discounts" name="order_discounts" amount={total_order_discounts} />
        <SummaryLine title="Payment Discounts" name="payment_adjustments" amount={total_payment_adjustments} />
        <SummaryLine title="Tax" name="tax_lines" amount={total_tax} />
        <SummaryLine title="Shipping" name="shipping_lines" amount={total_shipping} />
        {/* <SummaryLine title="Payments" amount={total_payments} /> */}
        <SummaryLine title="Total" amount={total} />
    </Card>
}

const getNewName = (data) => {
    const newBaseName = data.version ? String.fromCharCode(data.version.charCodeAt(0) + 1) : 'A'
    const prefix = /#/.test(data.name) ? '#' : ''
    return `${prefix}${data.base_name}${newBaseName}`
}

const Title = ({data}) => {
    const {watch} = useFormContext()
    const line_items = watch('line_items')
    const {total} = useTotals()

    let isNew = true //Math.abs(total - data.total_price) > 0.01 || line_items.length !== data.line_items.length
    if (!isNew) {
        for (const {sku, quantity} of line_items) {
            const item = data.line_items.find(x => x.sku === sku)
            if (!item || item.quantity !== quantity) {
                isNew = true
                break
            }
        }
    }

    const newTitle = ` -> ${getNewName(data)}`
    return <h1>Edit Order {data.name} {isNew ? newTitle : ''}</h1>
}

const EditOrder = () => {
    const {id} = useParams()
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const history = useHistory()

    const methods = useForm({
        defaultValues,
        resolver: yupResolver(orderSchema)
    })

    const handleSubmit = async (values) => {
        try {
            const {data} = await axios.put(`/customer-orders/${id}`, values)
            history.push(`/customer-orders/${data._id}`)
        } catch(err) {  
            handleErrors({err})
        }
    }

    const getOrder = async () => {
        try {
            setLoading(true)
            const {data} = await axios.get(`/customer-orders/${id}?populated=retailer`)
            setData(data)
            data.billing_address = data.billing_address || data.shipping_address
            data.tags = (data.tags || '').split(',').map(x => x.trim())
            const mapDiscount = arr => arr.map(x => ({
                ...x,
                promo_type: x.promo_type || (x.rate ? 'Rate' : 'Amount'),
                rate: x.rate ? x.rate * 100 : 0,
            }))

            data.line_items = data.line_items.map(x => {
                x.promos = mapDiscount(x.promos)
                return x
            });

            ['charges', 'order_discounts', 'payment_adjustments', 'tax_lines'].forEach(x => {
                data[x] = mapDiscount(data[x])
            });

            methods.reset(data)
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getOrder()
    }, [])

    if (loading) return <CircularProgress style={{position: 'absolute', left: '50%', top: '50%'}} />

    if (!data) return null

    return (
        <Form 
            onSubmit={handleSubmit}
            methods={methods}
        >
            <Title 
                data={data}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    {!isEmpty(methods.formState.errors) && <div style={{color: 'red'}}>
                        Please fix the following errors:
                        {JSON.stringify(Object.keys(methods.formState.errors), null, 2)}
                    </div>}
                    <LineItems />
                    <Summary />
                    <Button 
                        style={{marginTop: '10px'}}
                        type="submit" variant="contained" color="primary" 
                        disabled={methods.formState.isSubmitting}
                    >
                        {methods.formState.isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'Save'}
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <BasicCard name="notes" title="Notes">
                                <TextArea
                                    name="notes"
                                    multiline
                                    rows={11}
                                    style={{width: '100%'}}
                                />
                            </BasicCard>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <BasicCard name="note_attributes" title="Attributes">
                                <FormArray
                                    name="note_attributes"
                                    render={({fields, field, index, name}) => {
                                        return <div>
                                            <Input name={`${name}[${index}].name`} label="Name" />
                                            <Input name={`${name}[${index}].value`} label="Value" />
                                        </div>
                                    }}
                                    defaultValue={{name: '', value: ''}}
                                />
                            </BasicCard>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Address name="billing_address" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Address name="shipping_address" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <BasicCard name="tags" title="Tags">
                                <CreateSelect
                                    name="tags"
                                    options={['tag1', 'tag2', 'tag3']}
                                />
                            </BasicCard>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>                
        </Form>
    )
}

export default EditOrder;