import React, { useEffect, useState } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Grid, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import { PowerSettingsNew as PowerSettingsNewIcon, Menu as MenuIcon } from '@material-ui/icons';

import { drawerWidth, DrawerStateContext } from '.';
import {useAuthContext} from '../../auth';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
    createStyles({
        appBar: {
            color: '#9e9e9e',
            backgroundColor: '#fff',
            [theme.breakpoints.up('md')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
    })
);

// const Banner = () => {
//     const [show, setShow] = useState(false)

//     useEffect(() => {
//         const confirmed = localStorage.getItem('confirmTC')
//         if (!confirmed) setShow(true)
//     }, [])
    
//     if (!show) return null

//     return <div style={{position: 'absolute', padding: '5px 20px', top: 0, left: 0, right: 0, background: '#666', color: '#fff', zIndex: 1000}}>
//         Our <Link style={{color: '#fff', textDecoration: 'underline'}} to="/terms-and-conditions">terms and conditions</Link> have recently updated.
//         <div onClick={() => {
//             localStorage.setItem('confirmTC', 'true')
//             setShow(false)
//         }} style={{cursor: 'pointer', float: 'right', padding: '0 30px'}}>Close</div>
//     </div>
// }

export default function AppToolBar() {
  const classes = useStyles();
  const drawer = React.useContext(DrawerStateContext)

  const {logout, user} = useAuthContext()
  const names = user.name.trim().split(/\s+/).slice(0, 1)
  const avatar = names.reduce((a, c) => a + c.slice(0, 1).toUpperCase(), '')

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (f) => {
    if (typeof f === "function")
      f();
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Toolbar>
        {/* {user.is_supplier && <Banner />} */}
        <Grid container alignItems="center">
          <Grid item >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={drawer.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          </Grid>
          <Grid item xs={true}></Grid>
          <Grid item>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {/* <MenuItem>
                <ListItemText primary={user.name} disabled />
              </MenuItem> */}
              <MenuItem onClick={() => handleClose(logout)}>
                <ListItemIcon>
                  <PowerSettingsNewIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </Menu>
            <IconButton
              color="inherit"
              aria-controls="simple-menu" 
              aria-haspopup="true" 
              onClick={handleClick}
            >
              <Avatar>{avatar}</Avatar>
            </IconButton>
          </Grid>
        </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}
