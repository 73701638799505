import React, { useCallback, useEffect, useRef } from 'react';
import { IconButton, TextField } from "@material-ui/core";
import { useState } from "react";
import ClearIcon from '@material-ui/icons/Clear'
import { debounce } from 'lodash';

const CertSearch = ({onChange}) => {
    const [certs, setCerts] = useState('');
    const didMount = useRef(false)
    const debouncedOnChange = useCallback(debounce(onChange, 150), []);

    useEffect(() => {
        if (didMount.current)
            debouncedOnChange(certs);
        else 
            didMount.current = true;
        
    }, [certs])

    return (
        <TextField 
            value={certs}
            placeholder="Certificate Number"
            style={{margin: '20px'}}
            onChange={e => setCerts(e.target.value)}
            InputProps={{
                endAdornment: (
                <IconButton
                    onClick={() => setCerts('')}
                >
                    <ClearIcon />
                </IconButton>
                )
            }}
        />
    )
}

export default CertSearch;