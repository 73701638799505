import React from 'react'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { Tooltip, IconButton, DialogContentText } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from './BasicDialog'

export default function DeleteDialog({title, route, id, fetchData, deleteTitle}) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = async () => {
    try {
      await axios.delete(`/${route}/${id}`)
      setOpen(false)
      fetchData()
    } catch(err) {
      handleErrors({err})
      setOpen(false)
    }
  }

  return (
    <>
      <Tooltip title="Delete">
        <IconButton aria-label="delete" onClick={handleClickOpen}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Delete ${title}`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </>}
      >
        <DialogContentText id="alert-dialog-description">
            {deleteTitle || `Are you sure you want to delete this ${title}?`}
        </DialogContentText>
      </BasicDialog>
    </>
  );
}