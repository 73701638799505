import React from 'react'
import ChipInput from 'material-ui-chip-input'
import { useFormContext, useController } from 'react-hook-form'
 
export default function ChipInputForm({
  name, ...rest
}) {
  const { control } = useFormContext()

  const {
        field: { onChange, value, ref },
        formState: {errors}
    } = useController({
        name,
        control,
        rules: { required: true },
    });

  const err = errors?.[name]
  const errMessage = Array.isArray(err) ? err.filter(x => !!x).map(x => x.message).join(', ') : err?.message

  return (
    <ChipInput
        inputRef={ref}
        name={name}
        error={!!err}
        helperText={errMessage}
        value={value}
        // onAdd={chips => setValue(name, value.concat(chips), true)}
        // onDelete={chips => setValue(name, value.filter(x => x !== chips), true)}
        onAdd={chips => onChange(value.concat(chips))}
        onDelete={chips => onChange(value.filter(x => x !== chips))}
        {...rest}
    />
  )
}
