import React from 'react'
import SortIcon from '@material-ui/icons/Sort'
import Button from '@material-ui/core/Button'
import { Tooltip, IconButton, Checkbox } from '@material-ui/core'
import BasicDialog from '../components/form/BasicDialog'
import styled from 'styled-components'

const Box = styled.div`
    cursor: grab;
    margin: 5px;
    padding: 5px;
    background: #eee;
    border: 1px solid #ccc;
    border-radius: 3px;
`

export default function ReorderColumns ({values=[], updateValues, defaultValues}) {
    const [open, setOpen] = React.useState(false)
    const [order, setOrder] = React.useState(values)
    const dragItem = React.useRef()
    const dragOverItem = React.useRef()

    const dragStart = (position) => {
        dragItem.current = position
    }
     
    const dragEnter = (position) => {
        dragOverItem.current = position
    }

    const drop = () => {
        const newOrder = [...order]
        const dragItemContent = newOrder[dragItem.current]
        newOrder.splice(dragItem.current, 1)
        newOrder.splice(dragOverItem.current, 0, dragItemContent)
        dragItem.current = null
        dragOverItem.current = null
        setOrder(newOrder)
    }

    const updateRow = (idx, row) => {
      const newOrder = [...order]
      newOrder.splice(idx, 1, row)
      setOrder(newOrder)
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleConfirm = () => {
        updateValues(order)
        handleClose()
    }

    return (
        <>
          <Tooltip title="Sort columns">
            <IconButton onClick={handleClickOpen}>
              <SortIcon />
            </IconButton>
          </Tooltip>
          <BasicDialog
            open={open}
            onClose={handleClose}
            title={<div>Sort columns <Button color="primary" onClick={() => setOrder(defaultValues)}>Reset</Button></div>}
            actions={<>
              <Button onClick={handleClose} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={handleConfirm} color="secondary">
                Sort columns
              </Button>
            </>}
          >
            {order.map(({value, hide=false}, idx) => 
              <Box 
                draggable 
                onDragStart={() => dragStart(idx)} 
                onDragEnter={() => dragEnter(idx)} 
                onDragEnd={drop} key={value}
              >
                {value}
                <Checkbox checked={!hide} onClick={e => updateRow(idx, {value, hide: !e.target.checked})} />
              </Box>)}
          </BasicDialog>
        </>
    );
}