import { Dialog, DialogContent, DialogTitle } from "@material-ui/core"
import React from "react"

const SimpleDialog = ({ title, children, trigger, onClose }) => {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        onClose && onClose()
    }

    return (
        <>
            {React.cloneElement(trigger, {onClick: handleClickOpen})}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default SimpleDialog