import React from 'react'
import Button from '@material-ui/core/Button'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { Tooltip, IconButton, TextField } from '@material-ui/core'
import BasicDialog from '../form/BasicDialog'
import moment from 'moment'

export default function DownloadReceived({loading, onConfirm}) {
  const [open, setOpen] = React.useState(false)
  const [date, setDate] = React.useState(moment().format('YYYY-MM-DD'))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
    await onConfirm([], date)
    handleClose()
  }

  return (
    <>
      <Tooltip title="Download Received Orders">
        <IconButton onClick={handleClickOpen}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Download Received Orders`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button loading={loading} onClick={handleConfirm} color="secondary">
            Download Received
          </Button>
        </>}
      >
        <TextField
            type='date'
            value={date}
            onChange={e => {
                setDate(e.target.value)
              }
            }
        />
      </BasicDialog>
    </>
  );
}