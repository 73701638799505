import React from 'react'
import DefaultTable from '../table/DefaultTable'
import useTitle from '../../utils/useTitle'
import { StoreUserDialog } from './StoreUser'
import { useParams } from 'react-router-dom'

export default function Index() {
  useTitle('Users')
  const {id} = useParams()

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
    ],
    []
  )

  return (
    <DefaultTable 
      columns={columns} 
      routeOptions={{route: `stores/${id}/users`}}
      header='User'
      Dialog={StoreUserDialog(`stores/${id}/users`)}
    />
  )
}