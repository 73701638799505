import React from 'react'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useFormContext, useController } from 'react-hook-form'
import 'moment'
import DateFnsUtils from '@date-io/moment'

const DatePicker = ({ name='', ...rest }) => {
    const {control} = useFormContext()

    const {
        field: { onChange, onBlur, value, ref },
        formState: {errors}
    } = useController({
        name,
        control,
        rules: { required: true },
    });

    const err = errors?.[name]

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/DD/YYYY"
                margin="normal"

                ref={ref}
                name={name}
                onChange={onChange} 
                onBlur={onBlur} 
                value={value} 
                error={!!err}
                helperText={err?.message}
                {...rest}
             />
        </MuiPickersUtilsProvider>
    )
}

export default DatePicker;