import React, {useEffect, useState} from 'react'
import moment from 'moment'

import MomentUtils from '@date-io/moment'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, MenuItem } from '@material-ui/core'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { downloadBlob, handleErrors } from '../../utils'
import axios from '../../axios'
import Select from '@material-ui/core/Select'

const Wrapper = styled.div`
    display: flex;
    > * {
        margin-right: 10px;
    }
    margin-top: 10px;
    margin-bottom: 20px;
`

const DatePicker = ({label, value, onChange}) => <KeyboardDatePicker
    disableToolbar
    variant="inline"
    format="M/D/YYYY"
    margin="normal"
    id="date-picker-inline"
    label={label}
    value={value}
    onChange={onChange}
/>

const Reports = () => {
    const [report, setReport] = useState('flash')
    const [startDate, setStartDate] = useState(moment().subtract(1, 'day').startOf('day'))
    const [endDate, setEndDate] = useState(moment().startOf('day'))
    const [loading, setLoading] = useState(false)
    const [vip, setVip] = useState(false)
    const [vs, setVs] = useState(false)
    const [vendorReportUrl, setVendorReportUrl] = useState('')

    const fetchVendorReportUrl = async () => {
        try {
            const {data} = await axios.get('/reports/vendor-feed')
            setVendorReportUrl(data.url)
        } catch(e) {
            handleErrors(e)
        }
    }

    useEffect(() => {
        fetchVendorReportUrl()
    }, [])

    const generateReport = async () => {
        try {
            setLoading(true)
            const {data} = await axios.post('/reports', {report, startDate, endDate, filters: {includeVip: vip, includeVs: vs}}, {
                timeout: 1000 * 60 * 1
            })
            const blob = new Blob([data], { type: 'text/csv' })
            downloadBlob(blob, 'report.csv') 
        } catch(e) {
            handleErrors(e)
        } finally {
            setLoading(false)
        }
    }

    const showFilters = ['flash', 'itemized'].includes(report)

    return <MuiPickersUtilsProvider utils={MomentUtils}>
        <Select
            value={report}
            onChange={e => setReport(e.target.value)}
        >
            <MenuItem value='flash'>Flash Report</MenuItem>
            <MenuItem value='traffic'>Traffic Mix Report</MenuItem>
            <MenuItem value='product'>Product Mix Report</MenuItem>
            <MenuItem value='ordersdue'>Orders Due Report</MenuItem>
            <MenuItem value='itemized'>Itemized Report</MenuItem>
            <MenuItem value='press'>Press Report</MenuItem>
            <MenuItem value='birthday'>Birthday Report</MenuItem>
            <MenuItem value='7-day-unfulfilled'>7 Day Unfulfilled</MenuItem>
            <MenuItem value='diamond-discrepancy'>Diamond Discrepancy</MenuItem>
            <MenuItem value='order-discrepancy'>Order Discrepancy</MenuItem>
        </Select>
        {showFilters && <div>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={vs}
                            onChange={e => setVs(e.target.checked)}
                            color="primary"
                        />
                    }
                    label="Include VS"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={vip}
                            onChange={e => setVip(e.target.checked)}
                            color="primary"
                        />
                    }
                    label="Include VIP"
                />
            </FormGroup>
        </div>}
        <Wrapper>
            <DatePicker
                label={report === 'flash' ? 'Date' : 'End Date'}
                value={endDate}
                onChange={date => setEndDate(date.startOf('day'))}
            />
            {!['flash', 'ordersdue'].includes(report) &&
                <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={date => setStartDate(date.startOf('day'))}
                />
            }
        </Wrapper>
        <Button 
            variant="contained" 
            color="primary" 
            disabled={report !== 'flash' && startDate.isSameOrAfter(endDate)} 
            onClick={generateReport}
            style={{width: "200px"}}
        >
            {loading ? <CircularProgress size={24} color="secondary" /> : 'Generate Report'} 
        </Button>
        <br />
        <br />
        <Button 
            variant="contained" 
            color="primary" 
            disabled={loading || !vendorReportUrl} 
            onClick={() => window.open(vendorReportUrl, '_blank')}
            style={{width: "200px"}}
        >
            {loading ? <CircularProgress size={24} color="secondary" /> : 'Vendor Report'} 
        </Button>
    </MuiPickersUtilsProvider>
}

export default Reports