import React, { useState } from 'react'
import DownloadIcon from '@material-ui/icons/GetApp'
import { Tooltip, IconButton, CircularProgress } from '@material-ui/core'
import { downloadBlob, handleErrors } from '../../utils'
import axios from '../../axios'

export default function DownloadReport() {
    const [loading, setLoading] = useState(false)

    const download = async () => {
        try {
            setLoading(true)
            const {data} = await axios.get(`/item-logs/report`)
            const blob = new Blob([data], { type: 'text/csv' })
            downloadBlob(blob, 'item-log-report.csv') 
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

    return (
        <Tooltip title="Download Logs">
            <IconButton onClick={download}>
                {loading ? <CircularProgress size={20} /> : <DownloadIcon />}
            </IconButton>
        </Tooltip>
    );
}