import React, {useState} from 'react'
import { Button, CircularProgress, Input } from '@material-ui/core'
import { formatCurrency, handleErrors } from '../../utils'
import axios from '../../axios'
import styled from 'styled-components'

const Wrapper = styled.div`
    h3 {
        margin-bottom: 5px;
    }

    .bucket {
        display: flex;
        flex-wrap: wrap;

        div {
            margin-right: 50px;
        }

        .green {color: green}
        .red {color: red}
    }
`

export default function Scrape() {
    const [sku, setSku] = useState('')
    const [loading, setLoading] = useState(false)
    const [bucket, setBucket] = useState()

    const search = async () => {
        if (!sku) return
        
        try {
            setLoading(true)
            const {data} = await axios.get(`/scrape/${sku}`)
            setBucket(data)
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

    const generateColor = prop => {
        const ritani = bucket.ritani[prop]
        const other = bucket.other[prop]
        if (ritani < other) return 'green'
        if (ritani > other) return 'red'
        return ''
    }

    return (
        <Wrapper>
            <Input onChange={e => setSku(e.target.value)} /><Button color="primary" onClick={search}>Search</Button><br />
            {loading && <CircularProgress color="primary" size={40} style={{marginTop: 20}} />}
            {!loading && bucket && <div class="bucket">
                <div>
                    <h3>All</h3>
                    <b>Count</b>: {bucket.count}<br />
                    <b>Min</b>: {formatCurrency(bucket.min)}<br />
                    <b>Min3</b>: {formatCurrency(bucket.min3)}<br />
                    <b>Max</b>: {formatCurrency(bucket.max)}<br />
                    <b>Avg</b>: {formatCurrency(bucket.avg)}<br />
                </div>
                <div>
                    <h3>Ritani</h3>
                    <b>Count</b>: {bucket.ritani.count}<br />
                    <b className={generateColor('min')}>Min</b>: {formatCurrency(bucket.ritani.min)}<br />
                    <b className={generateColor('min3')}>Min3</b>: {formatCurrency(bucket.ritani.min3)}<br />
                    <b className={generateColor('max')}>Max</b>: {formatCurrency(bucket.ritani.max)}<br />
                    <b className={generateColor('avg')}>Avg</b>: {formatCurrency(bucket.ritani.avg)}<br />
                </div>
                <div>
                    <h3>Others</h3>
                    <b>Count</b>: {bucket.other.count}<br />
                    <b>Min</b>: {formatCurrency(bucket.other.min)}<br />
                    <b>Min3</b>: {formatCurrency(bucket.other.min3)}<br />
                    <b>Max</b>: {formatCurrency(bucket.other.max)}<br />
                    <b>Avg</b>: {formatCurrency(bucket.other.avg)}<br />
                </div>
            </div>}
        </Wrapper>
    )
}
