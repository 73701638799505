import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from '../../axios'
import { handleErrors, strEqFilter } from '../../utils'
import { debounce } from 'lodash'
import { TextField } from '@material-ui/core'
import { useController, useFormContext } from "react-hook-form";
import { get } from 'lodash'

export default function AsyncAutoComplete({
  routeOptions, name, errorPath=name,
  label, getOptionSelected, getOptionLabel,
}) {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  const {control} = useFormContext()

  const {
    field: { onChange, onBlur, value, ref },
    formState: {errors}
  } = useController({
    name,
    control,
    rules: { required: true },
  })

  const err = get(errors, errorPath)

  const getData = React.useCallback(async (term) => {
    const {route, selected, searchField} = routeOptions
    setLoading(true)
    try {
      const {data: res} = await axios.post(`/${route}/table`, {
        ...(term && {filter: strEqFilter(searchField, term)}),
        selected
      })

      setOptions(res.data)
    } catch(err) {
      handleErrors({err})
    } finally {
      setLoading(false)
    }
  }, [])

  const debouncedGetData = React.useCallback(debounce(getData, 900), [getData])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    } else {
      getData()
    }
  }, [open])

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={options}
      value={value}
      loading={loading}
      onChange={(_, data) => {
            const newOption = data || {_id: '', name: ''}
            onChange(newOption)
            return newOption
      }}
      onInputChange={(event, newInputValue) => {
        if (open)
          debouncedGetData(newInputValue)
      }}
      renderInput={params => (
        <TextField
            {...params}
            label={label}
            error={!!err}
            helperText={err?.message}
            // onChange={onChange}
            onBlur={onBlur}
            name={name}
            inputRef={ref}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                </React.Fragment>
                ),
            }}
        />
      )}
    />
  )
}
