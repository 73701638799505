import React from 'react'
import Button from '@material-ui/core/Button'
import CurrencyExchangeIcon from '@material-ui/icons/Undo'
import { Tooltip, IconButton, DialogContentText, TextField } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'

export default function RefundTransaction({row, fetchData}) {
  const [open, setOpen] = React.useState(false)
  const [refund, setRefund] = React.useState(false)
  const [refundError, setRefundError] = React.useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
    try {
        if (!refund || refund > row.left_amount) {
            setRefundError(`Refund amount must be greater than 0 and less than ${row.left_amount}`)
            return
        }
      await axios.post(`/payments/${row._id}/refund`, {...row, amount: refund})
      setOpen(false)
      fetchData()
    } catch(err) {
      handleErrors({err})
      // setOpen(false)
    }
  }

  return (
    <>
      <Tooltip title="Refund">
        <IconButton onClick={handleClickOpen}>
          <CurrencyExchangeIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Refund`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="secondary">
            Refund
          </Button>
        </>}
      >
        <DialogContentText id="alert-dialog-description">
            <TextField 
                label="Refund Amount"
                    onChange={e => {
                        setRefund(e.target.value)
                        setRefundError('')
                    }
                }
                 style={{minWidth: '250px'}}
                error={!!refundError}
                helperText={refundError}
            />
        </DialogContentText>
      </BasicDialog>
    </>
  );
}