import history from '../history'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import moment from 'moment'

export const getReturnUrl = () => {
  const returnUrl = history.location.pathname.slice(1)
  const queryParams = returnUrl  ? `?returnUrl=${encodeURIComponent(returnUrl)}` : ''
  return queryParams
}

export const getRedirectUrl = () => {
  const redirectUrl = new URLSearchParams(history.location.search).get('returnUrl')

  if (redirectUrl)
    return `/${redirectUrl}`
  else
    return `/`
}

const dotToArrayPath = path => {
  const re = /\.(\d+)/g;
  return path.replace(re, (match, p1) => `[${p1}]`)
}

export const handleErrors = ({err, setError, time=2500}) => {
  let displayMessage = ''

  const data = err?.response?.data
  if (data) {
    const {errors, message} = data
    if (typeof errors === 'object' && Object.keys(errors).length) {
      const parsedErrors = []

      for (const key in errors) {
        parsedErrors.push({name: dotToArrayPath(key), message: errors[key]})
        displayMessage += errors[key]
      }
      if (setError) {
          for (const {name, message} of parsedErrors)
            setError(name, {type: 'manual', message})
          return
      }
    }

    displayMessage = displayMessage || data.displayMessage || message
  }

  const message = displayMessage || err?.message || 'An error has occured. Please try again.'
  toast.error(message, {autoClose: time})
}

export const getQueryParams = () => {
  const {search} = history.location
  const params = new URLSearchParams(search);
  const result = {}
  for(const [key, value] of params) {
    if (value === 'undefined')
      continue;
    result[key] = value
  }
  return result
}

export const strEqFilter = (id, val) => ({[id]: {$regex: val, $options: 'i'}})
export const numEqFilter = (id, val) => ({[id]: val})
export const NotEqFilter = (id, val) => ({[id]: {$ne: val}})
export const LteFilter = (id, val) => ({[id]: {$lte: val}})
export const NinFilter = (id, val) => ({[id]: {$nin: val}})

export const formatCurrency = (amount=0) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })
    return formatter.format(amount ? amount : Math.abs(amount))
}

export const formatDate = (date, formatType) => {
    let format = 'M/D/YYYY'
    switch(formatType) {
        case 'l':
            format = 'M/D/YYYY h:mm A'
            break;
        default: 
            break;
    }

    if (!date)
        return date

    return moment(date)
        .format(format)
}

export const yupNumber = Yup
    .number()
    .transform((_, val) => val === "" ? undefined : +val)

export const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url;
    a.download = filename || 'download';
    
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        this.removeEventListener('click', clickHandler);
      }, 150);
    };
    
    a.click();
    
    return a;
}

export const objectToFormData = function(obj, form, namespace) {
    const fd = form || new FormData()
    let formKey
    
    for(var property in obj) {
        if(obj.hasOwnProperty(property)) {
            const val = obj[property]

            if(namespace) 
                formKey = namespace + '[' + property + ']'
            else 
                formKey = property
        
            if(typeof val === 'object' && !(val instanceof File))
                objectToFormData(val, fd, formKey)
            else 
                fd.append(formKey, val)
            
        }
    }
    return fd;
  }

export const getLocal = (name, defaultVal) => {
  let val
  try {
    val = localStorage.getItem(name)
    val = JSON.parse(val)
  } catch(e) {
  }
  return val || defaultVal
}

export const setLocal = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value))
}