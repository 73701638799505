import { Button, Grid, Input, MenuItem, Select, InputLabel, FormControl } from '@material-ui/core'
import React, {useState} from 'react'
import axios from '../../axios'
import { handleErrors } from '../../utils'
import styled from 'styled-components'

const GroupHeader = styled.h3`
    margin-bottom: 5px;
    margin-top: 30px;
    text-decoration: underline;
    margin-left: -10px;
`

const listItems = lists => lists.filter(x => x.items.length).map(({key, items}) => <div>
    <h4 style={{marginBottom: '5px', marginTop: '5px'}}>{key}</h4>
    <ul style={{marginTop: '5px'}}>
        {items.map(({title, handle, quantity}) => <li><a href={`https://www.ritani.com/products/${handle}`} target="_blank" rel="noopener noreferrer">{title}</a>({quantity})</li>)}
    </ul>
</div>)

const Customers = () => {
    const [colId, setColId] = useState('')
    const [colType, setColType] = useState('email')
    const [loading, setLoading] = useState(false)
    const [customerInfo, setCustomerInfo] = useState()

    const search = async () => {
        if (!colId || !colType) return
        
        try {
            setLoading(true)
            const {data} = await axios.get(`/customers/${colType}/${colId}`)
            setCustomerInfo(data)
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

    const {customer, cart, builder, wishlist} = customerInfo || {}

    return <div>
        <InputLabel style={{left: '20px'}} id="col-type-label">Type</InputLabel>
        <Select labelId="col-type-label" id="col-type-select" label="Type" value={colType} style={{width: '150px', marginRight: '20px'}} onChange={e => setColType(e.target.value)}>
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="cart">Cart</MenuItem>
            <MenuItem value="builder">Builder</MenuItem>
            <MenuItem value="wishlist">Wishlist</MenuItem>
        </Select>
        <Input style={{width: '200px'}} placeholder={colType === 'email' ? 'Email' : `${colType} ID`} onChange={e => setColId(e.target.value)} />
        <Button color="primary" onClick={search}>Search By {colType === 'email' ? 'Email' : `${colType} ID`}</Button><br />
        {customerInfo && <Grid container spacing={6}>
            {customer && <Grid item md={6}>
                <GroupHeader>Customer</GroupHeader>
                <div><b>Name:</b> {customer.name}</div>
                <div><b>Email:</b> {customer.email}</div>
            </Grid>}
            {cart && <Grid item md={6}>
                <GroupHeader>Carts</GroupHeader>
                {listItems(cart)}
            </Grid>}
            {builder && <Grid item md={6}>
                <GroupHeader>Builders</GroupHeader>
                {listItems(builder)}
            </Grid>}
            {wishlist && <Grid item md={6}>
                <GroupHeader>Wishlists</GroupHeader>
                {listItems(wishlist)}
            </Grid>}
        </Grid>}
    </div>
}

export default Customers