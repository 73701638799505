import React, {useState, useEffect} from 'react'
import Form from './form'
import { Grid, MenuItem, Paper } from '@material-ui/core'
import * as Yup from 'yup'
import Input from './form/Input'
import Button from './form/Button'
import { makeStyles } from '@material-ui/core/styles'
import axios from '../axios'
import { useForm } from 'react-hook-form'
import { handleErrors } from '../utils'
import { useHistory } from 'react-router-dom'
import Select from './form/Select'
import PageLoading from './PageLoading'
import { yupResolver } from '@hookform/resolvers/yup'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(#1b2430, #151C26);',
    height: '100vh'
  },
  logo: {
    textAlign: 'center'
  },
  paper: {
    width: '320px',
    padding: '40px 40px',
    [theme.breakpoints.up('md')]: {
      width: '450px',
      padding: '60px 40px',
    },
  },
  button: {
    width: '100%', marginTop: '20px'
  }
}));

const locations = [
    {name: 'New York', value: 'new york'},
    {name: 'United States', value: 'us'},
    {name: 'United Kingdom', value: 'uk'},
    {name: 'India', value: 'india'},
    {name: 'Belgium', value: 'belgium'},
    {name: 'Israel', value: 'israel'},
    {name: 'Hong Kong', value: 'hong kong'},
]

const SignupSchema = Yup.object().shape({
    username: Yup
        .string()
        .required('Username is required.')
        .matches(
            /^[a-z0-9]{5,16}$/,
            "Username must contain between 5 and 16 lowercase letters and numbers"
        ),
    company_name: Yup
        .string()
        .required('Company name is required.'),
    name: Yup
        .string()
        .required('Name is required.'),
    location: Yup
        .string()
        .oneOf(locations.map(x => x.value))
        .required('Location is required.'),
    email: Yup
        .string()
        .email()
        .required('Email is required.'),
    password: Yup
        .string()
        .required('Password is required.')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
        ),
    confirmPassword: Yup
        .string()
        .oneOf([Yup.ref('password'), null], 'Passwords do not match')
        .required('Please confirm password.')
})

function Signup() {
    const classes = useStyles()

    const history = useHistory()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        window.grecaptcha.ready(() => setLoading(false))
    }, [])

    const methods = useForm({ defaultValue: {}, resolver: yupResolver(SignupSchema) })
    const { setError } = methods

    const onSubmit = async (userInfo={}) => {
        try {
            const token = await window.grecaptcha.execute('6LfyYTwbAAAAAN6z3lkaMgcchcC4EKOhjvfC7Jc-', {action: 'signup/vendor'})
            await axios.post('/users/vendor/signup', {...userInfo, recaptcha: token})
            history.push('/login')
        } catch(err) {
            handleErrors({err, setError})
        } 
    }
   
    if (loading) 
        return <PageLoading />

    return (
        <div className={classes.root}>
        <Form 
            methods={methods} 
            onSubmit={onSubmit}
        >
            <Paper className={classes.paper} elevation={0}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                <h2 className={classes.logo}>Ritani Portal</h2>
                </Grid>
                <Grid item md={6} xs={12}>
                <Input
                    name="name"
                    label="Name"
                />
                </Grid>
                <Grid item md={6} xs={12}>
                <Input
                    name="company_name"
                    label="Company Name"
                />
                </Grid>
                <Grid item md={6} xs={12}>
                <Input
                    name="username"
                    label="Username"
                    autoComplete="username"
                />
                </Grid>
                <Grid item md={6} xs={12}>
                    <Select
                        name="location"
                        label="Location"
                    >
                        {locations.map(x => <MenuItem value={x.value}>{x.name}</MenuItem>)}
                    </Select>
                </Grid>
                <Grid item md={6} xs={12}>
                <Input
                    name="email"
                    label="Email"
                    autoComplete="email"
                />
                </Grid>
                <Grid item md={6} xs={12}>
                <Input
                    name="password"
                    label="Password"
                    type="password"
                    autoComplete="new-password"
                />
                </Grid>
                <Grid item md={6} xs={12}>
                <Input
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    autoComplete="new-password"
                />
                </Grid>
                <Grid item xs={12}>
                <Button className={classes.button} disabled={false} type="submit">Signup</Button>
                </Grid>
            </Grid>
            </Paper>
        </Form>
        </div>
    )
}

export default Signup;