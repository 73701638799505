import React from 'react'
import Button from '@material-ui/core/Button'
import CurrencyExchangeIcon from '@material-ui/icons/Payment'
import { Tooltip, IconButton, DialogContentText, TextField, Checkbox } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'

export default function CaptureTransaction({row, fetchData}) {
  const [open, setOpen] = React.useState(false)
  const [captureError, setCaptureError] = React.useState('')
  const [captureAmt, setCaptureAmt] = React.useState(true)
  const [voidRest, setVoidRest] = React.useState(true)

  const title = row.is_expired ? 'Reauthorize' : 'Capture'

  const handleClickOpen = () => {
    setCaptureAmt(row.left_amount)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
    try {
        if (!captureAmt || captureAmt > row.left_amount) {
            setCaptureError(`${title} amount must be greater than 0 and less than ${row.left_amount}`)
            return
        }
      await axios.post(`/payments/${row._id}/${row.is_expired ? 'reauthorize' : 'capture'}`, {...row, amount: captureAmt, voidRest})
      setOpen(false)
      fetchData()
    } catch(err) {
      handleErrors({err})
      // setOpen(false)
    }
  }

  return (
    <>
      <Tooltip title={title}>
        <IconButton onClick={handleClickOpen}>
          <CurrencyExchangeIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={title}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="secondary">
            {title}
          </Button>
        </>}
      >
        <DialogContentText id="alert-dialog-description">
            <TextField 
                label={`${title} Amount`}
                    onChange={e => {
                        setCaptureAmt(e.target.value)
                        setCaptureError('')
                    }
                }
                 style={{minWidth: '250px'}}
                error={!!captureError}
                helperText={captureError}
                value={captureAmt}
            /><br />
            Last capture
            <Checkbox checked={voidRest} onChange={e => setVoidRest(x => !x)} />
        </DialogContentText>
      </BasicDialog>
    </>
  );
}