import React from 'react'
import DefaultTable from '../table/DefaultTable'
import useTitle from '../../utils/useTitle'
import { useAuthContext } from '../../auth'
import moment from 'moment'
import { formatCurrency, NinFilter, numEqFilter, strEqFilter } from '../../utils'
import { ReturnsDialog } from './Returns'
import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Select } from '@material-ui/core'
import AcceptDiamonds from './AcceptDiamonds'
import DownloadReceived from './DownloadReceived'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ReturnsReport from './ReturnsReport'

const statuses = {
    cancelled: 'Cancelled',
    pending: 'Pending',
    accepted: 'Accepted',
    rejected: 'Rejected',
    ready_to_ship: 'Ready to Ship',
    shipped: 'Shipped',
    stock: 'Stock',
    return_initiated: 'Customer Initiated Return'
}

const statusArr = Object.entries(statuses)

export default function Index() {
  useTitle('Returns')

  const {user} = useAuthContext()
  const {is_supplier} = user

  const filtered = [
    {id: 'status', value: {value: ['cancelled', 'ready_to_ship'], filter: NinFilter}},
  ]

  const sorted = [
      {id: 'supplier_id', desc: false}
  ]

  const columns = React.useMemo(
    () => [
      {
          Header: 'Order',
          accessor: 'name',
      },
      {
          Header: 'Returned On',
          accessor: 'created_at',
          Cell: ({value}) => moment(value).format('M/D/YYYY'),
          disableFilters: true
      },
      {
        Header: 'Days since received',
        accessor: 'days_since_received',
        Cell: ({row}) => row.original.received_at ? moment().diff(moment(row.original.received_at), 'days') : '',
        disableFilters: true
      },
      {
        Header: 'Received On',
        accessor: 'received_at',
        Cell: ({value}) => value ? moment(value).format('M/D/YYYY') : '',
        disableFilters: true
      },
      {
          Header: 'SKU',
          accessor: 'sku',
          Cell: ({value}) => <div style={{whiteSpace: 'nowrap'}}>{value}</div>
      },
      ...(!is_supplier ? [
          {
          Header: 'Vendor',
          accessor: 'supplier.name',
          filter: numEqFilter,
          placeholder: 'vendor ID',
          filterId: 'supplier_id'
          }
      ] : []),
      {
          Header: 'Vendor SKU',
          accessor: 'supplier_sku',
          Cell: ({value}) => <div style={{whiteSpace: 'nowrap'}}>{value}</div>
      },
      {
          Header: 'Carat',
          accessor: 'carat',
          filter: numEqFilter
      },
      {
          Header: 'Shape',
          accessor: 'shape',
      },
      {
          Header: 'Color',
          accessor: 'color',
      },
      {
          Header: 'Cut',
          accessor: 'cut',
      },
      {
          Header: 'Clarity',
          accessor: 'clarity',
      },
      {
          Header: 'Vendor Cost',
          accessor: 'supplier_cost',
          Cell: ({value}) => formatCurrency(value),
          disableFilters: true
      },
      {
          Header: 'Cert Lab',
          accessor: 'certificate_lab',
      },
      {
          Header: 'Cert #',
          accessor: 'certificate_number',
          Cell: ({value, row: {original}}) => {
              return <div>
                  {value}
                  {!!original.invoice_url && <a href={original.invoice_url} target="_blank" rel="noopener noreferrer"><ReceiptIcon /></a>}
              </div>
          }
      },
    //   {
    //       Header: 'Notes',
    //       accessor: 'notes',
    //       Cell: ({value}) => <div>
    //           {value.map(({is_ritani, date, user_name, text}, idx) => <div key={idx} style={{width: 'fit-content', background: is_ritani ? '#aaa' : '#a0a', padding: '10%', borderRadius: '10px'}}>
    //               {text} - {formatDate(date)}
    //           </div>)}
    //       </div>
    //   },
      {
          Header: 'Tracking',
          accessor: 'tracking',
      },
      {
        Header: 'Return Invoice',
        accessor: 'return_invoice_url',
        Cell: ({value}) => !!value && <a href={value} target="_blank" rel="noopener noreferrer"><ReceiptIcon /></a>
    },
      {
          Header: 'Status',
          accessor: 'status',
          Cell: ({value, row: {original}}) => {
              const val = statuses[value] || value
              return <div>
                  {/* {(!is_supplier && original.cert_url) ? <a href={original.cert_url} target="_blank">{val}</a> : val} */}
                  {val}
              </div>
          },
          Filter: ({ setFilter, state, ...rest }) =>
            <Select value={state.filters?.find(x => x.id === 'status')?.value?.value || 'all'} onChange={e => setFilter('status', e.target.value !== 'all' ? {value: e.target.value, filter: strEqFilter, filterId: 'status'} : undefined)} style={{width: '100%'}}>
                <MenuItem key="all" value="all">
                    All 
                </MenuItem>
                {statusArr.map(([key, val]) => <MenuItem key={key} value={key}>
                    {val} 
                </MenuItem>)}
            </Select>
      },
    ],
    []
  )

  return (
    <>
        <Accordion TransitionProps={{ unmountOnExit: true }} style={{maxWidth: '300px'}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                Trailing 12 months report
            </AccordionSummary>
            <AccordionDetails>
                <ReturnsReport />
            </AccordionDetails>
        </Accordion>
        <DefaultTable 
        columns={columns} 
        Dialog={ReturnsDialog}
        actions={{canAdd: !is_supplier, canEdit: !is_supplier, canDelete: false, showActions: true, OtherActions}}
        routeOptions={{
            route: 'returns', filtered, sorted, selected: 'notes name sku supplier_sku carat shape color clarity cut supplier_cost certificate_lab certificate_number tracking status supplier_id created_at received_by_name received_at length width depth lab_grown invoice_url return_invoice_url',
            populated: {path: 'supplier', select: '-_id name'}
        }}
        selectedActions={[
            ({selectedRows, fetchData}) => <div style={{display: 'flex'}}>
                <DownloadReceived selectedRows={selectedRows} fetchData={fetchData} />
            </div>
        ]}
        header="Return"
    />
    </>
  )
}

const OtherActions = ({row, onFetchData}) => {
    const {user} = useAuthContext()
    const {is_supplier} = user

    const {status, _id, notes} = row.original 
    return <>
        {['pending', 'rejected'].includes(status) && <AcceptDiamonds isSupplier={is_supplier} ids={[_id]} notes={notes} fetchData={onFetchData} />}
    </>
}