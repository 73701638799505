import React from 'react'
import * as Yup from 'yup'

import { Grid } from '@material-ui/core'
import Checkbox from '../form/Checkbox'
import Input from '../form/Input'
import FormDialog from '../form/FormDialog'
import InputSkeleton from '../skeletons/InputSkeleton'
import ChipInput from '../form/ChipInput'

const RetailerSchema = Yup.object().shape({
  name: Yup
    .string()
    .required('Name is required'),
  emails: Yup
    .array()
    .of(Yup.string().email())
    .required('Email is required'),
})

const RetailerSkeleton = () => (
  <Grid container spacing={1}>
    {[...Array(2)].map((x, i) => <Grid key={i} item xs={6}>
      <InputSkeleton />
    </Grid>)}
  </Grid>
)

export default function Retailer() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Input 
          name="name"
          label="Name"
        />
      </Grid>
      <Grid item xs={6}>
        <ChipInput
          name="emails"
          label="Email"
        />
      </Grid>
      <Grid item xs={6}>
        <Checkbox
            name="is_store"
            label="Store"
        />
      </Grid>
    </Grid>
  )
}

const initialValues = {
    name: '',
    emails: [],
    is_store: true
}

export function RetailerDialog(props) {
  return (
    <FormDialog 
      title="Retailer"
      routeOptions={{route: 'retailers'}}
      validationSchema={RetailerSchema}
      Skeleton={RetailerSkeleton}
      defaultValues={initialValues}
      {...props}
    >
      <Retailer />
    </FormDialog>
  )
}