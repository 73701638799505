import React from 'react'
import DefaultTable from '../table/DefaultTable'
import { PaymentDialog } from './Payment'
import useTitle from '../../utils/useTitle'
import { numEqFilter } from '../../utils'
import moment from 'moment'
import RefundTransaction from './RefundTransaction'
import CaptureTransaction from './CaptureTransaction'
import VoidTransaction from './VoidTransaction'

const statusArr = ['authorized', 'captured', 'refunded', 'voided']

export default function Index() {
  useTitle('Payments')

  const columns = React.useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Order',
        accessor: 'number',
        Cell: ({value, row}) => row.original.order_url ? <a href={row.original.order_url}>{value}</a> : value || ''
      },
      {
        Header: 'Trans ID',
        accessor: 'trans_id',
      },
      {
        Header: 'Ref ID',
        accessor: 'ref_trans_id',
      },
      {
        Header: 'Original Amount',
        accessor: 'amount',
        filter: numEqFilter
      },
      {
        Header: 'Amount Left',
        accessor: 'left_amount',
        filter: numEqFilter
      },
      {
        Header: 'Type',
        accessor: 'type'
      },
      // {
      //   Header: 'Status',
      //   accessor: 'status',
      //   Filter: ({ setFilter, state, ...rest }) =>
      //       <Select value={state.filters?.find(x => x.id === 'status')?.value?.value || 'all'} onChange={e => setFilter('status', e.target.value !== 'all' ? {value: e.target.value, filter: strEqFilter, filterId: 'status'} : undefined)} style={{width: '100%'}}>
      //           <MenuItem key="all" value="all">
      //               All
      //           </MenuItem>
      //           {statusArr.map((val) => <MenuItem key={val} value={val}>
      //               {val} 
      //           </MenuItem>)}
      //       </Select>
      // },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: ({value}) => value ? moment(value).format('M/D/YYYY h:mm A') : '',
      }
    ],
    []
  )

  return (
    <DefaultTable 
      columns={columns} 
      routeOptions={{route: 'payments', selected: 'email number order_url amount left_amount provider type status is_expired account_type trans_id ref_trans_id created_at'}}
      header='Payment'
      Dialog={PaymentDialog}
      actions={{canAdd: true, canEdit: false, canDelete: false, showActions: true, OtherActions}}
    />
  )
}

const OtherActions = ({row: {original: row}, onFetchData}) => {
  return <>
      {['authorizenet'].includes(row.provider) && row.trans_id && row.type === 'payment' && row.left_amount > 0 && <RefundTransaction row={row} fetchData={onFetchData} />}
      {['authorizenet', 'paypal'].includes(row.provider) && row.trans_id && row.type === 'authorization' && row.left_amount > 0 && <CaptureTransaction row={row} fetchData={onFetchData} />}
      {['authorizenet'].includes(row.provider) && row.trans_id && row.type === 'authorization' && row.left_amount > 0 && <VoidTransaction row={row} fetchData={onFetchData} />}
  </>
}