import React from 'react'
import DefaultTable from '../table/DefaultTable'
import { UserDialog } from './User'
import useTitle from '../../utils/useTitle'
import { numEqFilter } from '../../utils'
import DownloadUserBarcodes from './DownloadUserBarcodes'

export default function Index() {
  useTitle('Users')

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Supplier',
        accessor: 'supplier_id',
        filter: numEqFilter
      },
      {
        Header: 'Role',
        accessor: 'role.name',
        disableFilters: true
      },
    ],
    []
  )

  return (
    <DefaultTable 
      columns={columns} 
      routeOptions={{route: 'users', selected: 'name username supplier_id', populated: {path: 'role', select: 'name'}}}
      header='User'
      Dialog={UserDialog}
      selectedActions={[
        ({selectedRows}) => <div style={{display: 'flex'}}>
            <DownloadUserBarcodes rows={selectedRows} />
        </div>
    ]}
    />
  )
}