import axios from 'axios'

const http = axios.create({
  baseURL: '/api',
  timeout: 10000
})

export const axiosVs = api_key => axios.create({
    baseURL: 'https://api.virtualshelf.io/api',
    headers: {
        api_key
    }
})

export default http;