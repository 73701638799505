import React from 'react'
import Form from '../form'
import { Grid, Paper } from '@material-ui/core'
import * as Yup from 'yup'
import Input from '../form/Input'
import Button from '../form/Button'
import { makeStyles } from '@material-ui/core/styles'
import axios from '../../axios'
import { useForm } from 'react-hook-form'
import { handleErrors } from '../../utils'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useParams } from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(#1b2430, #151C26);',
    height: '100vh'
  },
  logo: {
    textAlign: 'center'
  },
  paper: {
    width: '320px',
    padding: '40px 40px',
    [theme.breakpoints.up('md')]: {
      width: '320px',
      padding: '60px 40px',
    },
  },
  button: {
    width: '100%', marginTop: '20px'
  }
}));

const ResetPasswordSchema = Yup.object().shape({
    password: Yup
        .string()
        .required('Password is required.')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
        ),
    confirmPassword: Yup
        .string()
        .oneOf([Yup.ref('password'), null], 'Passwords do not match')
        .required('Please confirm password.')
})

function ResetPassword() {
    const classes = useStyles()

    const {slug} = useParams()
    const history = useHistory()

    const methods = useForm({ defaultValue: {}, resolver: yupResolver(ResetPasswordSchema) })
    const { setError } = methods

    const onSubmit = async (userInfo={}) => {
        try {
            await axios.post('/users/password-reset', {...userInfo, slug})
            history.push('/login')
        } catch(err) {
            handleErrors({err, setError})
        } 
    }
   
    return (
        <div className={classes.root}>
        <Form 
            methods={methods} 
            onSubmit={onSubmit}
        >
            <Paper className={classes.paper} elevation={0}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                <h2 className={classes.logo}>Ritani Portal</h2>
                </Grid>
                <Grid item xs={12}>
                <Input
                    name="password"
                    label="Password"
                    type="password"
                    autoComplete="new-password"
                />
                </Grid>
                <Grid item xs={12}>
                <Input
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    autoComplete="new-password"
                />
                </Grid>
                <Grid item xs={12}>
                <Button className={classes.button} disabled={false} type="submit">Reset Password</Button>
                </Grid>
            </Grid>
            </Paper>
        </Form>
        </div>
    )
}

export default ResetPassword;