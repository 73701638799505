import React, { useState } from 'react'
import ReceiptIcon from '@material-ui/icons/Receipt'
import LabelIcon from '@material-ui/icons/Label'
import { Tooltip, IconButton, Checkbox, Button } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'

export default function PackingSlip({selectedRows}) {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [reprint, setReprint] = React.useState(false)
  const [save, setSave] = React.useState(true)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
    try {
        setLoading(true)
        const {data} = await axios.post(`/order-reports/packing-slip`, {ids: selectedRows.map(x => x.order_id), reprint, save}, {
          timeout: 30000
      })
        window.open(data, '_blank')
    } catch(err) {
        handleErrors({err})
    } finally {
        setLoading(false)
    }
  }

  return (
    <>
      <Tooltip title={`Print packing slips`}>
        <IconButton onClick={handleClickOpen}>
          <ReceiptIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Print packing slips`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button loading={loading} disabled={loading} onClick={handleConfirm} color="secondary">
            Print packing slips
          </Button>
        </>}
      >
        Print packing slips for the selected orders? <br />
        Save packing slip <Checkbox checked={save} onChange={e => setSave(x => !x)} />
        Reprint packing slips <Checkbox checked={reprint} onChange={e => setReprint(x => !x)} />
      </BasicDialog>
    </>
  );
}