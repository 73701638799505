import React, {useState} from 'react'
import { Button, Card as MCard, CardContent, CircularProgress, Input, Table, TableBody, TableRow, TableCell, TableHead, Grid, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { formatCurrency, formatDate, handleErrors } from '../../utils'
import ExternalIcon from '@material-ui/icons/OpenInNew'
import axios from '../../axios'
import styled from 'styled-components'

const Wrapper = styled.div`
    h3 {
        margin-bottom: 5px;
    }
`

const DiamondContainer = styled.div`
    margin-top: 20px;
    display: flex;
    row-gap: 20px;
    column-gap: 40px;
    flex-wrap: wrap;
`

const DiamondDetail = ({label, value}) => <div>
    <div><b>{label}</b></div>
    <div>{value}</div>
</div>

const Card = ({title, children, ...rest}) => <MCard {...rest}>
    <CardContent>
        <h3 className="card-title">{title}</h3>
        {children}
    </CardContent>
</MCard>

export default function Diamond() {
    const [sku, setSku] = useState('')
    const [loading, setLoading] = useState(false)
    const [diamond, setDiamond] = useState()
    const [history, setHistory] = useState()

    const search = async () => {
        if (!sku) return  
        
        try {
            setLoading(true)
            const {data: {diamond={}, history=[]}} = await axios.get(`/diamonds/${sku}`)
            setDiamond(diamond)
            setHistory(history)
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }


    return (
        <Wrapper>
            <Input onChange={e => setSku(e.target.value)} placeholder="Search for SKU" /><Button color="primary" onClick={search}>Search</Button><br />
            {loading && <CircularProgress color="primary" size={40} style={{marginTop: 20}} />}
            {!loading && <Grid container>
                <Grid item md={6}>
                    {!!diamond && <Card title={<span>
                            <b>SKU: </b> 
                            <a style={{display: 'inline-flex'}} href={`https://www.ritani.com/products/sku-${diamond.sku}`} target="_blank" rel="noopener noreferrer">{diamond.sku} <ExternalIcon /></a>
                        </span>}>
                        <DiamondContainer>
                            <DiamondDetail label="Cert" value={`${diamond.certificate_lab} ${diamond.certificate_number}`} />
                            <DiamondDetail label="Type" value={diamond.lab_grown ? 'Lab Grown': 'Natural'} />
                            <DiamondDetail label="Carat" value={diamond.carat} />
                            <DiamondDetail label="Color" value={diamond.color} />
                            <DiamondDetail label="Clarity" value={diamond.clarity} />
                            <DiamondDetail label="Cut" value={diamond.cut} />
                            <DiamondDetail label="Shape" value={diamond.shape} />
                            <DiamondDetail label="Fluorescence" value={diamond.fluorescence} />
                            <DiamondDetail label="Symmetry" value={diamond.symmetry} />
                            <DiamondDetail label="Polish" value={diamond.polish} />
                            <DiamondDetail label="Price" value={formatCurrency(diamond.retail_price)} />
                            <DiamondDetail label="Cost" value={formatCurrency(diamond.supplier_cost)} />
                            <DiamondDetail label="Vendor" value={diamond.supplier_name} />
                        </DiamondContainer>
                    </Card>}
                    {!!diamond && <Accordion>
                        <AccordionSummary>
                            <h3>All Diamond Info</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Card>
                                {Object.entries(diamond)
                                    .filter(([key, value]) => value !== null && value !== undefined && value !== '')
                                    .map(([key, value]) => {
                                        const isLink = value?.includes?.('http')
                                        const val = typeof value === 'object' ? JSON.stringify(value) : value
                                        const valContainer = isLink ? <a href={value} target="_blank" rel="noopener noreferrer">{value.slice(0, 200)}</a> : <span>{val}</span>
                                        return <div key={key}>
                                            <b>{key}:</b> {valContainer}
                                        </div>
                                    })
                                }
                            </Card>    
                        </AccordionDetails>
                    </Accordion>}
                </Grid>
                <Grid item md={1}></Grid>
                <Grid item md={5}>
                    {!!history?.length && <Card>
                        <Table>
                            <TableHead>
                                <TableCell>Date</TableCell>
                                <TableCell>Cost</TableCell>
                                <TableCell>Price</TableCell>
                            </TableHead>
                            <TableBody>
                                {history.map(({supplier_cost, retail_price, priced_at}, idx) => <TableRow key={idx}>
                                    <TableCell>{formatDate(priced_at, 'l')}</TableCell>
                                    <TableCell>{formatCurrency(supplier_cost)}</TableCell>
                                    <TableCell>{formatCurrency(retail_price)}</TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </Card>}
                </Grid>
            </Grid>}
        </Wrapper>
    )
}
