import React from 'react'
import DefaultTable from '../table/DefaultTable'
import useTitle from '../../utils/useTitle'
import moment from 'moment'
import  ScannerIcon from '@material-ui/icons/Scanner';
import { Link } from 'react-router-dom';
import DownloadReport from './DownloadReport';

export default function Index() {
  useTitle('Item Logs')

  const columns = React.useMemo(
    () => [
      {
        Header: 'Sku',
        accessor: 'sku',
      },
      {
        Header: 'Barcode',
        accessor: 'barcode',
      },
      {
        Header: 'Order Number',
        accessor: 'order_number',
      },
      {
        Header: 'Certificate Number',
        accessor: 'certificate_number',
      },
      {
        Header: 'Lot ID',
        accessor: 'lot_id',
      },
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'Notes',
        accessor: 'notes',
      },
      {
        Header: 'Seen At',
        accessor: 'created_at',
        Cell: ({value}) => moment(value).format('MM/DD/YYYY hh:mm A')
      },
      {
        Header: 'Seen By',
        accessor: 'user.name',
      },
    ],
    []
  )

  return (
    <DefaultTable 
      columns={columns} 
      routeOptions={{route: 'item-logs', selected: 'sku tracking_number order_number certificate_number notes created_at user barcode lot_id', populated: {path: 'user', select: 'name'}}}
      header={<div style={{display: 'flex', alignItems: 'center'}}>Item Logs <Link style={{marginLeft: '10px'}} to='/item-logs/scanner'>
        <ScannerIcon />
      </Link>
      <DownloadReport />
      </div>}
      actions={{showActions: false}}
    />
  )
}