import React from 'react'
import * as Yup from 'yup'

import { Grid, MenuItem } from '@material-ui/core'
import Input from '../form/Input'
import FormDialog from '../form/FormDialog'
import InputSkeleton from '../skeletons/InputSkeleton'
import Select from '../form/Select'

const WarrantySchema = Yup.object().shape({
    first_name: Yup
        .string()
        .required('First Name is required'),
    last_name: Yup
        .string()
        .required('Last Name is required'),
    email: Yup
        .string()
        .required('Email is required'),
    birth_date: Yup
        .string()
        .required('Birth Date is required'),
    // customer_id: Yup
    //     .string()
    //     .required('Customer Id is required'),
    number: Yup
        .string()
        .required('Confirmation Number is required'),
    serial_number: Yup
        .string()
        .required('Serial Number is required'),
})

const WarrantySkeleton = () => (
  <Grid container spacing={1}>
    {[...Array(10)].map((x, i) => <Grid key={i} item xs={6}>
      <InputSkeleton />
    </Grid>)}
  </Grid>
)

export default function Warranty() {
  return (
    <Grid container spacing={1}>
      {/* <Grid item xs={6}>
        <Input 
          name="customer_id"
          label="ID"
        />
      </Grid> */}
      <Grid item xs={6}>
        <Input 
          name="first_name"
          label="First Name"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="last_name"
          label="Last Name"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="birth_date"
          label="Birth Date"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="email"
          label="Email"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="number"
          label="Confirmation Number"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="serial_number"
          label="Serial Number"
        />
      </Grid>
      <Grid item xs={6}>
      <Select 
            name="status"
            label="Status"
        >
            <MenuItem value="pending">
                Pending
            </MenuItem>
            <MenuItem value="verified">
                Verified
            </MenuItem>
        </Select>
      </Grid>
    </Grid>
  )
}

const initialValues = {
    first_name: '',
    last_name: '',
    status: 'pending',
    birth_date: '',
    wedding_date: '',
    email: '',
    number: '',
    serial_number: '',
    recipient: {
        first_name: '',
        last_name: '',
        email: '',
        birth_date: ''
    }
}

export function WarrantyDialog(props) {
  return (
    <FormDialog 
      title="Warranty"
      routeOptions={{route: 'warranties'}}
      validationSchema={WarrantySchema}
      Skeleton={WarrantySkeleton}
      defaultValues={initialValues}
      {...props}
    >
      <Warranty />
    </FormDialog>
  )
}