import React from 'react'
import Button from '@material-ui/core/Button'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import BalanceIcon from '@material-ui/icons/AccountBalance'
import { Tooltip, IconButton } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'
import { toast } from 'react-toastify'

export default function MarkPaid({selectedRows, fetchData, markPaid}) {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
        try {
            setLoading(true)
            await axios.post(`/order-reports/mark-paid`, {status: markPaid, ids: selectedRows.map(x => x.retail_order_id), orderIds: selectedRows.map(x => x.order_id)})
            toast.success('Successfully captured')
            fetchData()
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

  return (
    <>
      <Tooltip title={`Mark ${markPaid ? 'Paid' : 'Not Paid'}`}>
        <IconButton onClick={handleClickOpen}>
          {markPaid ? <AttachMoneyIcon /> : <BalanceIcon />}
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Mark ${markPaid ? 'Paid' : 'Not Paid'}`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button loading={loading} disabled={loading} onClick={handleConfirm} color="secondary">
            Mark {markPaid ? 'Paid' : 'Not Paid'}
          </Button>
        </>}
      >
        Are you sure you want to mark the selected orders as {markPaid ? '' : 'not '}paid?
      </BasicDialog>
    </>
  );
}