import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../auth'
import useTitle from '../utils/useTitle'
import Select from './form/Select'
import Input from './form/Input'
import Form from './form';
import { useForm, useFormContext } from 'react-hook-form';
import * as Yup from 'yup'
import Button from './form/Button';
import FormArray from './form/FormArray';
import styled from 'styled-components';
import { Checkbox, TextField } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup'
import axios from '../axios';
import {round} from 'lodash'
import { handleErrors, objectToFormData } from '../utils';
import FileUpload from './form/FileUpload';
import { Link } from 'react-router-dom'

const MarkupRow = ({markupType, field, fields, index, name}) => {
    const isPriceMarkup = markupType === 'price'
    const {watch} = useFormContext()
    const nextFrom = watch(`${name}[${index + 1}].from`)
    watch(`${name}[${index - 1}].from`)
    const isLast = index === fields.length - 1
    const defaultBound = isPriceMarkup ? 100000000 : 100
    const decrease = isPriceMarkup ? 1 : 0.01
    const toBound = isLast ? defaultBound : +nextFrom - decrease
    // const step = isPriceMarkup ? "1000" : "0.1"
    const max = isPriceMarkup ? "100000000" : "100"

    return <div>
        <Input min="0" max={max} disabled={index === 0} type="number" label="From" name={`${name}[${index}].from`} />
        <TextField label="To" value={toBound} disabled />
        <Input min="0" max="1000" type="number" step="0.01" label="Markup (%)" name={`${name}[${index}].pct`} />
    </div>
}

const Wrapper = styled.div`
    > div {
        margin: 1.5em 0;
    }
`

const degrees = [
    {key: '', value: ''},
    {key: 'L', value: 'Light'}, 
    {key: 'I', value: 'Intense'},
    {key: 'V', value: 'Vivid'},
    {key: 'P', value: 'Purplish'},
    {key: 'D', value: 'Deep'}
]
const fancyColors = [
    {key: 'Y', value: 'Yellow'},
    {key: 'O', value: 'Orange'},
    {key: 'R', value: 'Red'},
    {key: 'BLU', value: 'Blue'},
    {key: 'BLA', value: 'Black'},
    {key: 'BRO', value: 'Brown'},
    {key: 'PI', value: 'Pink'},
    {key: 'PU', value: 'Purple'},
    {key: 'GRE', value: 'Green'},
    {key: 'GRA', value: 'Gray'},
    {key: 'W', value: 'White'}
]
const colors = [...Array(9)]
    .map((x, i) => String.fromCharCode(68 + i))
    .concat(degrees.map(x => {
        return fancyColors.map(y => ({key: `F${x.key}${y.key}`, value: `Fancy ${x.value} ${y.value}`}))
    }).flat())

const clarities = ['FL', 'IF', 'VVS1', 'VVS2', 'VS1', 'VS2', 'SI1', 'SI2']

const cuts = [{key: 'ID', value: 'Ideal'}, {key: 'VG', value: 'Very Good'}, {key: 'G', value: 'Good'}]

const types = [{key: 'false', value: 'Earth'}, {key: 'true', value: 'Lab'}]

const fluorescences = [{key: 'N', value: 'None'}, {key: 'F', value: 'Faint'}, {key: 'M', value: 'Medium'}, {key: 'S', value: 'Strong'}, {key: 'VS', value: 'Very Strong'},] 

const labs = ['GIA','IGI','GCAL','DF','AGSL','HRD']

const arrKeys = arr => arr.map(x => x?.key || x)

const yupArray = arr => Yup.array().of(
    Yup.string().oneOf(arrKeys(arr))
)
.min(1, 'At least one option must be selected')

const yupMarkups = (isPrice) => {
    const max = isPrice ? 100000000 : 100
    
    return Yup
        .array()  
        .of(
            Yup.object().shape({
                from: Yup.number()
                    .required().min(0)
                    .test(
                        'from',
                        `Max is ${max}`,
                        (value) => value <= max
                    ),
                pct: Yup.number().required().min(5, 'minimum markup is 5 percent').max(1000, 'maximum markup is 1000 percent'),
            })
        )
        .min(1, 'At least one markup is required')
        .max(20, 'Cannot have more than 20 markups')
        .test(
            'markup',
            'Invalid markups',
            (value) => {
                const minBoundDiff = isPrice ? 1 : 0.01
                const bounds = value.map(x => x.from)
                return !bounds.some((x, idx) => {
                    const prev = bounds[idx - 1]
                    if (idx === 0 && x !== 0) return true
        
                    if ((x - prev) < minBoundDiff) return true
                })
            }
        )
}

const schema = Yup.lazy(values => {
    const isPrice = values.markup_type === 'price'
    const newMarkups = yupMarkups(isPrice)

    return Yup.object().shape({
        carat_min: Yup.number().min(0).max(100),
        carat_max: Yup.number().min(0).max(99.99),
        color: yupArray(colors),
        clarity: yupArray(clarities),
        cut: yupArray(cuts),
        fluorescence: yupArray(fluorescences),
        type: yupArray(types),
        lab: yupArray(labs),
        logo: Yup.string(),
        earth_markups: newMarkups,
        lab_markups: newMarkups,
        markup_type: Yup.string().oneOf(['price', 'carat']),
        file: Yup.mixed()
            .test('fileSize', 'File too large. Max size 2 MB', (files) => {
                const file = files[0]
                return files.length === 0 || file.size <= 2 * 1024 * 1024
            })
            .test(
                'fileFormat',
                'Unsupported file type. Only jpeg, jpg and png are allowed',
                (files) => {
                    const file = files[0]
                    return files.length === 0 || ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)
                }
            )
    })
})

const defaultValues = {
    carat_min: 0,
    carat_max: 99,
    color: arrKeys(colors),
    clarity: arrKeys(clarities),
    cut: arrKeys(cuts),
    fluorescence: arrKeys(fluorescences),
    type: arrKeys(types),
    lab: arrKeys(labs),
    earth_markups: [{from: 0, pct: 7}],
    lab_markups: [{from: 0, pct: 7}],
    markup_type: 'price',
    logo: ''
}

const MarkupArray = ({name}) => {
    const {watch, setValue} = useFormContext()
    const markupType = watch('markup_type')

    useEffect(() => {
        setValue('earth_markups', [{from: 0, pct: 7}])
        setValue('lab_markups', [{from: 0, pct: 7}])
    }, [markupType])

    const insertMarkup = (fields, index, insert) => {
        const prevVal = fields[index].from
        const increase = markupType === 'price' ? 1000 : 0.5
        insert(index+1, {from: +prevVal + increase, pct: 7})
    }

    return <FormArray 
        name={name}
        onInsert={insertMarkup}
        onDelete={(fields, index, remove) => index !== 0 && remove(index)}
        render={(props) => <MarkupRow markupType={markupType} {...props} />}
    />
}

export default function Account() {
    useTitle('Account')
    const [isSeparateMarkups, setIsSeparateMarkups] = useState(true)

    const {user: {
        api_key, api_password, username, company_name, 
        email, is_vs_retailer,
        is_supplier
    }} = useAuthContext()

    const methods = useForm({ 
        defaultValues: defaultValues, resolver: 
        yupResolver(schema),
    })

    const typeSelection = methods.watch('type')
    const logo = methods.watch('logo')

    const handleSubmit = async (info) => {
        try {
            const {earth_markups, lab_markups} = info
            const mapMarkups = ({from, pct}) => ([from, round(pct/100, 3)])
            const mappedEarthMarkups = earth_markups.map(mapMarkups)
            const mappedLabMarkups = isSeparateMarkups ? lab_markups.map(mapMarkups) : mappedEarthMarkups

            info.earth_markups = mappedEarthMarkups
            info.lab_markups = mappedLabMarkups
            info.file = info.file[0]

            const fd = objectToFormData(info)
            
            const {data} = await axios.put('/vs-retailers', fd, {timeout: 60000})
            if (data.logo) {
                methods.setValue('logo', data.logo)
            }
        } catch(err) {
            console.log(err,'err')
            handleErrors({err})
        }
    }

    const getData = async () => {
        try {
            const {data} = await axios.get('/vs-retailers')
            const mapMarkups = ([from, pct]) => ({from, pct: round(pct*100, 3)})
            data.earth_markups = data.earth_markups.map(mapMarkups)
            data.lab_markups = data.lab_markups.map(mapMarkups)
            setIsSeparateMarkups(data.type.length < 2 || JSON.stringify(data.earth_markups) !== JSON.stringify(data.lab_markups))
            data.type = data.type.map(x => x.toString())
            // console.log(data, 'data')
            methods.reset(data)
        } catch(e) {

        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            <h3>Account Info</h3>
            <b>Username:</b> {username}<br />
            <b>Email:</b> {email}<br />
            {is_supplier && <Link style={{display: 'block', marginTop: '20px'}} to="/terms-and-conditions">Term and Conditions</Link>}
            {is_vs_retailer && <><b>Sales Portal:</b> <a target="_blank" rel="noopener noreferrer"  href={`https://www.virtualshelf.io`}>Log in to your Sales Portal</a></>}
            {/* {is_vs_retailer && <><b>Sales Portal:</b> <a target="_blank"  href={`https://${vs_retailer_short_name}.virtualshelf.io`}>Log in to your Sales Portal</a></>} */}
            {company_name && <><b>Company Name:</b> {company_name}<br /></>}
            <br />

            {api_key && <div>
                <h3>FTP Credentials</h3>
                <b>Username:</b> {username}<br />
                <b>Password:</b> {api_password}<br />  
                <b>API Key:</b> {api_key}<br />    
            </div>}

            {is_vs_retailer && 
            <Form
                onSubmit={handleSubmit}
                methods={methods}
            >
                <Wrapper>
                    {logo && <div>
                        <h4>Current Logo</h4>
                        <img style={{maxHeight: '100px'}} src={logo} />    
                    </div>}
                    <FileUpload label="Upload Store Logo" name="file" />   
                    <div>
                        <Input type="number" name="carat_min" label="Minimun Carat" variant="outlined" style={{marginRight: '20px'}} />
                        <Input type="number" name="carat_max" label="Maximum Carat" variant="outlined" />
                    </div>
                    <div>
                        <Select 
                            label="Color"
                            name="color"
                            multiple 
                            options={colors} 
                        />
                    </div>
                    <div>
                        <Select 
                            label="Clarity"
                            name="clarity"
                            multiple 
                            options={clarities} 
                        />
                    </div>
                    <div>
                        <Select 
                            label="Cut"
                            name="cut"
                            multiple 
                            options={cuts} 
                        />
                    </div>
                    <div>
                        <Select 
                            label="Diamond Type"
                            name="type"
                            multiple 
                            options={types} 
                        />
                    </div>
                    <div>
                        <Select 
                            label="Fluorescence"
                            name="fluorescence"
                            multiple 
                            options={fluorescences} 
                        />
                    </div>
                    <div>
                        <Select 
                            label="Certificate Lab"
                            name="lab"
                            multiple 
                            options={labs} 
                        />
                    </div>
                    <div>
                        <Select 
                            label="Markup Type"
                            name="markup_type"
                            options={['price', 'carat']}
                        />
                    </div>
                    {typeSelection.length > 1 && <div>
                         Same markups for lab <Checkbox checked={!isSeparateMarkups} onChange={e => setIsSeparateMarkups(!e.target.checked)} />
                    </div>}
                    {typeSelection.includes('false') && <div>
                        {(isSeparateMarkups || typeSelection.length === 1) ? 'Earth Markups' : 'Earth And Lab Markups'}
                        <MarkupArray
                            name="earth_markups"
                        />
                    </div>}
                    {typeSelection.includes('true') && (isSeparateMarkups || typeSelection.length === 1) && <div>
                        Lab Markups
                        <MarkupArray
                            name="lab_markups"
                        />
                    </div>}
                    <Button type="submit">Update</Button>
                </Wrapper>
            </Form>}
        </div>
    )
}
