import { CircularProgress, IconButton, MenuItem, Select, TextField, Tooltip } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react'
import BasicDialog from '../form/BasicDialog'
import EmailIcon from '@material-ui/icons/Email';
import axios from '../../axios'
import { handleErrors } from '../../utils'
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import SendEmail from './SendEmail';

const statusArr = ['pending', 'requested', 'accepted', 'rejected', 'garbage']

const CustomSelect = ({options, label, value, setValue}) => {
    return (
        <Autocomplete
            freeSolo
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            inputValue={value}
            onInputChange={(event, newInputValue) => {
                setValue(newInputValue);
            }}
            options={options}
            renderInput={(params) => (
                <TextField {...params} label={label} variant="outlined" />
            )}
        />
    );
}

const Backlink = ({id, reload}) => {
    const [open, setOpen] = useState(false)
    const [, setLoading] = useState(false)
    const [backlink, setBacklink] = useState()
    const [contactEmail, setContactEmail] = useState()
    const [recipient, setRecipient] = useState()
    const [altLink, setAltLink] = useState()
    const [emailBody, setEmailBody] = useState()
    const [senderName, setSenderName] = useState('')
    const [senderEmail, setSenderEmail] = useState('')

    const getBacklink = async () => {
        try {
            setLoading(true)
            const {data} = await axios.get(`/backlinks/${id}`)
            setContactEmail(data.contacts[0]?.email)
            setRecipient(data.contacts[0]?.name)
            setAltLink(data.altLinks[0])
            setBacklink(data)
            const sender = data.senders[0]
            setSenderName(sender.name)
            setSenderEmail(sender.email)
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!backlink) return

        const newEmailBody = `Dear ${recipient},

I hope this message finds you well. My name is ${senderName}, and I recently visited your website, particularly the page titled "${backlink.referring_text}." I found the content to be very insightful and beneficial, which is why I wanted to bring to your attention a small issue that I encountered.

It appears that there is a broken link on this page. The link in question is supposed to lead to ${backlink.link}, but unfortunately, it seems to be non-functional at the moment. Broken links can sometimes lead to a less optimal user experience and may affect your site's SEO performance.

To assist with this, I'd like to suggest a replacement link that provides similar, if not more comprehensive, information on the topic: ${altLink}. I believe this link could serve as an excellent alternative to the current one and would enhance the value of your page for readers like myself.

Here are the details of the broken link for your convenience:

- Page URL: ${backlink.referring_link}
- Anchor Text: ${backlink.text_pre_link} ${backlink.text_link} ${backlink.text_post_link}
- Current Broken URL: ${backlink.link}

And here is the replacement link that I recommend:

- Suggested Replacement URL: ${altLink}
- Source/Website: Ritani

I hope you find this information helpful. Please feel free to reach out if you need any further details or assistance. I'd be more than happy to help.

Thank you for your time and for providing such valuable content on your site. I look forward to revisiting the page once the link has been updated.
Warm regards,

${senderName}
`
        
    setEmailBody(newEmailBody)
    }, [backlink, contactEmail, altLink, recipient, senderName])

    const copyText = () => {
        navigator.clipboard.writeText(emailBody)
    }

    const updateStatus = async (status) => {
        try {
            setLoading(true)
            await axios.put(`/backlinks/${id}`, {status})
            setBacklink(x => ({...x, status}))
            reload()
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

    const handleClickOpen = () => {
        getBacklink()
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    // const openEmailClient = () => {
    //     window.open(`mailto:${contactEmail}?subject=Broken Link Found on ${backlink.referring_text} - Suggested Replacement&body=${emailBody.replace(/\n/g, '%0D%0A')}`)
    // }

    return (
        <>
            <Tooltip title="Email">
                <IconButton onClick={handleClickOpen}>
                    <EmailIcon />
                </IconButton>
            </Tooltip>
            <BasicDialog
                open={open}
                onClose={handleClose}
                title={`Backlink`}
            >
                {!backlink ? <CircularProgress size={24} color="secondary" /> : 
                    <div style={{minWidth: '500px', minHeight: '300px'}}>
                        <br />
                        <div>
                            Status: 
                            <Select style={{marginLeft: '10px'}} value={backlink.status} onChange={e => updateStatus(e.target.value)}>
                                {statusArr.map((val) => <MenuItem key={val} value={val}>
                                    {val}
                                </MenuItem>)}
                            </Select>
                        </div><br />
                        <CustomSelect label="Recipient Email" value={contactEmail} setValue={e => {
                            setContactEmail(e)
                            const recipient = backlink.contacts.find(x => x.email === e)?.name
                            if (recipient)
                                setRecipient(recipient)
                        }} options={backlink.contacts.map(x => x.email)} /><br />
                        <CustomSelect label="Recipient Name" value={recipient} setValue={setRecipient} options={backlink.contacts.map(x => x.name)} />
                        <br />
                        <div>
                            <CustomSelect label="Suggested Link" value={altLink} setValue={setAltLink} options={backlink.altLinks} />
                        </div><br />
                        <div>
                            <Select
                                onChange={e => {
                                    const sender = backlink.senders.find(x => x.email === e.target.value)
                                    setSenderName(sender.name)
                                    setSenderEmail(sender.email)
                                }}
                                value={senderEmail}
                            >
                                {backlink.senders.map(x => <MenuItem key={x.email} value={x.email}>
                                    {x.email} ({x.count})
                                </MenuItem>)}
                            </Select>
                        </div>
                        <div>
                            Email: <span>
                            <SendEmail 
                                to={{
                                    name: recipient,
                                    email: contactEmail
                                }} 
                                from={{
                                    name: senderName,
                                    email: senderEmail
                                }}
                                altLink={altLink}
                                body={emailBody} 
                                backlink={backlink}
                                id={id}
                                reload={reload}
                            />
                            <Tooltip title="Copy">
                                <IconButton onClick={copyText}>
                                    <CopyIcon />
                                </IconButton>
                            </Tooltip>
                            </span><br />
                            <textarea style={{
                                minWidth: '100%',
                                minHeight: '300px'
                            }} value={emailBody} onChange={e => setEmailBody(e.target.value)} />
                        </div>
                    </div>
                }
            </BasicDialog>
        </>
      )
}

export default Backlink