import React from 'react'
import Button from '@material-ui/core/Button'
import CurrencyExchangeIcon from '@material-ui/icons/MoneyOff'
import { Tooltip, IconButton, DialogContentText } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'

export default function VoidTransaction({row, fetchData}) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
    try {
      await axios.post(`/payments/${row._id}/void`, row)
      setOpen(false)
      fetchData()
    } catch(err) {
      handleErrors({err})
      // setOpen(false)
    }
  }

  return (
    <>
      <Tooltip title="Void Transaction">
        <IconButton onClick={handleClickOpen}>
          <CurrencyExchangeIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Void Transaction`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="secondary">
            Void Transaction
          </Button>
        </>}
      >
        <DialogContentText id="alert-dialog-description">
            Are you sure you want to void this transaction?
        </DialogContentText>
      </BasicDialog>
    </>
  );
}