import React from 'react'
import * as Yup from 'yup'

import { Grid, MenuItem } from '@material-ui/core'
import Input from '../form/Input'
import DatePicker from '../form/DatePicker'
import Select from '../form/Select'
import FormDialog from '../form/FormDialog'
import AsyncAutoComplete from '../form/AutoComplete'
import InputSkeleton from '../skeletons/InputSkeleton'
import { yupNumber } from '../../utils'
import moment from 'moment'

const requiredString = Yup
    .string()
    .required('Required')

const requiredNumber = yupNumber
    .required('Required')

const OrderSchema = Yup.object().shape({
    name: requiredString,
        // .matches(/^#\d+/i, 'Sample name #1069'),
    sku: requiredString,
    supplier: Yup.object().shape({
        _id: Yup.string().required('Required')
    }),
    supplier_sku: Yup.string(),
    carat: requiredNumber,
    shape: requiredString,
    color: requiredString,
    cut: requiredString,
    clarity: requiredString,
    supplier_cost: yupNumber,
    certificate_lab: requiredString,
    certificate_number: requiredString,
    supplier_tracking: Yup.string(),
    fulfillment_status: Yup.string(),
    delivery_by: Yup.string()
})

const OrderSkeleton = () => (
    <Grid container spacing={1}>
        {[...Array(14)].map((x, i) => <Grid key={i} item xs={6}>
            <InputSkeleton />
        </Grid>)}
    </Grid>
)

export default function Order({isNew}) {
  return (
    <Grid container spacing={1}>
        <Grid item xs={6}>
            <Input 
                name="name"
                label="Name"
            />
        </Grid>
        <Grid item xs={6}>
            <Input 
                name="sku"
                label="SKU"
            />
        </Grid>
        <Grid item xs={6}>
            <AsyncAutoComplete
                name="supplier"
                errorPath="supplier._id"
                label="Vendor"
                routeOptions={{route: 'suppliers', selected: 'name supplier_id', searchField: 'name'}}
                getOptionSelected={(option, value) => {
                    return option.supplier_id === value.supplier_id
                }}
                getOptionLabel={option => option.name || ''}
            />
        </Grid>
        <Grid item xs={6}>
            <Input 
                name="supplier_sku"
                label="Vendor SKU"
            />
        </Grid>
        <Grid item xs={6}>
            <Input 
                name="carat"
                label="Carat"
                type="number"
                inputProps={{step: 0.01}}
            />
        </Grid>
        <Grid item xs={6}>
            <Select 
                name="shape"
                label="Shape"
            >
                <MenuItem value="AS">
                    Asscher
                </MenuItem>
                <MenuItem value="CU">
                    Cushion
                </MenuItem>
                <MenuItem value="EM">
                    Emerald
                </MenuItem>
                <MenuItem value="HS">
                    Heart
                </MenuItem>
                <MenuItem value="MQ">
                    Marquise
                </MenuItem>
                <MenuItem value="OV">
                    Oval
                </MenuItem>
                <MenuItem value="PS">
                    Pear
                </MenuItem>
                <MenuItem value="PR">
                    Princess
                </MenuItem>
                <MenuItem value="RA">
                    Radiant
                </MenuItem>
                <MenuItem value="RD">
                    Round
                </MenuItem>
            </Select>
        </Grid>
        <Grid item xs={6}>
            <Select 
                name="color"
                label="Color"
            >
                {
                    [...Array(9)]
                        .map((_, idx) => String.fromCharCode(idx + 68))
                        .map(x => <MenuItem key={x} value={x}>
                            {x}
                        </MenuItem>)
                }
            </Select>
        </Grid>
        <Grid item xs={6}>
            <Select 
                name="cut"
                label="Cut"
            >
                <MenuItem value="ID">
                    Ideal
                </MenuItem>
                <MenuItem value="E">
                    Excellent
                </MenuItem>
                <MenuItem value="VG">
                    Very Good
                </MenuItem>
                <MenuItem value="G">
                    Good
                </MenuItem>
            </Select>
        </Grid>
        <Grid item xs={6}>
            <Select 
                name="clarity"
                label="Clarity"
            >
                {
                    ['FL', 'IF', 'VVS1', 'VVS2', 'VS1', 'VS2', 'SI1', 'SI2']
                        .map(x => <MenuItem key={x} value={x}>
                            {x}
                        </MenuItem>)
                }
            </Select>
        </Grid>
        <Grid item xs={6}>
            <Input 
                name="supplier_cost"
                label="Vendor cost"
                type="number"
                inputProps={{step: 0.01}}
            />
        </Grid>
        <Grid item xs={6}>
            <Select 
                name="certificate_lab"
                label="Cert Lab"
            >
                {
                    ['GIA', 'IGI', 'HRD', 'GCAL', 'AGS', 'GSI', 'EGL']
                        .map(x => <MenuItem key={x} value={x}>
                            {x}
                        </MenuItem>)
                }
            </Select>
        </Grid>
        <Grid item xs={6}>
            <Input 
                name="certificate_number"
                label="Cert #"
            />
        </Grid>
        <Grid item xs={6}>
            <DatePicker 
                name="delivery_by"
                label="Delivery By"
            />
        </Grid>
        {!isNew && <>
            <Grid item xs={6}>
                <Input 
                    name="supplier_tracking"
                    label="Tracking"
                />
            </Grid>
            <Grid item xs={6}>
                <Select 
                    name="fulfillment_status"
                    label="Status"
                    select
                >
                    <MenuItem value="pending">
                        Pending
                    </MenuItem>
                    <MenuItem value="on_hold">
                        On hold
                    </MenuItem>
                    <MenuItem value="unavailable">
                        Unavailable
                    </MenuItem>
                    <MenuItem value="ready_to_ship">
                        Ready to ship
                    </MenuItem>
                    <MenuItem value="shipped">
                        Shipped
                    </MenuItem>
                    <MenuItem value="received">
                        Received
                    </MenuItem>
                    <MenuItem value="cancelled">
                        Cancelled
                    </MenuItem>
                </Select>
            </Grid></>
        }
    </Grid>
  )
}

const initialValues = {
    name: '',
    sku: '',
    supplier: {_id: ''},
    supplier_sku: '',
    carat: '',
    shape: '',
    color: '',
    cut: '',
    clarity: '',
    supplier_cost: '',
    certificate_lab: '',
    certificate_number: '',
    supplier_tracking: '',
    fulfillment_status: '',
    verification_status: '',
    delivery_by: ''
}

const preTransformData = data => ({
    ...data,
    delivery_by: data.delivery_by ? moment(data.delivery_by).utcOffset(240).format('YYYY-MM-DD') : ''
})

const transformData = data => {
    data.role = data.role?._id || undefined
    data.supplier_id = data.supplier?.supplier_id || undefined
}

export function OrderDialog(props) {
  return (
    <FormDialog 
      title="Order"
      routeOptions={{route: 'orders', populated: 'supplier'}}
      preTransformData={preTransformData}
      transformData={transformData}
      validationSchema={OrderSchema}
      Skeleton={OrderSkeleton}
      defaultValues={initialValues}
      {...props}
    >
      <Order isNew={!props.itemId} />
    </FormDialog>
  )
}