import React from 'react'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import { Tooltip, IconButton, TextField } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'
import { toast } from 'react-toastify'

export default function EditTracking({row: {order_id: id, tracking: tn, name: order_number}, fetchData}) {
  const [open, setOpen] = React.useState(false)
  const [tracking, setTracking] = React.useState(tn)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

    const handleConfirm = async () => {
        try {
            await axios.post(`/order-reports/${id}/tracking`, {tracking, order_number})
            toast.success('Successfully updated')
            setOpen(false)
            fetchData()
        } catch(err) {
            handleErrors({err})
        }
    }

  return (
    <>
      <Tooltip title="Update Tracking">
        <IconButton onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Update Tracking for ${order_number}`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="secondary">
            Update Tracking
          </Button>
        </>}
      >
        <TextField
            label="Tracking Number" 
            value={tracking}
            onChange={e => {
                setTracking(e.target.value)
              }
            }
        />
      </BasicDialog>
    </>
  );
}