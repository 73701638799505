import React, { useState } from 'react'
import * as Yup from 'yup'

import { Grid, MenuItem } from '@material-ui/core'
import Input from '../form/Input'
import FormDialog from '../form/FormDialog'
import InputSkeleton from '../skeletons/InputSkeleton'
import Select from '../form/Select'
import states from '../../utils/states.json'
import countries from '../../utils/countries.json'
import { useFormContext } from 'react-hook-form'
import { handleErrors } from '../../utils'
import axios from '../../axios'

const PaymentSchema = Yup.object().shape({
    // name: Yup
    //   .string()
    //   .when('payment_type', (payment_type, schema) => payment_type === 'cc' ? schema.required('Name is required') : schema),
    amount: Yup.number().positive().required('Amount is required'),
    payment_type: Yup.string().oneOf(['cc', 'profile']).required('Payment type is required'),
    type: Yup.string().oneOf(['authorization', 'payment']).required('Charge type is required'),
    card_number: Yup.string().when('payment_type', (payment_type, schema) => payment_type === 'cc' ? schema.required('Card number is required') : schema),
    card_cvv: Yup.string().when('payment_type', (payment_type, schema) => payment_type === 'cc' ? schema.required('Card cvv is required') : schema),
    expiration_date: Yup.string().when('payment_type', (payment_type, schema) => payment_type === 'cc' ? schema.required('Expiration date is required') : schema),
    customer_profile_id: Yup.string().when('payment_type', (payment_type, schema) => payment_type === 'profile' ? schema.required('Payment profile is required') : schema),
    customer_payment_profile_id: Yup.string().when('payment_type', (payment_type, schema) => payment_type === 'profile' ? schema.required('Payment profile is required') : schema),
    customer_profile_email: Yup.string()
      .when('payment_type', (payment_type, schema) => 
        payment_type === 'profile' ? schema
          .oneOf([Yup.ref('email'), null], 'Email must match profile email')
          .required('Payment profile is required') 
          : schema),
    email: Yup.string().email().required('Email is required'),
    number: Yup.string().required('Order is required'),
    provider: Yup.string().oneOf(['authorizenet']),

    billing_address: Yup.lazy((_, {parent}) => {
      const paymentType = parent.payment_type;
      if (paymentType === 'cc') {
        return Yup.object().shape({
          first_name: Yup
            .string()
            .required('Name is required'),
          last_name: Yup
            .string()
            .required('Name is required'),
          address1: Yup.string()
          .required('Address is required'),
          address2: Yup.string(),
          city: Yup.string().required('City is required'),
          state: Yup.string().required('State is required'),
          country: Yup.string().required('Country is required'),
          zip: Yup.string().required('Zipcode is required'),
        })
      } else {
        return Yup.object().shape({});
      }
    }),
  })

const PaymentSkeleton = () => (
  <Grid container spacing={1}>
    {[...Array(2)].map((x, i) => <Grid key={i} item xs={6}>
      <InputSkeleton />
    </Grid>)}
  </Grid>
)

export default function Payment() {
  const { watch, setValue } = useFormContext()
  const paymentType = watch('payment_type')
  const email = watch('email')
  const [profiles, setProfiles] = useState([])

  const getProfiles = async () => {
    if (!email) return 
    try {
      const {data} = await axios.get(`/payments/profiles/${email}`)
      setProfiles(data)
    } catch(err) {
      handleErrors({err})
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Input 
            name="email"
            label="Email"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="number"
            label="Order Number"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="amount"
            label="Amount"
          />
        </Grid>
        <Grid item xs={6}>
          <Select 
            name="provider"
            label="Provider"
          >
              <MenuItem value="authorizenet">Authorize.net</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select 
            name="payment_type"
            label="Payment Type"
          >
              <MenuItem value="cc">CC</MenuItem>
              <MenuItem value="profile">Payment profile</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select 
            name="type"
            label="Charge Type"
          >
              <MenuItem value="authorization">Authorization</MenuItem>
              <MenuItem value="payment">Capture</MenuItem>
          </Select>
        </Grid>
        {paymentType === 'cc' && <>
          <Grid item xs={6}>
            <Input 
              name="name"
              label="Card Name"
            />
          </Grid>
          <Grid item xs={6}>
            <Input 
              name="card_number"
              label="Card Number"
            />
          </Grid>
          <Grid item xs={6}>
            <Input 
              name="card_cvv"
              label="CVV"
            />
          </Grid>
          <Grid item xs={6}>
            <Input 
              name="expiration_date"
              label="Expiration"
            />
          </Grid>
        </>}
        {paymentType === 'profile' && <>
          <Grid item xs={12}>
            <Select onOpen={getProfiles} onChange={e => {
              const profile = profiles.find(({customerProfileId}) => customerProfileId === e.target.value)
              setValue('customer_profile_email', profile.email)
              setValue('customer_profile_id', profile.customerProfileId)
              setValue('customer_payment_profile_id', profile.customerPaymentProfileId)
            }} name="customer_profile_id" label="Customer Profile">
              {profiles.map(({customerProfileId, email, cardNumber, cardType}) => <MenuItem value={customerProfileId}>{`${cardNumber} (${cardType}) - ${email}`}</MenuItem>)}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Input type="hidden" name="customer_profile_email" />
          </Grid>
        </>}
        
      </Grid>
      {paymentType === 'cc' && <>
        <h4 style={{marginBottom: 0}}>Billing Address</h4>
        <Grid container spacing={1}>
        <Grid item xs={6}>
          <Input 
            name="billing_address.first_name"
            label="First Name"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="billing_address.last_name"
            label="Last Name"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="billing_address.address1"
            label="Address 1"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="billing_address.address2"
            label="Address 2"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="billing_address.city"
            label="City"
          />
        </Grid>
        <Grid item xs={6}>
          <Select 
            name="billing_address.state"
            label="State"
          >
              {states.map(x => <MenuItem value={x.code}>{x.name}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select 
            name="billing_address.country"
            label="Country"
          >
              {countries.map(x => <MenuItem value={x.code}>{x.name}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Input 
            name="billing_address.zip"
            label="Zip"
          />
        </Grid>
        </Grid>
      </>
      }
      </>
  )
}

const initialValues = {
    name: '',
    amount: '',
    phone: '',
    email: '',
    card_number: '',
    card_cvv: '',
    expiration_date: '',
    customer_profile_id: '',
    customer_payment_profile_id: '',
    customer_profile_email: '',
    provider: 'authorizenet',
    payment_type: 'profile',
    type: 'payment',

    billing_address: {
      first_name: '',
      last_name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
    }
}

export function PaymentDialog(props) {
  return (
    <FormDialog 
      title="Payment"
      routeOptions={{route: 'payments'}}
      validationSchema={PaymentSchema}
      Skeleton={PaymentSkeleton}
      defaultValues={initialValues}
      {...props}
    >
      <Payment />
    </FormDialog>
  )
}