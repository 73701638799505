import { keyBy } from 'lodash'
import React from 'react'
import Table from '.'
import axios from '../../axios'
import { handleErrors } from '../../utils'

export default function DefaultTable(props) {
  const fetchIdRef = React.useRef(0)
  const [state, setState] = React.useState({
    loading: false, data: [], pageCount: 0, count: 0
  })

  const fetchData = React.useCallback(async ({ pageIndex, pageSize, sortBy, filters=[] }) => {
    const fetchId = ++fetchIdRef.current

    const table = document.querySelector('.table-wrapper')
    const scrollPosY = window.scrollY
    const scrollPosX = window.scrollX
    const scrollTop = table.scrollTop
    const scrollLeft = table.scrollLeft
    
    setState(prevState => ({...prevState, loading: true}))
    

    try {
      const { route='', selected='', populated='', filtered='', filter='', query='' } = props.routeOptions
      const sortHash = keyBy(props.columns, 'accessor')
      const {data: users} = await axios.post(`/${route}/table?${query}`, {
        page: pageIndex, pageSize, sorted: sortBy.map(x => {
          const {id} = x
          const col = sortHash[id]?.sortId || id
          return {...x, id: col}
        }), 
        selected, populated, filtered: filters.map(x => {
          const {id, value} = x
          if (!id && !value)
            return x

          const {value: val, filter, filterId=id} = value
          return filter(filterId, val)
        }), filter
      })

      const {data, pages, count} = users

      if (fetchId === fetchIdRef.current)
        setState(prevState => ({...prevState, data, count, pageCount: pages}))

      setTimeout(() => {
        window.scrollTo(scrollPosX, scrollPosY)
        table.scrollTop = scrollTop
        table.scrollLeft = scrollLeft
      }, 100)
    } catch(err) {
      handleErrors({err})
    } finally {
      setState(prevState => ({...prevState, loading: false}))
    }
  }, [props.routeOptions?.query])

  const {loading, data, pageCount, count} = state

  return (
    <Table 
      data={data} 
      fetchData={fetchData}
      loading={loading}
      itemCount={count}
      pageCount={pageCount}
      {...props}
    />
  )
}