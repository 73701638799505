import React, { useMemo, useState } from "react"
import { getLocal, setLocal } from "../../utils"
import ReorderColumns from "../../utils/ReorderColumns"
import { keyBy } from "lodash"

const useColumns = (name, columnKeyArr) => {
    const columnKey = keyBy(columnKeyArr, 'Header')
    const defaultColumnOrder = columnKeyArr.map(({Header}) => ({value: Header, hide: false}))
    
    const [columnOrder, setColumnOrder] = useState(getLocal(name, defaultColumnOrder))
    const updateColumnOrder = (order) => {
        setLocal(name, order)
        setColumnOrder(order)
    }

    const columns = useMemo(
        () => columnOrder.filter(x => !x.hide && columnKey[x.value]).map(x => columnKey[x.value]), [columnOrder]
    )

    return {
        columns,
        reorder: () => <ReorderColumns values={columnOrder} updateValues={updateColumnOrder} defaultValues={defaultColumnOrder} />
    }
}

export default useColumns;