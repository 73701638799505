import React, { useEffect, useState } from 'react'
import axios from '../axios'
import { formatCurrency, formatDate, handleErrors } from '../utils'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core'
import { round, sumBy } from 'lodash'

const formatPct = (num) => `${round(num * 100, 2)}%`

const Dashboard = ({data}) => {
    const {orders, startDate, endDate} = data
    const totalOrders = orders.length
    const totalItems = sumBy(orders, x => x.line_items.length)
    const totalPrice = sumBy(orders, x => x.total_price)
    const totalCost = sumBy(orders, x => x.total_cost)
    const totalProfit = sumBy(orders, x => x.profit_post_discounts)
    const avgMargin = sumBy(orders, x => x.margin_post_discounts) / orders.length

    return (
        <>
            <div>
                {formatDate(startDate, 'l')} - {formatDate(endDate, 'l')}
            </div>
            <br />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Total Orders</TableCell>
                            <TableCell>Total Items</TableCell>
                            <TableCell>Total Price</TableCell>
                            <TableCell>Total Cost</TableCell>
                            <TableCell>Total Profit</TableCell>
                            <TableCell>Avg Margin</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{totalOrders}</TableCell>
                            <TableCell>{totalItems}</TableCell>
                            <TableCell>{formatCurrency(totalPrice)}</TableCell>
                            <TableCell>{formatCurrency(totalCost)}</TableCell>
                            <TableCell>{formatCurrency(totalProfit)}</TableCell>
                            <TableCell>{formatPct(avgMargin)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer><br /><br />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Order</TableCell>
                            <TableCell>Total Items</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Cost</TableCell>
                            <TableCell>Profit</TableCell>
                            <TableCell>Margin</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map((order, i) => (
                            <TableRow key={i}>
                                <TableCell>{order.name}</TableCell>
                                <TableCell>{order.line_items.length}</TableCell>
                                <TableCell>{formatCurrency(order.total_price)}</TableCell>
                                <TableCell>{formatCurrency(order.total_cost)}</TableCell>
                                <TableCell>{formatCurrency(order.profit_post_discounts)}</TableCell>
                                <TableCell>{formatPct(order.margin_post_discounts)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

const LiveDashboard = () => {
    const [data, setData] = useState()

    const getReport = async () => {
        try {
            const {data} = await axios.get('/reports/live-dashboard')
            setData(data)
        } catch(e) {
            handleErrors(e)
        }
    }

    useEffect(() => {
        getReport()
        const interval = setInterval(() => {
            getReport()
        }, 30000)
        return () => clearInterval(interval)
    }, [])

    return (
        <div>
            <h1>Live Dashboard</h1>
            {data && <Dashboard data={data} /> }
        </div>
    )
}

export default LiveDashboard