import React from 'react'
import DefaultTable from '../table/DefaultTable'
import { VendorDialog } from './Vendor'
import useTitle from '../../utils/useTitle'
import { numEqFilter } from '../../utils'

export default function Index() {
  useTitle('Vendors')

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'supplier_id',
        filter: numEqFilter
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Emails',
        accessor: 'emails',
        Cell: ({value}) => value.map(email => <div>{email}</div>),
      },
    ],
    []
  )

  return (
    <DefaultTable 
      columns={columns} 
      routeOptions={{route: 'suppliers', selected: 'name emails supplier_id'}}
      header='Vendor'
      Dialog={VendorDialog}
      deleteTitle={<div>Are you sure you want to delete this vendor? <p style={{color: 'red', textTransform: 'uppercase'}}>This will delete the portal user, and ftp user as well</p></div>}
    />
  )
}