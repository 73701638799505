import React from 'react'
import DefaultTable from '../table/DefaultTable'
import { RoleDialog } from './Role'
import useTitle from '../../utils/useTitle'

export default function Index() {
  useTitle('Roles')

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
    ],
    []
  )

  return (
    <DefaultTable 
      columns={columns} 
      routeOptions={{route: 'roles', selected: 'name'}}
      header='Role'
      Dialog={RoleDialog}
    />
  )
}