import React from 'react'
import { Select as MSelect, FormControl, InputLabel, MenuItem } from '@material-ui/core'
import { useController, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const StyledSelect = styled(MSelect)`
    .MuiSelect-root.MuiSelect-select {
        min-width: 140px;
    }
`

const Select = ({ name='', label, children, options, ...rest }) => {
    const { control } = useFormContext()
    const {
        field: {onChange, onBlur, value, ref},
        formState: {errors}
    } = useController({
        name, control, rules: { required: true },
    })

    const err = errors?.[name]

    return (
        <FormControl>
            {!!label && <InputLabel>{label}</InputLabel>}
            <StyledSelect 
                control={control}
                value={value}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                error={!!err}
                helperText={err?.message}
                style={{width: '100%'}}
                {...rest}
            >
                {options ? options.map(x => {
                    const {key, value} = typeof x === 'string' ? {key: x, value: x} : x
                    return <MenuItem key={key} value={key}>{value}</MenuItem>
                }) : children}
            </StyledSelect>
            {rest.multiple && err && <div style={{color: 'red'}}>{err?.message}</div>}
        </FormControl>
    )
}

export default Select;