import React from 'react'
import Form from './form'
import { Grid } from '@material-ui/core'
import * as Yup from 'yup'
import Input from './form/Input'
import Button from './form/Button'
import axios from '../axios'
import {useAuthContext} from '../auth'
import { useForm } from 'react-hook-form'
import { handleErrors } from '../utils'
import { yupResolver } from '@hookform/resolvers/yup'

const LoginSchema = Yup.object().shape({
  username: Yup
    .string()
    .required('Username is required.'),
})

function MasterLogin() {
  const {login} = useAuthContext()

  const methods = useForm({ defaultValue: {}, resolver: yupResolver(LoginSchema) })
  const { setError } = methods

  const onSubmit = async (userInfo={}) => {
    try {
      //test mode
      // const user = {
      //   name: 'Jack Weber',
      //   role: {
      //     permissions: {
      //       readUser: true,
      //       writeUser: true,
      //       readVendor: true,
      //       writeVendor: true
      //     }
      //   }
      // }
      // const expiry = Date.now() + 100000000
      // localStorage.setItem('user', JSON.stringify(user))
      // localStorage.setItem('expiry', expiry)
      // login({user, expiry})

      //production
      const {data} = await axios.post('/users/master-login', {...userInfo})
      login(data)
    } catch(err) {
      handleErrors({err, setError})
    }
  }

  return (
    <div>
      <Form 
        methods={methods} 
        onSubmit={onSubmit}
      >
        <Grid container>
          <Grid item>
            <Input
              name="username"
              label="Username"
              autoComplete="username"
            />
          </Grid>
        </Grid>
        <br />
        <Button type="submit">Login</Button>
      </Form>
    </div>
  )
}

export default MasterLogin;