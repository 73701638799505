import React from 'react'
import useTitle from '../../utils/useTitle'

export default function Index() {
  useTitle('Diamond Feed')
  
  return (
    <div>
        feeds
    </div>
  )
}