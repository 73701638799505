import { Button, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { useAuthContext } from '../../auth';
import axios from '../../axios';
import { handleErrors } from '../../utils';
import { createBarcodeScanner } from '../../utils/scanner';

export function Scanner(props) {
    const [items, setItems] = React.useState([])
    const [currUser, setCurrUser] = React.useState(null)
    const [isScanning, setIsScanning] = React.useState(false)
    const {user} = useAuthContext()

    const handleBarcode = React.useCallback(async (barcode) => {
        if (items.find(x => x.barcode === barcode)) return

        try {
            console.log('scanning', barcode)
            const {data: {user, items}} = await axios.post('/item-logs/scan', {barcode, user: currUser})
            if (user) {
                setCurrUser(user)
            } else if (items) {
                setItems(x => [...items, ...x])
            }
        } catch (err) {
            handleErrors({err})
        }
    }, [items, currUser])

    React.useEffect(() => {
        const scanner = createBarcodeScanner({onBarcode: handleBarcode});

        if (isScanning)
            document.addEventListener('keydown', scanner.handleKeyEvent);

        return () => {
            document.removeEventListener('keydown', scanner.handleKeyEvent);
        };
    }, [isScanning, handleBarcode])

    return (
        <div>
            {!isScanning ?
                <Button variant='contained' color='primary' onClick={() => setIsScanning(true)}>Start Scanning</Button> :
                <Button variant='contained' color='secondary' onClick={() => setIsScanning(false)}>Stop Scanning</Button>
            }
            {isScanning && <div>
                <LinearProgress style={{margin: '20px 0'}} />
                {!currUser && <div>Please scan user code or <Button style={{marginLeft: '25px'}} color='secondary' onClick={() => setCurrUser(user)}>Use Current User</Button></div>}
                {currUser && <div style={{margin: '20px 0'}}>Current user: {currUser.name} <Button variant='text' color='secondary' onClick={() => setCurrUser(null)}>Clear user</Button></div>}
                {items.length > 0 && 
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Barcode</TableCell>
                                <TableCell>SKU</TableCell>
                                <TableCell>Order Number</TableCell>
                                <TableCell>Certificate Number</TableCell>
                                <TableCell>Lot ID</TableCell>
                                <TableCell>Tracking Number</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map(item => <TableRow key={item.sku}>
                                <TableCell>{item.barcode}</TableCell>
                                <TableCell>{item.sku}</TableCell>
                                <TableCell>{item.order_number}</TableCell>
                                <TableCell>{item.certificate_number}</TableCell>
                                <TableCell>{item.lot_id}</TableCell>
                                <TableCell>{item.tracking_number}</TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                }
            </div>}  
        </div>
    )
}