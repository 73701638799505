import React from 'react'
import Button from '@material-ui/core/Button'
import EmailIcon from '@material-ui/icons/Email'
import { Tooltip, IconButton, DialogContentText, Input } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'
import { toast } from 'react-toastify'

export default function AbandonedEmail({row: {_id, email}, state: {getFetcher}}) {
  const fetchData = getFetcher()
  const [open, setOpen] = React.useState(false)
  const [emailInput, setEmailInput] = React.useState(email)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
    try {
      await axios.post(`/checkouts/${_id}/abandoned-email`, {email: emailInput})
      setOpen(false)
      toast.success('Email successfully sent')
      fetchData()
    } catch(err) {
      handleErrors({err})
      setOpen(false)
    }
  }

  return (
    <>
      <Tooltip title="Send abandoned cart email">
        <IconButton onClick={handleClickOpen}>
          <EmailIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Send abandoned cart email`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="secondary">
            Send Email
          </Button>
        </>}
      >
        <DialogContentText id="alert-dialog-description">
          Send abandoned cart email to <br />
          <Input value={emailInput} onChange={e => setEmailInput(e.target.value)} />
        </DialogContentText>
      </BasicDialog>
    </>
  );
}