import React from 'react'
import { CircularProgress} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import axios from '../../axios'
import { handleErrors } from '../../utils'

const Checkout = () => {
  const {id} = useParams()

  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
      const getData = async () => {
          try {
              const res = await axios.get(`/checkouts/${id}`)
              setData(res.data)
          } catch(e) {
              handleErrors(e)
          } finally {
              setLoading(false)
          }
      }
      getData()
  }, [id])

  if (loading) return <CircularProgress style={{position: 'absolute', left: '50%', top: '50%'}} />

  const {email, total} = data

  return <div>
    Email: {email} <br />
    Total: {total} <br />
  </div>
}

export default Checkout