import React from 'react'
import * as Yup from 'yup'

import styled from 'styled-components'
import { Grid, Typography } from '@material-ui/core'
import Input from '../form/Input'
import Checkbox from '../form/Checkbox'
import FormDialog from '../form/FormDialog'
import InputSkeleton from '../skeletons/InputSkeleton'
import TextSkeleton from '../skeletons/TestSkeleton'
import CheckboxSkeleton from '../skeletons/CheckboxSkeleton'

const NameGrid = styled(Grid)`
  margin-bottom: 30px;
`

const PermissionGrid = styled(Grid)`
  margin-bottom: 30px;
`

PermissionGrid.defaultProps = {
  container: true,
  item: true,
  xs: 6,
  direction: 'column'
}

const RoleSchema = Yup.object().shape({
  name: Yup
    .string()
    .required('Name is required'),
  permissions: Yup.object().shape({
    readUser: Yup
      .boolean(),
    writeUser: Yup
      .boolean(),
    readRole: Yup
      .boolean(),
    writeRole: Yup
      .boolean(),
    readCheckout: Yup
      .boolean(),
    writeCheckout: Yup
      .boolean(),
    readOrderReport: Yup
      .boolean(),
    writeOrderReport: Yup
      .boolean(),
    readPayment: Yup
      .boolean(),
    writePayment: Yup
      .boolean(),
    readSupplier: Yup
      .boolean(),
    writeSupplier: Yup
      .boolean(),
    readOrder: Yup
      .boolean(),
    writeOrder: Yup
      .boolean(),
    readCustomerOrder: Yup
      .boolean(),
    writeCustomerOrder: Yup
      .boolean(),
    readCustomer: Yup
      .boolean(),
    writeCustomer: Yup
      .boolean(),
    readStore: Yup
      .boolean(),
    writeStore: Yup
      .boolean(),
    readReturn: Yup
      .boolean(),
    writeReturn: Yup
      .boolean(),
    readReport: Yup
      .boolean(),
    readScrape: Yup
      .boolean(),
    readBacklinks: Yup
      .boolean(),
    readWarranty: Yup
      .boolean(),
    writeWarranty: Yup
      .boolean(),
    writeAssignment: Yup
      .boolean(),
    readDiamond: Yup
      .boolean(),
    readWebCustomer: Yup
      .boolean()
  })
})

const RoleSkeleton = () => (
  <Grid container spacing={1}>
    <NameGrid item xs={12}>
      <InputSkeleton />
    </NameGrid>
    {[...Array(4)].map((x, i) => <PermissionGrid key={i} direction="column">
      <Grid>
        <TextSkeleton />
      </Grid>
      {[...Array(2)].map((x, i) => <Grid key={i}>
        <CheckboxSkeleton style={{margin: '7px'}} />
      </Grid>)}
    </PermissionGrid>)}
  </Grid>
)

export default function Role() {
  return (
    <Grid container spacing={1}>
      <NameGrid item xs={12}>
        <Input 
          name="name"
          label="Name"
        />
      </NameGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Users</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readUser"
            label="Read User"
          />
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.writeUser"
            label="Write User"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Roles</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readRole"
            label="Read Role"
          />
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.writeRole"
            label="Write Role"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Vendors</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readSupplier"
            label="Read Vendor"
          />
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.writeSupplier"
            label="Write Vendor"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Retailers</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readRetailer"
            label="Read Retailer"
          />
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.writeRetailer"
            label="Write Retailer"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Locations</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readLocation"
            label="Read Location"
          />
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.writeLocation"
            label="Write Location"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Ordered Diamonds</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readOrder"
            label="Read Ordered Diamonds"
          />
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.writeOrder"
            label="Write Ordered Diamonds"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Orders</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readCustomerOrder"
            label="Read Order"
          />
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.writeCustomerOrder"
            label="Write Order"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Customers</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readCustomer"
            label="Read Customer"
          />
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.writeCustomer"
            label="Write Customer"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Checkouts</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readCheckout"
            label="Read Checkout"
          />
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.writeCheckout"
            label="Write Checkout"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Order Reports</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readOrderReport"
            label="Read Order Report"
          />
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.writeOrderReport"
            label="Write Order Report"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Payments</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readPayment"
            label="Read Payment"
          />
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.writePayment"
            label="Write Payment"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>VS Stores</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readStore"
            label="Read Store"
          />
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.writeStore"
            label="Write Store"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Returns</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readReturn"
            label="Read Return"
          />
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.writeReturn"
            label="Write Return"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Assign Orders</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.writeAssignment"
            label="Write Assignment"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Warranties</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readWarranty"
            label="Read Warranty"
          />
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.writeWarranty"
            label="Write Warranty"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Reports</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readReport"
            label="Read Report"
          />
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readBacklinks"
            label="Read Backlinks"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Scrape</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readScrape"
            label="Read Scrape"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Diamonds</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readDiamond"
            label="Read Diamond"
          />
        </Grid>
      </PermissionGrid>
      <PermissionGrid direction="column">
        <Grid>
          <Typography>Customer Board</Typography>
        </Grid>
        <Grid>
          <Checkbox
            name="permissions.readWebCustomer"
            label="Read Customer Board"
          />
        </Grid>
      </PermissionGrid>
    </Grid>
  )
}

const initialValues = {
  name: '',
  permissions: {
    readUser: false,
    writeUser: false,
    readRole: false,
    writeRole: false,
    readSupplier: false,
    writeSupplier: false,
    readRetailer: false,
    writeRetailer: false,
    readLocation: false,
    writeLocation: false,
    readOrder: false,
    writeOrder: false,
    readCustomerOrder: false,
    writeCustomerOrder: false,
    readCustomer: false,
    writeCustomer: false,
    readStore: false,
    writeStore: false,
    readReturn: false,
    writeReturn: false,
    readWarranty: false,
    writeWarranty: false,
    readReport: false,
    readScrape: false,
    readBacklinks: false,
    writeAssignment: false,
    readDiamond: false,
    readWebCustomer: false,
    readOrderReport: false,
    writeOrderReport: false,
    readPayment: false,
    writePayment: false,
  }
}

export function RoleDialog(props) {
  return (
    <FormDialog 
      title="Role"
      routeOptions={{route: 'roles'}}
      validationSchema={RoleSchema}
      Skeleton={RoleSkeleton}
      defaultValues={initialValues}
      {...props}
    >
      <Role />
    </FormDialog>
  )
}