import React from 'react'
import Button from '@material-ui/core/Button'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { Tooltip, IconButton, CircularProgress } from '@material-ui/core'
import BasicDialog from '../form/BasicDialog'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import { useForm } from 'react-hook-form';
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Form from '../form'
import Input from '../form/Input'

const labelSchema = Yup.object().shape({
  hours: Yup
    .number()
    .min(0),
  row: Yup
    .number()
    .integer()
    .min(1)
    .max(10),
  column: Yup
    .number()
    .integer()
    .min(1)
    .max(5),
})

const defaultValues = {
  row: 1, column: 1, hours: 2
}

export default function DownloadReceivedLabels({rows}) {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const methods = useForm({ 
    defaultValues, resolver: yupResolver(labelSchema),
  })

  const handleConfirm = async (labelInfo) => {
    try {
        setLoading(true)

        const selectedRows = rows
          .filter(x => x.status === 'success')
          .map(({orders}) => orders?.[orders.length - 1])

        const emptyLabels = (labelInfo.row - 1) * 5 + (labelInfo.column - 1) 
        const {data} = await axios.post(`/orders/received/labels`, {rows: selectedRows, emptyLabels, hours: labelInfo.hours}, {timeout: 30000})
        window.open(data.url, '_blank')
        handleClose()
    } catch(err) {
        handleErrors({err})
    } finally {
        setLoading(false)
    }
  }

  return (
    <>
      <Tooltip title="Download Orders">
        <IconButton onClick={handleClickOpen}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Download Orders`}
        // actions={<>
        //   <Button onClick={handleClose} color="primary" autoFocus>
        //     Cancel
        //   </Button>
        //   <Button loading={loading} onClick={handleConfirm} color="secondary">
        //     Download Orders
        //   </Button>
        // </>}
      >
        <Form
          onSubmit={handleConfirm}
          methods={methods}
        >
          Hours <Input type="number" name="hours" /><br />
          Start row <Input type="number" name="row" /><br />
          Start column <Input type="number" name="column" /><br /><br />
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button type="submit" color="secondary">
            {loading ? <CircularProgress /> : 'Download Labels'}
          </Button>
        </Form>
      </BasicDialog>
    </>
  );
}