import React from 'react'
import DefaultTable from '../table/DefaultTable'
import useTitle from '../../utils/useTitle'
import { Link } from 'react-router-dom'
import AssignOrder from '../orders/AssignOrder'
import { useAuthContext } from '../../auth'

export default function Index() {
  useTitle('Customer Board')

  const {user, hasPermission} = useAuthContext()

  const columns = React.useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'info.email',
        Cell: ({value, row}) => <Link to={`/customer-board/${row.original?._id}`}>{value || 'No Email'}</Link> 
      },
      {
        Header: 'First Name',
        accessor: 'info.first_name',
        Cell: ({row: {original: {info}}}) => `${info?.first_name || ''} ${info?.last_name || ''}`
      },
      {
        Header: 'Score',
        accessor: 'score',
      },
      {
        Header: 'Assigned To',
        accessor: 'assigned_to_name',
        Cell: ({value='------', row: {original}, initialState: {getFetcher}}) => {
            const text = original.assigned_to === user._id ? 'Me' : value || '------'
            return hasPermission('writeAssignment') ? <AssignOrder id={original._id} fetchData={getFetcher()} route="web-customers" name="Customer">{text}</AssignOrder> : text
        }
        }
    ],
    []
  )

    return (
        <DefaultTable 
            columns={columns} 
            routeOptions={{
                route: 'web-customers', selected: 'email score info assigned_to assigned_to_name', 
                sorted: [{id: "score", desc: true}],
            }}
            header='Customer Board'
            actions={{
                showActions: false
            }}
        />
    )
}