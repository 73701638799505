import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, useMediaQuery } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import Button from './Button'
import Form from '.'
import { useForm } from 'react-hook-form'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import { yupResolver } from '@hookform/resolvers/yup'

const useStyles = makeStyles(theme => ({
  actions: {
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      padding: '15px',
      backgroundColor: '#efefef'
    },
  },
}))

const Wrapper = styled.div`
  padding: 150px;
  text-align: center;
`

const FetchError = () => (
  <Wrapper>
    An error has occured.
  </Wrapper>
)

const Progress = () => (
  <Wrapper>
    <CircularProgress />
  </Wrapper>
)

const defaultOnSubmit = async ({data, handleSave, setError, route, timeout}) => {
  const {_id} = data
  try {
    if (!_id)
      await axios.post(`/${route}`, data, {
          timeout
      })
    else
      await axios.put(`/${route}/${_id}`, data)
    handleSave()
  } catch(err) {
    handleErrors({err, setError})
  }
}

const FormDialog = ({ 
  title='', children, Skeleton=Progress,
  routeOptions: {route='', selected='', populated=''}, 
  onSubmit=defaultOnSubmit, itemId, defaultValues, validationSchema, 
  open, handleClose, handleSave, transformData, preTransformData,
  timeout=10000
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isUpdate = !!itemId
  const titleAction = isUpdate ? 'Edit' : 'Add'
  const schema = typeof validationSchema === 'function' ? validationSchema({isNew: !isUpdate}) : validationSchema

  const [loading, setLoading] = React.useState(false)
  const [fetchError, setFetchError] = React.useState(false)

  const methods = useForm({ defaultValues, resolver: yupResolver(schema) })
  const {reset, setError} = methods

  const handleSubmit = async (data) => {
    if (isUpdate)
      data._id = itemId
    if (typeof transformData === 'function')
      transformData(data)
    await onSubmit({data, handleSave, setError, route, timeout})
  }

  React.useEffect(() => {
    if (!open || !isUpdate) return
    const getData = async () => {
      setLoading(true)
      try {
        const {data} = await axios.get(`/${route}/${itemId}?selected=${selected}&populated=${populated}`)
        const transformedData = preTransformData ? preTransformData(data) : data
        reset(transformedData)
      } catch(err) {
        handleErrors({err})
        setFetchError(true)
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [open])

  React.useEffect(() => {
    if (!itemId)
      reset(defaultValues)
  }, [itemId])

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      style={{zIndex: '1200'}}
    >
      <DialogTitle>{titleAction} {title}</DialogTitle>
        <Form
          onSubmit={handleSubmit}
          methods={methods}
        >
          <DialogContent>
            {loading ? 
              <Skeleton /> :
              (fetchError ? 
                <FetchError /> : 
                children
              )
            }
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button variant="text" onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button variant="text" type="submit">
              Save
            </Button>
          </DialogActions>
        </Form>
    </Dialog>
  );
}

export default FormDialog;