import React from 'react'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import { Tooltip, IconButton, MenuItem, Grid } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import Input from '../form/Input'
import Select from '../form/Select'
import states from '../../utils/states.json'
import countries from '../../utils/countries.json'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import Form from '../form'

const addressSchema = Yup.object().shape({
    first_name: Yup
      .string()
          .required('First name is required'),
    last_name: Yup
      .string()
          .required('Last name is required'),
    address1: Yup.string()
        .required('Address is required'),
    address2: Yup.string(),
    city: Yup.string().required('City is required'),
    state_code: Yup.string().required('State is required'),
    country_code: Yup.string().required('Country is required'),
    zip: Yup.string().required('Zipcode is required'),
    phone: Yup.string(),
})

export default function EditAddress({id, address, name, fetchData}) {
  const [open, setOpen] = React.useState(false)

  const methods = useForm({ 
    defaultValues: address, resolver: yupResolver(addressSchema),
  })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = async () => {
      try {
        const data = methods.getValues()
        data.state = states.find(x => x.code === data.state_code)?.name
        data.country = countries.find(x => x.code === data.country_code)?.name
        await axios.post(`/order-reports/${id}/fedex-address`, data)
        toast.success('Successfully updated')
        setOpen(false)
        fetchData()
      } catch(err) {
        handleErrors({err})
      }
  }

  return (
    <>
      <Tooltip title="Update Address">
        <IconButton onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Update Address for ${name}`}
        // actions={<>
        //   <Button onClick={handleClose} color="primary" autoFocus>
        //     Cancel
        //   </Button>
        //   <Button onClick={handleSubmit} color="secondary">
        //     Update Address
        //   </Button>
        // </>}
      >
        <Form
          onSubmit={handleSubmit}
          methods={methods}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Input
                name="first_name"
                label="First name"
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name="last_name"
                label="Last name"
              />
            </Grid>
            <Grid item xs={6}>
              <Input 
                name="address1"
                label="Address 1"
              />
            </Grid>
            <Grid item xs={6}>
              <Input 
                name="address2"
                label="Address 2"
              />
            </Grid>
            <Grid item xs={6}>
              <Input 
                name="city"
                label="City"
              />
            </Grid>
            <Grid item xs={6}>
              <Select 
                name="state_code"
                label="State"
                placeholder='State'
              >
                  {states.map(x => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem>)}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select 
                name="country_code"
                label="Country"
              >
                  {countries.map(x => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem>)}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Input 
                name="zip"
                label="Zip"
              />
            </Grid>
            <Grid item xs={6}>
              <Input 
                name="phone"
                label="Phone"
              />
            </Grid>
          </Grid>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button type="submit" color="secondary">
            Update Address
          </Button>
        </Form>
      </BasicDialog>
    </>
  );
}