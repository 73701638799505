import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Button as MButton } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'

const Button = ({ 
  type='button', 
  variant='contained', 
  color='primary', 
  disableElevation=true, 
  children,
  disabled,
  ...rest
}) => {
  const { control, formState: {isDirty, isSubmitting} } = useFormContext()

  const isSubmit = type === 'submit'
  let isDisabled = false
  if (disabled === undefined)
    isDisabled = isSubmit && !isDirty
  else if (typeof disabled === 'function')
    isDisabled = disabled()
  else
    isDisabled = disabled

  return (
    <MButton 
      type={type} 
      variant={variant}
      color={color}
      disableElevation={disableElevation}
      disabled={isDisabled}
      {...rest}
    >
      {isSubmit && isSubmitting ? <CircularProgress size={24} color="secondary" /> : children}
    </MButton>
  )
}

export default Button;