import React, { useRef, useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'
import { handleErrors } from '../../utils';
import axios from '../../axios'
import {keyBy} from 'lodash'
import { Link } from 'react-router-dom';
import DownloadReceivedLabels from './DownloadReceivedLabels';

const CertImg = ({url, loading, status, orders=[], error}) => {
    return <Grid container spacing={3}>
        <Grid item style={{display: 'flex'}}>
            {loading ? <CircularProgress /> : (status === 'success' ? '✅' : '❌')}
        </Grid>
        <Grid item>
            <a href={url} target="_bland">
                <img height="100" src={url} />
            </a> 
        </Grid>
        <Grid item>
            {!loading && <div style={{padding: '1em', display: 'flex', flexWrap: 'wrap', gap: '15px'}}>
                {status === 'success' ? orders.map(({name, loose, certificate_number}) => <div key={name}>  
                    <div><b>Order:</b> <Link to={`/orders`}>{name} - {loose ? 'LD' : 'R'}</Link></div>
                    <div><b>Cert:</b> {certificate_number}</div>
                </div>)  : <div style={{color: 'red', whiteSpace: 'pre'}}><b>Error:</b> {error}</div>}
            </div>}
        </Grid>
    </Grid>
    
}

export default function ReceiveDiamonds({receive=false}) {
    const [files, setFiles] = useState([])
    const ref = useRef()

    const handleUpload = async (e) => {
        const files = [...e.target.files]
            .map(x => {
                x.url = URL.createObjectURL(x)
                x.loading = true
                return x
            })
        setFiles(arr => files.concat(arr))

        try {
            // const fd = objectToFormData({files})
            const fd = new FormData()
            for (const file of files)
                fd.append('files[]', file)

            const route = receive ? 'receive' : 'invoice'
            const {data} = await axios.post(`/orders/${route}`, fd, {timeout: 60000})
            const fileHash = keyBy(data.files, 'name')
            setFiles(files => files.map(file => {
                const retFile = fileHash[file.name]
                if (retFile) {
                    const {status, orders, error} = retFile
                    Object.assign(file, {loading: false, status, orders, error})
                }
                return {...file, name: file.name}
            }))
        } catch(err) {
            handleErrors({err})
        }
    }

    const clearFiles = () => {
        setFiles([])
        ref.current.value = ''
    }

    const header = receive ? 'Receive Diamonds' : 'Upload Invoices'

    return <div>
        <h1>{header} <DownloadReceivedLabels rows={files} /></h1>
        
        <input ref={ref} type="file" accept="image/*" capture="camera" multiple onChange={handleUpload} />
        <br /><br /><Button variant="contained" color="secondary" onClick={clearFiles}>Clear Results</Button>
        <br /><br />
        {files.map(x => <CertImg key={x.name} {...x} />)}
    </div>
}