import React, { useState } from 'react'
import DefaultTable from '../table/DefaultTable'
import { useParams, useLocation } from 'react-router-dom'
import ConfirmAvailability from './ConfirmAvailability'
import AddTracking from './AddTracking'
import GetRecommendations from './GetRecommendations'
import useTitle from '../../utils/useTitle'
import { formatCurrency, numEqFilter, NotEqFilter, strEqFilter, LteFilter, NinFilter, handleErrors, downloadBlob, getLocal, setLocal } from '../../utils'
import { useAuthContext } from '../../auth'
import { OrderDialog } from './Order'
import moment from 'moment'
import AssignOrder from './AssignOrder'
import axios from '../../axios'
import { Button, IconButton, Tooltip } from '@material-ui/core'
import DelayedEmail from './DelayedEmail'
import { Link } from 'react-router-dom'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import DownloadReceived from './DownloadReceived'
import ReceiptIcon from '@material-ui/icons/Receipt'
import { keyBy } from 'lodash'
import ReorderColumns from '../../utils/ReorderColumns'
import DownloadPickups from './DownloadPickups'

const statuses = {
  pending: 'Pending',
  unavailable: 'Unavailable',
  on_hold: 'On hold',
  ready_to_ship: 'Ready to ship',
  shipped: 'Shipped',
  cancelled: 'Cancelled',
  received: 'Received'
}

export default function Index() {
    const [loading, setLoading] = useState()
    const { status } = useParams()
    const query = new URLSearchParams(useLocation().search)
    const isPendingVerification = query.get('verification') === 'pending'
    const isNeededTomorrow = query.get('needed') === 'tomorrow'

    const {user, hasPermission} = useAuthContext()
    const {is_supplier} = user

    useTitle('Orders')

    const downloadReport = async (selectedRows, date) => {
        try {
            setLoading(true)
            const {data} = await axios.post('/orders/orderreport', {ids: selectedRows.map(x => x._id), date})
            const blob = new Blob([data], { type: 'text/csv' })
            downloadBlob(blob, 'report.csv') 
        } catch(e) {
            handleErrors(e)
        } finally {
            setLoading(false)
        }
    }

    const columnKeyArr = [
        {
            Header: 'Order',
            accessor: 'name',
        },
        {
            Header: 'Date',
            accessor: 'createdAt',
            Cell: ({value}) => moment(value).format('M/D/YYYY h:mm A'),
            disableFilters: true
        },
        ...(!is_supplier ? [
            {
            Header: 'Added On',
            accessor: 'added_on',
            Cell: ({value}) => value ? moment(value).format('M/D/YYYY h:mm A') : '',
            disableFilters: true
            }
        ] : []),
        {
            Header: 'SKU',
            accessor: 'sku',
            Cell: ({value}) => <div style={{whiteSpace: 'nowrap'}}>{value}</div>
        },
        ...(!is_supplier ? [
            {
            Header: 'Vendor',
            accessor: 'supplier.name',
            filter: numEqFilter,
            placeholder: 'vendor ID',
            filterId: 'supplier_id',
            sortId: 'supplier_id'
            }
        ] : []),
        {
            Header: 'Vendor SKU',
            accessor: 'supplier_sku',
            Cell: ({value}) => <div style={{whiteSpace: 'nowrap'}}>{value}</div>
        },
        {
            Header: 'Carat',
            accessor: 'carat',
            filter: numEqFilter
        },
        {
            Header: 'Shape',
            accessor: 'shape',
        },
        {
            Header: 'Color',
            accessor: 'color',
        },
        {
            Header: 'Cut',
            accessor: 'cut',
        },
        {
            Header: 'Clarity',
            accessor: 'clarity',
        },
        {
            Header: 'Vendor Cost',
            accessor: 'supplier_cost',
            Cell: ({value}) => formatCurrency(value),
            disableFilters: true
        },
        {
            Header: 'Cert Lab',
            accessor: 'certificate_lab',
        },
        {
            Header: 'Cert #',
            accessor: 'certificate_number',
            Cell: ({value, row: {original}}) => {
                return <div>
                    {value}
                    {!is_supplier && !!original.invoice_url && <a href={original.invoice_url} target="_blank" rel="noopener noreferrer"><ReceiptIcon /></a>}
                </div>
            }
        },
        {
            Header: 'Tracking',
            accessor: 'supplier_tracking',
        },
        {
            Header: 'Status',
            accessor: 'fulfillment_status',
            Cell: ({value, row: {original}}) => {
                const val = statuses[value] || value
                return <div>
                    {(!is_supplier && original.cert_url) ? <a href={original.cert_url} target="_blank" rel="noopener noreferrer">{val}</a> : val}
                </div>
            },
            disableFilters: true
        },
        ...(!is_supplier ? [
            {
                Header: 'Received By',
                accessor: 'received_by_name',
                disableFilters: true,
                Cell: ({value, row: {original}}) => {
                    return <div>
                        {value}
                        {original.fulfillment_status === 'received' && original.received_at && <div>
                            On {moment(original.received_at).format('YYYY-MM-DD HH:MM')}
                        </div>}
                    </div>
                }
            },
            {
                Header: 'Delivery By',
                accessor: 'delivery_by',
                Cell: ({value}) => value ? moment(value).format('M/D/YYYY') : '',
                disableFilters: true
            },
            {
                Header: 'Assigned To',
                accessor: 'assigned_to_name',
                Cell: ({value='------', row: {original}, initialState: {getFetcher}}) => {
                    const text = original.assigned_to === user._id ? 'Me' : value
                    return hasPermission('writeAssignment') ? <AssignOrder id={original._id} fetchData={getFetcher()}>{text}</AssignOrder> : text
                }
            }
        ] : []),
    ]
    const columnKey = keyBy(columnKeyArr, 'Header')
    const defaultColumnOrder = columnKeyArr.map(({Header}) => ({value: Header, hide: false}))
    const [columnOrder, setColumnOrder] = React.useState(getLocal('ordered-diamonds-column-order', defaultColumnOrder))
    const updateColumnOrder = (order) => {
        setLocal('ordered-diamonds-column-order', order)
        setColumnOrder(order)
    }

    const columns = React.useMemo(
        () => columnOrder.filter(x => !x.hide && columnKey[x.value]).map(x => columnKey[x.value]),
        [is_supplier, columnOrder]
    )

    const filtered = status ? [{id: 'fulfillment_status', value: {value: status, filter: strEqFilter}}] : []
const sorted = isNeededTomorrow ? [{id: 'supplier.name', desc: false}] : [{id: "createdAt", desc: true}]
    if (isPendingVerification)
        filtered.push(...[
            {id: 'verification_status', value: {value: 'pending', filter: numEqFilter}},
            {id: 'fulfillment_status', value: {value: 'cancelled', filter: NotEqFilter}},
        ])
    if (isNeededTomorrow) 
        filtered.push(...[
            {id: 'fulfillment_status', value: {value: ['received', 'cancelled'], filter: NinFilter}},
            {id: 'delivery_by', value: {value: moment().utcOffset(-240).add(1, 'day').endOf('day').toDate(), filter: LteFilter}}
        ])

    return (
        <>
            <DefaultTable 
                columns={columns} 
                routeOptions={{
                    route: 'orders', filtered, sorted,
                    selected: 'name sku supplier_sku carat shape color clarity cut supplier_cost certificate_lab certificate_number supplier_tracking fulfillment_status supplier_id createdAt received_by_name received_at invoice_url cert_url delivery_by assigned_to assigned_to_name added_on',
                    populated: {path: 'supplier', select: '-_id name'}
                }}
                header={<>
                        Order 
                        {!is_supplier && <>
                            <Link style={{marginLeft: '10px'}} to="/orders/receive">
                                <Tooltip title="Receive orders">
                                    <IconButton>
                                        <AddAPhotoIcon />
                                    </IconButton>
                                </Tooltip>    
                            </Link>
                            <Link style={{marginLeft: '10px'}} to="/orders/invoice">
                                <Tooltip title="Invoices">
                                    <IconButton>
                                        <ReceiptIcon />
                                    </IconButton>
                                </Tooltip>    
                            </Link>
                            <DownloadReceived loading={loading} onConfirm={downloadReport} />
                            <DownloadPickups />
                        </>}
                        <ReorderColumns values={columnOrder} updateValues={updateColumnOrder} defaultValues={defaultColumnOrder} />
                    </>}
                actions={{canAdd: !is_supplier, canEdit: !is_supplier, canDelete: false, showActions: true, OtherActions}}
                Dialog={OrderDialog}
                getRowProps={(x, {row}) => {
                    const createdAt = moment(row.original.createdAt)
                    const now = moment()
                    const diff = Math.abs(createdAt.diff(now, 'hours'))
                    const isPending = row.original.fulfillment_status === 'pending'
                    const red = (diff > 12 && isPending) ? {className: 'red'} : {}
                    return {...x, ...red}
                }}
                selectedActions={[
                    ({selectedRows}) => <div style={{display: 'flex'}}>
                        <DelayedEmail selectedRows={selectedRows} />
                        <Button loading={loading} onClick={() => downloadReport(selectedRows)}>Download</Button>
                    </div>
                ]}
            />
        </>
    )
}

const OtherActions = ({row, onFetchData}) => {
    const {user} = useAuthContext()
    const {is_supplier} = user

    const {fulfillment_status, _id} = row.original 
    return <>
        {!['cancelled', 'shipped', 'received'].includes(fulfillment_status) && <ConfirmAvailability ids={[_id]} fetchData={onFetchData} />}
        {['ready_to_ship', 'shipped', 'received'].includes(fulfillment_status) && <AddTracking id={_id} fetchData={onFetchData} />}
        {!is_supplier && <GetRecommendations id={_id} />}
    </>
}