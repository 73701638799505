import React from 'react'
import Button from '@material-ui/core/Button'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import { Tooltip, IconButton, DialogContentText } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'

export default function ConfirmAvailability({ids, fetchData}) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async (available) => {
    try {
      await axios.patch(`/orders/availability`, {ids, available})
      setOpen(false)
      fetchData()
    } catch(err) {
      handleErrors({err})
      setOpen(false)
    }
  }

  return (
    <>
      <Tooltip title="Confirm Availability">
        <IconButton onClick={handleClickOpen}>
          <AssignmentTurnedInIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Confirm Availability`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={() => handleConfirm('no')} color="secondary">
            Unavailable
          </Button>
          <Button onClick={() => handleConfirm('yes')} color="secondary">
            Available
          </Button>
        </>}
      >
        <DialogContentText id="alert-dialog-description">
          Confirm availability for this item
        </DialogContentText>
      </BasicDialog>
    </>
  );
}