import React, {useState} from 'react'
import Form from '../form'
import { Grid, Paper } from '@material-ui/core'
import * as Yup from 'yup'
import Input from '../form/Input'
import Button from '../form/Button'
import { makeStyles } from '@material-ui/core/styles'
import axios from '../../axios'
import { useForm } from 'react-hook-form'
import { handleErrors } from '../../utils'
import { yupResolver } from '@hookform/resolvers/yup'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(#1b2430, #151C26);',
    height: '100vh'
  },
  logo: {
    textAlign: 'center'
  },
  paper: {
    width: '320px',
    padding: '40px 40px',
    [theme.breakpoints.up('md')]: {
      width: '320px',
      padding: '60px 40px',
    },
  },
  button: {
    width: '100%', marginTop: '20px'
  }
}));

const RequestPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email'),
})

function RequestPassword() {
    const classes = useStyles()
    const [email, setEmail] = useState('')

    const methods = useForm({ defaultValue: {}, resolver: yupResolver(RequestPasswordSchema) })
    const { setError } = methods

    const onSubmit = async (userInfo={}) => {
        try {
            await axios.post('/users/password-request', {...userInfo})
            setEmail(userInfo.email)
        } catch(err) {
            handleErrors({err, setError})
        } 
    }

    return (
        <div className={classes.root}>
        <Form 
            methods={methods} 
            onSubmit={onSubmit}
        >
            <Paper className={classes.paper} elevation={0}>
                {email ? <div >An email has been sent to {email}</div> :
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                        <h2 className={classes.logo}>Ritani Portal</h2>
                        </Grid>
                        <Grid item xs={12}>
                        <Input 
                            name="email"
                            label="Email"
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <Button className={classes.button} disabled={false} type="submit">Request New Password</Button>
                        </Grid>
                    </Grid>
                }
            </Paper>
        </Form>
        </div>
    )
}

export default RequestPassword;