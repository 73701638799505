import React from 'react'
import * as Yup from 'yup'

import { Grid } from '@material-ui/core'
import Input from '../form/Input'
import Select from '../form/Select'
import FormDialog from '../form/FormDialog'
import InputSkeleton from '../skeletons/InputSkeleton'
import countries from '../../utils/countries.json'
import states from '../../utils/states.json'

const stateOptions = states.map(({name, code}) => ({key: code, value: name}))
const countryOptions = countries.map(({name, code}) => ({key: code, value: name}))

const StoreSchema = Yup.object().shape({
    name: Yup
        .string()
        .required('Name is required'),
    email: Yup.string().email('Invalid email'),
    phone: Yup.string(),
    address1: Yup.string().required(),
    address2: Yup.string(),
    city: Yup.string().required(),
    state: Yup.string().oneOf(states.map(x => x.code)).required(),
    zip: Yup.string().required(),
    country: Yup.string().oneOf(countries.map(x => x.code)).required()
})

const StoreSkeleton = () => (
    <Grid container spacing={1}>
        {[...Array(8)].map((x, i) => <Grid key={i} item md={6} xs={12}>
            <InputSkeleton />
        </Grid>)}
    </Grid>
)

export default function Store() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
            <Input 
                name="name"
                label="Name"
            />
        </Grid>
        <Grid item md={6} xs={12}>
            <Input 
                name="email"
                label="Email"
                autoComplete="email"
            />
        </Grid>
        <Grid item md={6} xs={12}>
            <Input 
                name="phone"
                label="Phone"
                autoComplete="tel"
            />
        </Grid>
        <Grid item md={6} xs={12}>
            <Input 
                name="address1"
                label="Address1"
                autoComplete="address-line1"
            />
        </Grid>
        <Grid item md={6} xs={12}>
            <Input 
                name="address2"
                label="Address2"
                autoComplete="address-line2"
            />
        </Grid>
        <Grid item md={6} xs={12}>
            <Input 
                name="city"
                label="City"
                autoComplete="address-level2"
            />
        </Grid>
        <Grid item md={6} xs={12}>
            <Select 
                name="state"
                label="State"
                autoComplete="address-level1"
                options={stateOptions}
            />
        </Grid>
        <Grid item md={6} xs={12}>
            <Input 
                name="zip"
                label="Zip"
                autoComplete="postal-code"
            />
        </Grid>
        <Grid item md={6} xs={12}>
            <Select
                name="country"
                label="Country"
                autoComplete="country"
                options={countryOptions}
            />
        </Grid>
    </Grid>
  )
}

const initialValues = {
    name: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: ''
}

export function StoreDialog(props) {
  return (
    <FormDialog 
      title="Store"
      routeOptions={{route: 'stores'}}
      validationSchema={StoreSchema}
      Skeleton={StoreSkeleton}
      defaultValues={initialValues}
      {...props}
    >
      <Store />
    </FormDialog>
  )
}