import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from '../../axios'
import {handleErrors, formatCurrency, formatDate} from '../../utils'
import { CircularProgress, Grid, Card as MCard, CardContent, Accordion, AccordionDetails, AccordionSummary, Chip, Tooltip, IconButton } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EditIcon from '@material-ui/icons/Edit'
import moment from 'moment'
import styled from 'styled-components'
import {round} from 'lodash'
import CreateRepair from './CreateRepair'
import CreateLabel from './CreateLabel'
import EmailLabel from './EmailLabel'
import { useAuthContext } from '../../auth'

const StyledGrid = styled(Grid)`
    h1, h2, h3, h4, h5, h6 {margin-bottom: 0;}
    .container {margin-top: 20px;}
    .MuiCard-root {margin-bottom: 25px;}
    .card-title {
        margin-top: 5px;
        margin-bottom: 15px;
    }
    p {
        margin: 0;
    }
    .main {margin-right: 30px;}
    .sidebar {max-width: 400px;}
    .MuiCardContent-root {padding: 25px;}
    .chips {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        > * {
            margin-right: 10px;
        }
    }
    table {
        width: 100%;
        td:not(:last-child) {padding-right: 15px;}
        td:first-child {width: 55%;}
        td:last-child {text-align: right}
    }
    .line-items {margin-bottom: 25px;}
    .discounted-price {
        text-decoration: line-through;
        opacity: 0.6;
    }
    .accordion-details {
        width: 100%;
        margin-right: 35px;
    }
    .promo-table {margin-bottom: 15px}
    .promos {opacity: 0.6;}
    .notes {white-space: pre-line}
    .status-red {color: red}
    .status-green {color: green}
`

const OptionalRow = ({value, title, ...rest}) => {
    if (!value) return null
    return <p {...rest}>{!!title && `${title}:`} {value}</p>
}

const SummaryRows = ({negative=false, arr, total, title: rowTitle}) => {
    const newArr = arr.length ? arr : [{amount: 0}]
    return <>
        <tr><td></td></tr>
        {newArr.map(({title, amount, rate}, idx) => <tr key={idx}>
            <td>{idx === 0 ? rowTitle : ''}</td>
            <td>{title} {!!rate && !/(.+%)/.test(title) && <>({round(rate * 100, 3)}%)</>}</td>
            <td>{formatCurrency(negative ? -amount : amount)}</td>
        </tr>)}
        <tr><td>Total {rowTitle}</td><td></td><td>{formatCurrency(negative ? -total : total)}</td></tr>
        <tr><td></td></tr>
    </>
}

const Card = ({title, children, ...rest}) => <MCard {...rest}>
    <CardContent>
        <h3 className="card-title">{title}</h3>
        {children}
    </CardContent>
</MCard>

const AddressCard = ({title, address}) => {
    const {first_name, last_name, address1, address2='', city, state, zip, country, phone=''} = address || {}
    return <Card title={title}>
        <p className='address-first-name'>{first_name} {last_name}</p>
        <p className='address-address1'>{address1}</p>
        <p className='address-address2'>{address2}</p>
        <p><span className='address-city'>{city}</span>, <span className='address-state'>{state}</span> <span className='address-zip'>{zip}</span></p>
        <p className='address-country'>{country}</p>
        <p className='address-phone'>{phone}</p>
    </Card>
}

const OptionalChip = ({condition, label, color}) => {
    if (!condition) return null
    return <Chip size="small" label={label} color={color} />
}

const CustomerOrder = () => {
    const {id} = useParams()

    const [loading, setLoading] = React.useState(true)
    const [data, setData] = React.useState(null)
    const [count, setCount] = React.useState(0)
    const reload = () => setCount(x => x + 1)
    const history = useHistory()

    const {user} = useAuthContext()

    React.useEffect(() => {
        const getData = async () => {
            try {
                const res = await axios.get(`/customer-orders/${id}?populated=retailer`)
                setData(res.data)
            } catch(e) {
                handleErrors(e)
            } finally {
                setLoading(false)
            }
        }
        getData()
    }, [id, count])

    if (loading) return <CircularProgress style={{position: 'absolute', left: '50%', top: '50%'}} />

    const {
        name, email='', phone='', customer_name='', notes, comments, 
        financial_status, fulfillment_status,
        billing_address, shipping_address, created_at, 
        line_items, charges, payments, tax_lines, order_discounts, 
        total_tax, total_payments, total_order_discounts, total_price,
        payment_adjustments, total_payment_adjustments, total_shipping,
        total_item_discounted_price, total_charges, warranty,
        cancelled_at, is_vip, source, outcome_status, current_order_rma, outcome_exchange_order_id,
        purpose_of_order_status, purpose_source_order_id, status, retailer, commission, is_repair,
        return_label, return_tracking, return_sent_at, ip_address, order_payments=[], timeline=[]
    } = data

    const outcomeStatusLabel = outcome_exchange_order_id ? ` to ${outcome_exchange_order_id}` : ''

    return <StyledGrid container spacing={1}>
        <Grid item xs={12}>
            <h2>{name}</h2>
            <span>Created at {moment(created_at).format('M/D/YYYY h:mm A')}</span>
            <div className="chips">
                <OptionalChip condition={true} label={source} color="primary" />
                <OptionalChip condition={!!cancelled_at} label="cancelled" />
                <OptionalChip condition={!!status} label={status} />
                <OptionalChip condition={is_vip} label="VIP" />
                <OptionalChip condition={!!outcome_status} label={`${outcome_status}${outcomeStatusLabel}`} />
                <OptionalChip condition={!!current_order_rma} label={`RMA - ${current_order_rma}`} />
                <OptionalChip condition={!!purpose_of_order_status} label={`${purpose_of_order_status} of ${purpose_source_order_id || ''}`} />
            </div>
            <Tooltip title={`Edit order`}>
                <IconButton onClick={() => history.push(`/customer-orders/${id}/edit`)}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            {!is_repair && <CreateRepair {...data} />}
            {!is_repair && <CreateRepair isReturn {...data} />}
            {is_repair && !(purpose_of_order_status === 'return' && return_label) && <CreateLabel order_id={data._id} reload={reload} />}
            {is_repair && <EmailLabel order_id={data._id} reload={reload} />}<br />
            {return_tracking && <span style={{marginRight: '20px'}}>
                Return tracking: <a href={`https://www.fedex.com/fedextrack/?trknbr=${return_tracking}`} target="_blank" rel="noopener noreferrer">{return_tracking}</a>
            </span>}
            {return_sent_at && <span>Return label emailed at {formatDate(return_sent_at, 'l')}</span>}
        </Grid>
        <Grid className="container" item container xs={12}>
            <Grid className="main" item lg={8}>
                <div className="line-items">
                    {line_items.map(({
                            title, unit_price, unit_discounted_price, quantity, total_discounted_price, promos, sku,
                            cost, product_type, product_group, product_category, product_category_old, metal, ring_size, 
                            shape, weight, clarity, cut, lab_grown, certificate_number,
                            color, polish, symmetry, fluorescence, length,
                            width, certificate_lab, depth_percent, table_percent,
                            girdle, girdle_thin, girdle_thick,
                            girdle_percent, culet, culet_condition,
                            fancy_color, color_intensity, fancy_color_overtone,
                            length_to_width_ratio, crown_height, crown_angle,
                            pavillion_depth, pavillion_angle, supplier_name, supplier_sku, properties
                        }, idx) => {
                            const itemDetails = {cost, product_type, product_group, product_category, product_category_fixed: product_category_old, metal, ring_size, 
                                shape, weight, clarity, cut, lab_grown, certificate_number,
                                color, polish, symmetry, fluorescence, length,
                                width, certificate_lab, depth_percent, table_percent,
                                girdle, girdle_thin, girdle_thick,
                                girdle_percent, culet, culet_condition,
                                fancy_color, color_intensity, fancy_color_overtone,
                                length_to_width_ratio, crown_height, crown_angle,
                                pavillion_depth, pavillion_angle, supplier_name, supplier_sku
                            }

                            let margin = 0
                            try {
                                margin = (unit_discounted_price - cost) / unit_discounted_price
                            } catch(e) {
                            }
                            
                            const isDiscounted = unit_discounted_price !== unit_price
                            return <React.Fragment key={idx}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="title">
                                                        <div><b>{title}</b></div>
                                                        <div>SKU: {sku}</div>
                                                    </td>
                                                    <td className="unit-price">
                                                        <div className={isDiscounted ? 'discounted-price' : ''}>{formatCurrency(unit_price)}</div>
                                                        {isDiscounted && <div className="discount-price">{formatCurrency(unit_discounted_price)}</div>}
                                                    </td>
                                                    <td className="times">x</td>
                                                    <td className="quantity">{quantity}</td>
                                                    <td className="total">{formatCurrency(total_discounted_price)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="accordion-details">
                                            {properties.map(x => <div key={x.name}><b>{x.name}:</b> {x.value}</div>)}
                                            {isDiscounted && promos.map(({name, description, rate, amount}, idx) => <table className="promo-table" key={idx}>
                                                <tbody>
                                                    <tr className="promos">
                                                        <td>{description} {!!rate && <>({rate * 100})%</>}</td>
                                                        <td>{name}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="total">-{formatCurrency(amount)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>)}
                                            <div>
                                                <b>Margin:</b> {round(margin * 100, 1)}%
                                            </div>
                                            <div>
                                                {Object.entries(itemDetails)
                                                    .filter(([key, value]) => value !== null && value !== undefined && value !== '')
                                                    .map(([key, value]) => <div key={key}><b>{key}:</b> {String(value)}</div>)
                                                }
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>    
                            </React.Fragment>
                    })}
                </div> 
                <Card title="Summary">
                    <table className="summary">
                        <tbody>
                            <tr><td>Item Total</td><td></td><td>{formatCurrency(total_item_discounted_price)}</td></tr>
                            <SummaryRows arr={charges} total={total_charges} title="Charges" />
                            <SummaryRows negative arr={order_discounts} total={total_order_discounts} title="Promo Discounts" />
                            <SummaryRows negative arr={payment_adjustments} total={total_payment_adjustments} title="Payment Discounts" />
                            <SummaryRows arr={tax_lines} total={total_tax} title="Tax" />
                            <tr><td>Shipping</td><td></td><td>{formatCurrency(total_shipping)}</td></tr>
                            <SummaryRows arr={payments} total={total_payments} title="Payments" />
                            <tr><td><b>Total</b></td><td></td><td><b>{formatCurrency(total_price)}</b></td></tr>
                        </tbody>
                    </table>
                </Card>
                {user.user_type === 'admin' && <Card title="Timeline">
                    <table>
                        {timeline.map(({text, user_name, date}) => <tr>
                            <td>{text}</td><td>{user_name}</td><td>{moment(date).format('M/D/YYYY h:mm A')}</td>
                        </tr>)}
                    </table>
                </Card>}
                {retailer && <Card title="Retailer">
                    <table className="summary">
                        <tbody>
                            <tr><td>Name</td><td></td><td>{retailer.name}</td></tr>
                            <tr><td>Commission</td><td></td><td>{commission}</td></tr>
                            <tr><td>Payment Status</td><td></td><td className={financial_status === 'paid' ? 'status-green' : 'status-red'}>{financial_status}</td></tr>
                            <tr><td>Fulfillment Status</td><td></td><td className={fulfillment_status === 'fulfilled' ? 'status-green' : 'status-red'}>{fulfillment_status}</td></tr>
                        </tbody>
                    </table>
                </Card>}
            </Grid>
            <Grid item className="sidebar">
                <Card title="Notes" className="notes">
                    <OptionalRow value={notes} />
                    <OptionalRow value={comments} />
                </Card>
                <Card title="Customer Info">
                    <p>{customer_name}</p>
                    <p>{email}</p>
                    <p>{phone}</p>
                </Card>
                {user.user_type === 'admin' && <Card title="IP Address">
                    <OptionalRow value={ip_address} />
                </Card>}
                <AddressCard title="Billing Address" address={billing_address} />
                <AddressCard title="Shipping Address" address={shipping_address} />
                <Card title="Payments">
                    {order_payments.map(({title, amount, billing_address}) => <div>
                        {formatCurrency(amount)} on {title}   
                        {/* {billing_address && <AddressCard title="Billing Address" address={billing_address} />} */}
                    </div>)}
                </Card>
                <Card title="Warranty Info"> 
                    <OptionalRow title="Created" value={formatDate(warranty?.created_at)} />
                    <OptionalRow title="Serial #" value={warranty?.serial_number} />
                    <OptionalRow title="Extension Created" value={formatDate(warranty?.extended_warranty_activation_date)} />
                    <OptionalRow title="Extension Term (months)" value={warranty?.extended_warranty_term_in_months} />
                    <OptionalRow title="Extension SKU" value={warranty?.extended_warranty_sku} />
                </Card>
            </Grid>
        </Grid>
    </StyledGrid>
}

export default CustomerOrder