import React from 'react'
import * as Yup from 'yup'

import { Grid } from '@material-ui/core'
import Input from '../form/Input'
import FormDialog from '../form/FormDialog'
import InputSkeleton from '../skeletons/InputSkeleton'
import ChipInput from '../form/ChipInput'
import { Link } from 'react-router-dom'
import AsyncAutoComplete from '../form/AutoComplete'

const VendorSchema = Yup.object().shape({
    name: Yup
        .string()
        .required('Name is required'),
    days_to_ship: Yup
        .number(),
    holidays: Yup
        .array()
        .of(Yup.string()),
    emails: Yup
        .array()
        .of(Yup.string().email())
        .required('Email is required'),
    supplier_id: Yup
        .number()
        .required('ID is required'),
    location: Yup.string(),
    account_mgr_contact: Yup.string(),
    escalations: Yup.string(),
    shipping_method: Yup.string(),
    secondary_contact_phone: Yup.string(),
    comments: Yup.string(),
    contact: Yup.object().shape({
        _id: Yup
          .string()
          .required('Contact is required')
    }).nullable(),
})

const VendorSkeleton = () => (
  <Grid container spacing={1}>
    {[...Array(10)].map((x, i) => <Grid key={i} item xs={6}>
      <InputSkeleton />
    </Grid>)}
  </Grid>
)

export default function Vendor() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Input 
          name="supplier_id"
          label="ID"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="name"
          label="Name"
        />
      </Grid>
      <Grid item xs={6}>
        <ChipInput
          name="emails"
          label="Email"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="days_to_ship"
          label="Days to ship"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="location"
          label="Location"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="account_mgr_contact"
          label="Account Manager"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="phone"
          label="Phone"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="secondary_contact_phone"
          label="Secondary Phone"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="escalations"
          label="Escalations"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="shipping_method"
          label="Shipping Method"
        />
      </Grid>
      <Grid item xs={6}>
        <Input 
          name="comments"
          label="Comments"
          multiline
        />
      </Grid>
      <Grid item xs={6}>
      <AsyncAutoComplete
          name="contact"
          errorPath="contact._id"
          label="Assigned Contact"
          routeOptions={{route: 'users', selected: 'name', searchField: 'name'}}
          getOptionSelected={(option, value) => {
            return option._id === value._id
          }}
          getOptionLabel={option => option.name || ''}
        />
      </Grid>
      <Grid item xs={6}>
        <ChipInput
          name="holidays" 
          label={<Link to="holiday-info" target="_blank" rel="noopener noreferrer">Holidays</Link>}
        />
      </Grid>
    </Grid>
  )
}

const initialValues = {
    name: '',
    emails: [],
    days_to_ship: 0,
    supplier_id: '',
    location: '',
    account_mgr_contact: '',
    escalations: '',
    secondary_contact_phone: '',
    comments: '',
    shipping_method: '',
    holidays: [
        "1/1",
        "easter",
        "5/(1,-1)",
        "5/(0,2)",
        "7/4",
        "9/(1,1)",
        "11/(4,4)",
        "12/25",
        "(0)",
        "(6)"
    ],
    contact: {_id: ''},
}

const transformData = data => {
    data.contact = data.contact?._id || undefined
  }

export function VendorDialog(props) {
  return (
    <FormDialog 
      title="Vendor"
      routeOptions={{route: 'suppliers', populated: 'contact'}}
      validationSchema={VendorSchema}
      Skeleton={VendorSkeleton}
      defaultValues={initialValues}
      transformData={transformData}
      {...props}
    >
      <Vendor />
    </FormDialog>
  )
}