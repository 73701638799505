import React from 'react'
import Button from '@material-ui/core/Button'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import { Tooltip, IconButton, DialogContentText } from '@material-ui/core'
import { handleErrors } from '../../utils'
import axios from '../../axios'
import BasicDialog from '../form/BasicDialog'

export default function AcceptDiamonds({
  ids, fetchData, notes, 
  isSupplier
}) {
  const [open, setOpen] = React.useState(false)
  const ref = React.useRef()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async (status) => {
    try {
      const notes = ref.current.value.trim()
      if (notes.length < 4) {
        let message = ''
        switch (status) {
          case 'pending':
            message = 'Pending reason'
            break
          case 'accepted':
            message = 'Shipping instructions'
            break
          case 'rejected':
            message = 'Rejected reason'
            break
        }
        throw new Error(message + ' required')
      }
      
      await axios.patch(`/returns/accept`, {ids, status, notes})
      setOpen(false)
      fetchData()
    } catch(err) {
      handleErrors({err})
      // setOpen(false)
    }
  }

  return (
    <>
      <Tooltip title="Accept Diamond">
        <IconButton onClick={handleClickOpen}>
          <AssignmentTurnedInIcon />
        </IconButton>
      </Tooltip>
      <BasicDialog
        open={open}
        onClose={handleClose}
        title={`Accept Diamond`}
        actions={<>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          {isSupplier && <Button onClick={() => handleConfirm('accepted')} color="secondary">
            Accept
          </Button>}
          <Button onClick={() => handleConfirm(isSupplier ? 'rejected' : 'pending')} color="secondary">
            {isSupplier ? 'Reject' : 'Mark pending'}
          </Button>
        </>}
      >
        <DialogContentText id="alert-dialog-description">
          <div style={{margin: '20px 0'}}>
            <h4>Notes</h4>
            {notes.map(({is_ritani, date, user_name, text}, idx) => <div key={idx} style={{background: is_ritani ? '#cdf2c9' : '#ddd', marginLeft: is_ritani ? '10px' : 0, marginBottom: '5px', padding: '5px', borderRadius: '5px'}}>
                {text}
            </div>)}
          </div>
          <textarea ref={ref} style={{width: '100%', height: '400px', minWidth: '400px'}} placeholder="Shipping instructions for accepted diamonds or rejection reason for rejected diamonds">
            
          </textarea>
        </DialogContentText>
      </BasicDialog>
    </>
  );
}