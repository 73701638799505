import React from 'react'
import { useFormContext } from 'react-hook-form'
import { get } from 'lodash'
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

const FileUpload = ({ name='', label, ...rest }) => {
    const { register, formState: {errors}, watch } = useFormContext()

    const err = get(errors, name)
    const files = watch(name)
    const file = files?.[0]

    return (
        <div>
            <label htmlFor="icon-button-file">
                {label}
                <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                </IconButton>
            </label>
            <input id="icon-button-file" {...rest} {...register(name)} style={{display: 'none'}} accept="image/png, image/jpeg, image/jpg" type="file" />
            {file && <b>Upload: {file?.name}</b>}
            {err?.message && <div style={{color: 'red'}}>{err?.message}</div>}
        </div>
    );
}

export default FileUpload;